@charset "UTF-8";
@import "https://fonts.googleapis.com/css?family=Poppins:200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800";
@import "https://fonts.googleapis.com/css?family=Poppins:200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800";
body {
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  background-color: #eeeded;
  height: 100%;
}

/*======================================================
    Header Section
=======================================================*/
.header {
  background-color: #fff;
  width: 100%;
  height: auto;
  display: block;
}
.header ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.header-logo img {
  max-width: 100%;
}

.sticky-header {
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  transform: translateY(-100%);
  z-index: 99;
}
.sticky-header .primary-header {
  background-color: #fff;
  box-shadow: 0px 2px 5px -2px rgba(0, 0, 0, 0.1);
}

.sticky-header.sticky-fixed-top {
  transition: transform 0.25s ease-in-out;
  will-change: transform;
  transform: translateY(0);
}

.mobile-menu-icon {
  display: none;
}

.burger-menu {
  width: 20px;
  height: 17px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  transition: transform 330ms ease-out;
}

.burger-menu.menu-open {
  transform: rotate(-45deg);
}

.line-menu {
  background-color: #222222;
  border-radius: 2px;
  width: 100%;
  height: 3px;
}

.line-menu.line-half {
  width: 50%;
}

.line-menu.first-line {
  transition: transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57);
  transform-origin: right;
}

.menu-open .line-menu.first-line {
  transform: rotate(-90deg) translateX(3px);
}
.menu-open .line-menu.last-line {
  transform: rotate(-90deg) translateX(-3px);
}

.line-menu.last-line {
  align-self: flex-end;
  transition: transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57);
  transform-origin: left;
}

.header-menu-wrap ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.header-menu-wrap ul li {
  display: inline-block;
  position: relative;
}
.header-menu-wrap ul li > a {
  display: block;
  font-size: 13px;
  letter-spacing: 0;
  text-transform: uppercase;
  font-weight: 500;
  color: #07122D;
  padding: 0 10px;
  height: 80px;
  line-height: 80px;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
}
.header-menu-wrap ul li:hover > a {
  color: #0e0d0d;
}
.header-menu-wrap li ul {
  background-color: #fff;
  display: block;
  width: 250px;
  padding: 30px 0;
  box-shadow: 0px 50px 100px 0px rgba(64, 1, 4, 0.1), 0px -6px 0px 0px rgba(248, 99, 107, 0.004);
  position: absolute;
  left: -35px;
  top: 100%;
  opacity: 0;
  visibility: hidden;
  z-index: 0;
  transition: opacity 0.5s ease, visibility 0.5s ease;
}
.header-menu-wrap li ul li ul {
  width: 250px;
  position: absolute;
  left: 100%;
  top: 0;
}
.header-menu-wrap li:hover > ul {
  opacity: 1;
  visibility: visible;
  z-index: 99;
}
.header-menu-wrap li li {
  display: block;
  padding: 0 35px;
  margin-bottom: 5px;
  text-align: left;
  position: relative;
}
.header-menu-wrap li li:last-child {
  margin: 0;
}
.header-menu-wrap li li > a {
  display: block;
  height: auto;
  line-height: inherit;
  color: #07122D;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.8;
  text-transform: uppercase;
  padding: 1px 7px;
  font-size: 13px;
  -webkit-font-smoothing: antialiased;
}
.header-menu-wrap li li:hover > a {
  color: #0e0d0d;
  background-color: #f9f9f9;
}

.primary-header-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.primary-header-inner .header-right {
  display: flex;
  align-items: center;
  line-height: 80px;
  margin-left: 20px;
}

.header-right .mobile-menu-icon {
  margin-left: 20px;
}
.header-right a.header-btn {
  display: inline-block;
  background: linear-gradient(to right, #181818, #0e0d0d);
  color: #FFF;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  height: 45px;
  line-height: 45px;
  padding: 0 35px;
  letter-spacing: 0;
  border-radius: 2px;
  overflow: hidden;
  position: relative;
  margin-left: 20px;
  transition: all ease 700ms;
  -moz-transition: all ease 700ms;
  -webkit-transition: all ease 700ms;
  -ms-transition: all ease 700ms;
  -o-transition: all ease 700ms;
  z-index: 1;
}
.header-right a.header-btn:hover {
  color: #fff;
}
.header-right a.header-btn:hover span {
  height: 562.5px;
  width: 562.5px;
}
.header-right a.header-btn span {
  background: #07122D none repeat scroll 0 0;
  border-radius: 50%;
  display: block;
  height: 0;
  position: absolute;
  transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transition: width 0.6s ease 0s, height 0.6s ease 0s;
  -moz-transition: width 0.6s ease 0s, height 0.6s ease 0s;
  -webkit-transition: width 0.6s ease 0s, height 0.6s ease 0s;
  -ms-transition: width 0.6s ease 0s, height 0.6s ease 0s;
  -o-transition: width 0.6s ease 0s, height 0.6s ease 0s;
  width: 0;
  z-index: -1;
}
.header-right .search-icon {
  color: #07122D;
  cursor: pointer;
  font-size: 20px;
}
.header-right .search-icon:hover {
  opacity: 0.8;
}

.primary-header .header-logo {
  max-width: 180px;
}

.viewport-lg .primary-header .header-menu-wrap {
  display: block !important;
}

.top-bar {
  background-color: black;
}
.top-bar .top-left li {
  font-size: 12px;
  color: #fff;
  display: inline-block;
  margin: 0;
  padding-top: 7px;
  padding-bottom: 7px;
}
.top-bar .top-left li:not(:last-of-type) {
  padding-right: 20px;
}
.top-bar .top-left li a {
  color: #fff;
  text-decoration: none;
}
.top-bar .lang li {
  display: inline-block;
  margin-left: 13px;
}
.top-bar .lang li:first-child {
  margin-left: 0;
}
.top-bar .lang li a {
  color: #fff;
  font-size: 12px;
}
.top-bar .lang li a:hover {
  color: #ddd;
}
.top-bar .top-social li {
  display: inline-block;
  margin-left: 13px;
}
.top-bar .top-social li:first-child {
  margin-left: 0;
}
.top-bar .top-social li a {
  color: #fff;
  font-size: 12px;
}
.top-bar .top-social li a:hover {
  color: #ddd;
}

.top-bar-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
}

.primary-header-one .header-menu-wrap {
  margin-left: auto;
}

.header-cart-btn {
  margin-left: 10px;
}
.header-cart-btn a {
  color: #07122D;
  font-size: 18px;
  text-decoration: none;
  position: relative;
}
.header-cart-btn a .num {
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #0e0d0d;
  color: #fff;
  font-size: 11px;
  display: block;
  top: -8px;
  right: -9px;
  line-height: 16px;
  text-align: center;
}

#dl-popup-search-box {
  background-color: rgba(0, 0, 0, 0.95);
  position: fixed;
  width: 100%;
  height: 100%;
  top: -20em;
  left: 0;
  right: 0;
  white-space: nowrap;
  z-index: 9999;
  opacity: 0;
  visibility: hidden;
  transition: 500ms ease all;
}
#dl-popup-search-box .box-inner-wrap {
  width: 100%;
  height: 100%;
}
#dl-popup-search-box .box-inner-wrap form {
  position: relative;
  margin: 0 auto;
}
#dl-popup-search-box .box-inner-wrap input {
  width: 100%;
  height: 70px;
  padding: 0 75px 0.125em 0;
  background: transparent;
  border: none;
  border-bottom: 3px solid #07122D;
  font-size: 40px;
  color: #ddd;
}
#dl-popup-search-box .box-inner-wrap input::-moz-placeholder {
  color: #444;
}
#dl-popup-search-box .box-inner-wrap input::placeholder {
  color: #444;
}
#dl-popup-search-box .box-inner-wrap input:focus {
  outline: none;
}
#dl-popup-search-box .box-inner-wrap button {
  position: absolute;
  display: block;
  width: 70px;
  height: 70px;
  right: 0;
  top: 0;
  background: transparent;
  border: none;
  color: #444;
  font-size: 40px;
  border-left: 3px solid #07122D;
  transition: 500ms ease all;
}
#dl-popup-search-box .box-inner-wrap button:hover {
  color: #ddd;
}
#dl-popup-search-box .box-inner-wrap button:focus {
  outline: none;
}

#dl-popup-search-box.toggled {
  top: 0;
  opacity: 1;
  visibility: visible;
}

@media (min-width: 993px) {
  .header-menu-wrap li ul {
    display: block !important;
  }
}
@media (max-width: 992px) {
  #page.site {
    margin-top: 0 !important;
  }
  .headroom--top .header-menu-wrap {
    display: none !important;
  }
  .headroom--unpinned .header-menu-wrap {
    display: none !important;
  }
  .dropdown-plus {
    width: 49px;
    height: 49px;
    line-height: 49px;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
  }
  .dropdown-plus:before {
    position: absolute;
    content: "";
    top: 21px;
    right: 10px;
    width: 13px;
    height: 1px;
    background-color: #222222;
  }
  .dropdown-plus:after {
    position: absolute;
    content: "";
    top: 21px;
    right: 10px;
    width: 13px;
    height: 1px;
    background-color: #222222;
    transform: rotate(90deg);
  }
  .dropdown-plus.dropdown-open:after {
    display: none;
  }
  .mobile-menu-icon {
    display: block;
  }
  .header-menu-wrap {
    display: none;
    background-color: #fff;
    width: 100%;
    height: auto;
    padding: 0 20px;
    position: absolute;
    left: 0;
    top: 100%;
    z-index: 999;
    top: 100%;
  }
  .header-menu-wrap ul li {
    display: block;
    border-bottom: 1px solid rgba(0, 0, 0, 0.04);
    margin: 0;
  }
  .header-menu-wrap ul li:first-child {
    border-top: 1px solid rgba(0, 0, 0, 0.04);
  }
  .header-menu-wrap ul li > a {
    padding: 10px 15px;
    height: inherit;
    line-height: inherit;
  }
  .header-menu-wrap ul li ul {
    background-color: transparent;
    width: 100%;
    opacity: 1;
    padding: 0;
    visibility: visible;
    position: inherit;
    display: none;
    top: inherit;
    left: inherit;
    box-shadow: none;
    padding-top: 11px;
  }
  .header-menu-wrap ul li ul li ul {
    background-color: transparent;
    width: 100%;
    opacity: 1;
    padding: 0;
    visibility: visible;
    position: inherit;
    display: none;
    top: inherit;
    left: inherit;
    box-shadow: none;
    padding-top: 11px;
  }
  .header-menu-wrap li li {
    padding-left: 11px;
  }
  .header-menu-wrap li li:hover > a {
    background-color: transparent;
    color: #666;
  }
  .header-menu-wrap li li:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
  .header-menu-wrap li li > a {
    color: #07122D;
    font-size: 13px;
  }
  .primary-header-inner {
    padding: 0;
  }
  .sticky-header {
    display: none;
  }
  .sticky-header .primary-header-inner {
    padding: 0;
  }
  .header-1 .header-right a.header-btn {
    margin-left: 15px;
    margin-right: 0;
  }
  .primary-header .header-logo {
    max-width: 190px;
  }
}
@media screen and (max-width: 782px) {
  .primary-header-inner .header-right {
    margin-left: 6px !important;
  }
  .header-right a.header-btn {
    padding: 0 6px;
    margin-left: 6px !important;
  }
}
@media (max-width: 580px) {
  .header-right a.header-btn {
    padding: 0 6px !important;
    margin-left: 6px !important;
  }
  .header-right a.dl-btn {
    line-height: 40px;
    font-size: 10px;
    padding: 0 25px;
  }
  .primary-header .header-logo {
    max-width: 150px;
  }
  .top-bar .top-left li {
    font-size: 12px;
    font-size: 12px;
  }
  .top-bar .top-left li:not(:last-of-type) {
    padding-right: 15px;
  }
}
@media (max-width: 767px) {
  .top-bar .top-left li {
    border: none;
    padding-left: 0;
  }
  .top-bar .top-left li:first-child {
    border: none;
  }
  .top-bar .top-left li:not(:last-of-type) {
    padding-right: 20px;
  }
}
@media screen and (max-width: 600px) {
  #dl-popup-search-box .box-inner-wrap input {
    font-size: 20px;
    height: 50px;
  }
  #dl-popup-search-box .box-inner-wrap button {
    font-size: 20px;
    height: 50px;
  }
}
.webpage-content {
  background-color: var(--fg-color);
  border-radius: var(--border-radius-md);
  padding: var(--padding-sm) 15px;
}

.web-sidebar {
  padding-top: 2rem;
  position: sticky;
  top: 0;
  background-color: var(--fg-color);
  border-radius: var(--border-radius-md);
  padding: var(--padding-sm) 15px;
  width: 200px;
}

.sidebar-item a {
  display: block;
  padding: 0.25rem 0.5rem;
  margin-top: 0.25rem;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  color: var(--text-color);
  text-decoration: none;
  font-weight: 500;
}
.sidebar-item a:hover {
  color: #1F272E;
}

.sidebar-item:first-child a {
  color: var(--black);
  background-color: var(--light);
  margin-top: 0;
}

.sidebar-item a.active {
  color: var(--primary);
  background-color: var(--primary-light);
}

.sidebar-item-icon {
  width: 24px;
  height: 24px;
  display: inline-block;
}

.sidebar-group {
  margin-bottom: 1rem;
}
.sidebar-group h6 {
  font-size: 0.875rem;
  margin-bottom: 0.75rem;
  line-height: 1.5;
}
.sidebar-group > ul {
  padding-left: 0.5rem;
  margin-bottom: 2rem;
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font-size-adjust: 100%;
}

body {
  font-family: "Poppins", sans-serif;
  color: #666666;
  background-color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

p {
  margin-bottom: 1.5rem;
  font-size: 0.8rem;
  font-weight: 300;
  font-family: "Poppins";
  letter-spacing: 0;
  color: #777;
}

strong {
  font-weight: 700;
}

em {
  font-style: italic;
}

i {
  font-style: italic;
}

hr {
  border: none;
  border-top: 1px solid #ebebeb;
  margin: 3rem auto 2.5rem;
}

sub {
  position: relative;
  font-size: 70%;
  line-height: 0;
  vertical-align: baseline;
  bottom: -0.25em;
}

.bg-light {
  background-color: #f8f8f8 !important;
}

.bg-light-2 {
  background-color: #f9f9f9 !important;
}

.bg-lighter {
  background-color: #fafafa !important;
}

.bg-gray {
  background-color: #f4f5f8 !important;
}

.bg-primary {
  background-color: #c96 !important;
}

.bg-secondary {
  background-color: #000 !important;
}

.bg-image {
  background-color: #ccc;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.bg-image .btn-link-dark {
  color: #fff;
}
.bg-image .btn-link-dark:hover {
  color: #c96;
  border-color: #c96;
}
.bg-image .btn-link-dark:focus {
  color: #c96;
  border-color: #c96;
}
.bg-image .btn-outline-primary:hover {
  background-color: #c96;
  border-color: #c96;
  color: #fff;
}
.bg-image .btn-outline-primary:focus {
  background-color: #c96;
  border-color: #c96;
  color: #fff;
}
.bg-image .btn-outline-primary:not(:disabled):not(.disabled):active {
  background-color: #c96;
  border-color: #c96;
  color: #fff;
}
.bg-image .btn-outline-primary:not(:disabled):not(.disabled).active {
  background-color: #c96;
  border-color: #c96;
  color: #fff;
}
.bg-image .btn-outline-primary.focus {
  background-color: #c96;
  border-color: #c96;
  background-color: #c96;
  border-color: #c96;
  color: #fff;
  background-color: #c96;
  border-color: #c96;
}
.bg-image .btn-outline-dark {
  color: #fff;
  border-color: #fff;
}
.bg-image .btn-outline-dark:hover {
  color: #c96;
  color: #c96;
  color: #c96;
  background-color: #fff;
  border-color: #fff;
}
.bg-image .btn-outline-dark:focus {
  color: #c96;
  color: #c96;
  color: #c96;
  background-color: #fff;
  border-color: #fff;
}
.bg-image .btn-outline-dark:not(:disabled):not(.disabled):active {
  color: #c96;
  background-color: #fff;
  border-color: #fff;
}
.bg-image .btn-outline-dark:not(:disabled):not(.disabled).active {
  color: #c96;
  background-color: #fff;
  border-color: #fff;
}
.bg-image .btn-outline-dark.focus {
  color: #c96;
  background-color: #fff;
  border-color: #fff;
}
.bg-image .btn-wrap > span {
  color: #777;
}

.bg-overlay {
  position: relative;
}
.bg-overlay:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  opacity: 0.3;
  background-color: #333;
}
.bg-overlay .container {
  position: relative;
  z-index: 2;
}
.bg-overlay .container-fluid {
  position: relative;
  z-index: 2;
}

h1 {
  font-weight: 500;
  line-height: 1.1;
  margin-bottom: 1.4rem;
  color: #333333;
  letter-spacing: -0.03em;
  font-size: 4rem;
}

.h2 {
  font-weight: 500;
  line-height: 1.1;
  margin-bottom: 1.4rem;
  color: #333333;
  letter-spacing: -0.03em;
  font-size: 3.6rem;
}

h3 {
  font-weight: 500;
  line-height: 1.1;
  margin-bottom: 1.4rem;
  color: #333333;
  letter-spacing: -0.03em;
  font-size: 2.6rem;
}

.h4 {
  font-weight: 500;
  line-height: 1.1;
  margin-bottom: 1.4rem;
  color: #333333;
  letter-spacing: -0.03em;
  font-size: 2.2rem;
}

h5 {
  font-weight: 500;
  line-height: 1.1;
  margin-bottom: 1.4rem;
  color: #333333;
  font-size: 2rem;
  letter-spacing: -0.025em;
}

.h6 {
  font-weight: 500;
  line-height: 1.1;
  margin-bottom: 1.4rem;
  color: #333333;
  font-size: 1.6rem;
  letter-spacing: -0.01em;
}

a {
  color: #cc9966;
  text-decoration: none;
  transition: color 0.3s ease;
  font-family: "Poppins";
}
a:hover {
  color: #bf8040;
  text-decoration: none;
}
a:focus {
  color: #bf8040;
  text-decoration: none;
}

.link-underline {
  box-shadow: 0 1px 0 0 #c96;
}

.text-primary {
  color: #c96 !important;
}

.text-secondary {
  color: #000 !important;
}

.text-dark {
  color: #333 !important;
}

.text-light {
  color: #ccc !important;
}

.lead {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.875;
  margin-bottom: 1rem;
}

blockquote {
  position: relative;
  margin: 0 0 4.2rem;
  padding: 0;
  color: #777;
  font-style: italic;
  font-size: 1.6rem;
  line-height: 1.625;
}
blockquote p {
  max-width: 1120px;
}
blockquote cite {
  font-style: normal;
  font-weight: 500;
  color: #333333;
  letter-spacing: -0.01em;
  line-height: 1;
}
blockquote cite span {
  display: block;
  font-weight: 300;
  font-size: 1.4rem;
}

blockquote.text-center p {
  margin-left: auto;
  margin-right: auto;
}

blockquote.text-white cite {
  color: #cccccc;
}
blockquote.text-white cite span {
  color: #777;
}

.row {
  margin-left: -10px;
  margin-right: -10px;
}

.col-1 {
  padding-right: 10px;
  padding-left: 10px;
}

.col-2 {
  padding-right: 10px;
  padding-left: 10px;
}

.col-3 {
  padding-right: 10px;
  padding-left: 10px;
}

.col-4 {
  padding-right: 10px;
  padding-left: 10px;
}

.col-5 {
  padding-right: 10px;
  padding-left: 10px;
}

.col-6 {
  padding-right: 10px;
  padding-left: 10px;
}

.col-7 {
  padding-right: 10px;
  padding-left: 10px;
}

.col-8 {
  padding-right: 10px;
  padding-left: 10px;
}

.col-9 {
  padding-right: 10px;
  padding-left: 10px;
}

.col-10 {
  padding-right: 10px;
  padding-left: 10px;
}

.col-11 {
  padding-right: 10px;
  padding-left: 10px;
}

.col-12 {
  padding-right: 10px;
  padding-left: 10px;
}

.col {
  padding-right: 10px;
  padding-left: 10px;
}

.col-auto {
  padding-right: 10px;
  padding-left: 10px;
}

.col-sm-1 {
  padding-right: 10px;
  padding-left: 10px;
}

.col-sm-2 {
  padding-right: 10px;
  padding-left: 10px;
}

.col-sm-3 {
  padding-right: 10px;
  padding-left: 10px;
}

.col-sm-4 {
  padding-right: 10px;
  padding-left: 10px;
}

.col-sm-5 {
  padding-right: 10px;
  padding-left: 10px;
}

.col-sm-6 {
  padding-right: 10px;
  padding-left: 10px;
}

.col-sm-7 {
  padding-right: 10px;
  padding-left: 10px;
}

.col-sm-8 {
  padding-right: 10px;
  padding-left: 10px;
}

.col-sm-9 {
  padding-right: 10px;
  padding-left: 10px;
}

.col-sm-10 {
  padding-right: 10px;
  padding-left: 10px;
}

.col-sm-11 {
  padding-right: 10px;
  padding-left: 10px;
}

.col-sm-12 {
  padding-right: 10px;
  padding-left: 10px;
}

.col-sm {
  padding-right: 10px;
  padding-left: 10px;
}

.col-sm-auto {
  padding-right: 10px;
  padding-left: 10px;
}

.col-md-1 {
  padding-right: 10px;
  padding-left: 10px;
}

.col-md-2 {
  padding-right: 10px;
  padding-left: 10px;
}

.col-md-3 {
  padding-right: 10px;
  padding-left: 10px;
}

.col-md-4 {
  padding-right: 10px;
  padding-left: 10px;
}

.col-md-5 {
  padding-right: 10px;
  padding-left: 10px;
}

.col-md-6 {
  padding-right: 10px;
  padding-left: 10px;
}
.col-md-6 .widget-about p {
  max-width: none;
}

.col-md-7 {
  padding-right: 10px;
  padding-left: 10px;
}

.col-md-8 {
  padding-right: 10px;
  padding-left: 10px;
}

.col-md-9 {
  padding-right: 10px;
  padding-left: 10px;
}

.col-md-10 {
  padding-right: 10px;
  padding-left: 10px;
}

.col-md-11 {
  padding-right: 10px;
  padding-left: 10px;
}

.col-md-12 {
  padding-right: 10px;
  padding-left: 10px;
}

.col-md {
  padding-right: 10px;
  padding-left: 10px;
}

.col-md-auto {
  padding-right: 10px;
  padding-left: 10px;
}

.col-lg-1 {
  padding-right: 10px;
  padding-left: 10px;
}

.col-lg-2 {
  padding-right: 10px;
  padding-left: 10px;
}

.col-lg-3 {
  padding-right: 10px;
  padding-left: 10px;
}

.col-lg-4 {
  padding-right: 10px;
  padding-left: 10px;
}

.col-lg-5 {
  padding-right: 10px;
  padding-left: 10px;
}

.col-lg-6 {
  padding-right: 10px;
  padding-left: 10px;
}
.col-lg-6 .widget-about p {
  max-width: none;
}

.col-lg-7 {
  padding-right: 10px;
  padding-left: 10px;
}

.col-lg-8 {
  padding-right: 10px;
  padding-left: 10px;
}

.col-lg-9 {
  padding-right: 10px;
  padding-left: 10px;
}

.col-lg-10 {
  padding-right: 10px;
  padding-left: 10px;
}

.col-lg-11 {
  padding-right: 10px;
  padding-left: 10px;
}

.col-lg-12 {
  padding-right: 10px;
  padding-left: 10px;
}

.col-lg {
  padding-right: 10px;
  padding-left: 10px;
}

.col-lg-auto {
  padding-right: 10px;
  padding-left: 10px;
}

.col-xl-1 {
  padding-right: 10px;
  padding-left: 10px;
}

.col-xl-2 {
  padding-right: 10px;
  padding-left: 10px;
}

.col-xl-3 {
  padding-right: 10px;
  padding-left: 10px;
}

.col-xl-4 {
  padding-right: 10px;
  padding-left: 10px;
}

.col-xl-5 {
  padding-right: 10px;
  padding-left: 10px;
}

.col-xl-6 {
  padding-right: 10px;
  padding-left: 10px;
}

.col-xl-7 {
  padding-right: 10px;
  padding-left: 10px;
}

.col-xl-8 {
  padding-right: 10px;
  padding-left: 10px;
}

.col-xl-9 {
  padding-right: 10px;
  padding-left: 10px;
}

.col-xl-10 {
  padding-right: 10px;
  padding-left: 10px;
}

.col-xl-11 {
  padding-right: 10px;
  padding-left: 10px;
}

.col-xl-12 {
  padding-right: 10px;
  padding-left: 10px;
}

.col-xl {
  padding-right: 10px;
  padding-left: 10px;
}

.col-xl-auto {
  padding-right: 10px;
  padding-left: 10px;
}

.no-gutters {
  margin-left: 0;
  margin-right: 0;
}
.no-gutters > .col {
  padding-left: 0;
  padding-right: 0;
}
.no-gutters > [class*=col-] {
  padding-left: 0;
  padding-right: 0;
}

.container {
  width: 1188px;
  max-width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

.page-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  transition: transform 0.4s ease;
}

.mmenu-active .page-wrapper {
  transform: translateX(200px);
}
.mmenu-active .mobile-menu-container {
  visibility: visible;
  transform: translateX(280px);
}
.mmenu-active .mobile-menu-overlay {
  visibility: visible;
  opacity: 1;
}

.main {
  flex: 1;
  width: 100%;
}

.page-content {
  padding-bottom: 5rem;
}

.col-xl-5col {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

.col-xl-4-5col {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

.col-xl-2-5col {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

.col-xl-3-5col {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

.sidenav-title {
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 1.5;
  letter-spacing: -0.01em;
  padding: 1.45rem 3rem;
  color: #fff;
  background-color: #333;
  text-transform: uppercase;
}

.btn-video {
  color: #c96;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 5.4rem;
  height: 5.4rem;
  font-size: 1.6rem;
  line-height: 1;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 0 0 1.5rem rgba(255, 255, 255, 0.2);
  transition: all 0.35s ease;
}
.btn-video:hover {
  background-color: #222;
  color: #fff;
  box-shadow: 0 0 0 1rem rgba(255, 255, 255, 0.2);
}
.btn-video:focus {
  background-color: #222;
  color: #fff;
  box-shadow: 0 0 0 1rem rgba(255, 255, 255, 0.2);
}

.btn-link {
  padding: 0.4rem 1rem;
  min-width: 0;
  color: #c96;
  text-decoration: none;
  border: none;
  border-bottom: 0.1rem solid transparent;
  letter-spacing: 0;
  font-size: 1rem;
  font-family: "Poppins", sans-serif;
}
.btn-link:hover {
  color: #c96;
  border-color: #c96;
  text-decoration: none;
}
.btn-link:focus {
  color: #c96;
  border-color: #c96;
  text-decoration: none;
}
.btn-link .btn-link-dark:hover {
  color: #c96;
  border-color: #c96;
  text-decoration: none;
}
.btn-link .btn-link-dark:focus {
  color: #c96;
  border-color: #c96;
  text-decoration: none;
}
.btn-link i:first-child {
  margin-right: 1rem;
}
.btn-link i:last-child {
  margin-left: 1rem;
}

.page-link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 3rem;
  height: 4rem;
  font-weight: 400;
  font-size: 1rem;
  padding: 0 0.3rem;
  line-height: 1;
  color: #706f6c;
  border-radius: 0.3rem;
  background-color: transparent;
  border: 0.1rem solid transparent;
}
.page-link:hover {
  box-shadow: none;
  color: #c96;
  border-color: #ebebeb;
  text-decoration: none;
  background-color: transparent;
  outline: none !important;
}
.page-link:focus {
  box-shadow: none;
  color: #c96;
  border-color: #ebebeb;
  text-decoration: none;
  background-color: transparent;
  outline: none !important;
}
.page-link i {
  font-size: 1.5rem;
}

.page-item.active .page-link {
  color: #c96;
  background-color: transparent;
  border-color: #ebebeb;
}

.title-link {
  font-weight: 500;
  transition: all 0.3s ease;
}
.title-link:hover {
  box-shadow: 0 1px 0 0 #c96;
}
.title-link:focus {
  box-shadow: 0 1px 0 0 #c96;
}
.title-link i {
  font-size: 1.5rem;
}
.title-link i:last-child {
  margin-left: 0.5rem;
}

.product-title {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.25;
  letter-spacing: -0.01em;
  color: #333333;
  margin-bottom: 0.2rem;
}
.product-title a {
  color: inherit;
}
.product-title a:hover {
  color: #c96;
}
.product-title a:focus {
  color: #c96;
}

.product-price {
  color: #c96;
  display: flex;
  align-items: center;
  flex-flow: wrap;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.25;
  margin-bottom: 1.3rem;
}

.product-label.label-primary {
  background-color: #c96;
  color: #fff;
}

.product-label.label-secondary {
  color: #fff;
  background-color: #000;
}

.product-label-text {
  color: #c96;
  font-weight: 400;
  font-size: 1.3rem;
  line-height: 1;
  letter-spacing: -0.01em;
  margin-top: -0.1rem;
}

.product-body {
  position: relative;
  padding: 1.6rem 2rem;
  transition: all 0.35s ease;
  background-color: #fff;
}
.product-body .btn-wishlist {
  position: absolute;
  top: 3.4rem;
  right: 2rem;
  color: #ccc;
  padding: 0;
  margin: 0;
  background: none;
}
.product-body .btn-wishlist:hover {
  color: #c96;
  background: none;
}
.product-body .btn-wishlist:focus {
  color: #c96;
  background: none;
}
.product-body .btn-wishlist:before {
  margin-right: 0;
}
.product-body .btn-wishlist span {
  display: none;
}

.btn-expandable {
  position: relative;
}
.btn-expandable span {
  position: absolute;
  right: 100%;
  top: 0;
  bottom: 0;
  align-items: center;
  display: inline-flex;
  font-weight: 300;
  font-size: 1.1rem;
  white-space: nowrap;
  letter-spacing: 0;
  min-width: 100px;
  padding-left: 1.8rem;
  padding-right: 0.4rem;
  color: #fff;
  background-color: #c96;
  transition: all 0.35s ease;
  opacity: 0;
  visibility: hidden;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  transform: translateX(15px);
  z-index: -1;
}
.btn-expandable:hover {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-expandable:hover span {
  visibility: visible;
  opacity: 1;
  transform: translateX(0);
}
.btn-expandable:focus {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-expandable:focus span {
  visibility: visible;
  opacity: 1;
  transform: translateX(0);
}

.product.product-4 .btn-product {
  padding-top: 1.1rem;
  padding-bottom: 1.1rem;
  color: #fff;
  background-color: #c96;
}
.product.product-4 .btn-product:hover {
  color: #fff;
  background-color: #000;
}
.product.product-4 .btn-product:hover span {
  box-shadow: none;
}
.product.product-4 .btn-product:focus {
  color: #fff;
  background-color: #000;
}
.product.product-4 .btn-product:focus span {
  box-shadow: none;
}
.product.product-4 .btn-product span {
  color: inherit;
  transition-duration: 0s;
}
.product.product-4 .product-body {
  padding-bottom: 2rem;
  z-index: 2;
}
.product.product-4 .product-body div:last-child {
  margin-bottom: 0;
}
.product.product-4 .product-action {
  background-color: transparent;
}
.product.product-4 .product-action-vertical {
  top: 3rem;
  transform: translate(0, 10px);
}
.product.product-4 .product-title {
  margin-bottom: 1rem;
}
.product.product-4 .product-nav {
  margin-bottom: 0;
}
.product.product-4 .product-footer {
  opacity: 0;
  position: absolute;
  left: 0;
  right: 0;
  display: block;
  padding-top: 0.3rem;
  padding-bottom: 2.5rem;
  transition: all 0.35s ease;
  background-color: #fff;
  z-index: 1;
}
.product.product-4 .ratings-container {
  margin-bottom: 0;
}
.product.product-4:hover .product-action-vertical {
  transform: translate(0, 0);
}
.product.product-4:hover .product-footer {
  opacity: 1;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
}

.product.product-5 .btn-product {
  color: #c96;
  padding-top: 1.1rem;
  padding-bottom: 1.1rem;
  background-color: #333333;
}
.product.product-5 .btn-product:hover {
  background-color: #c96;
  color: #fff;
}
.product.product-5 .btn-product:hover span {
  box-shadow: none;
}
.product.product-5 .btn-product:focus {
  background-color: #c96;
  color: #fff;
}
.product.product-5 .btn-product:focus span {
  box-shadow: none;
}
.product.product-5 .btn-product span {
  color: #fff;
  transition-duration: 0s;
}
.product.product-5 .product-body {
  padding-bottom: 1.6rem;
  background-color: #fff;
  transition-duration: 0s;
  z-index: 2;
}
.product.product-5 .product-body div:last-child {
  margin-bottom: 0;
}
.product.product-5 .product-action {
  background-color: transparent;
}
.product.product-5 .product-action-vertical {
  transform: translate(10px, 0);
}
.product.product-5 .product-title {
  margin-bottom: 1rem;
}
.product.product-5 .product-footer {
  opacity: 0;
  position: absolute;
  left: 0;
  right: 0;
  display: block;
  padding-top: 0;
  padding-bottom: 2rem;
  transition: all 0.35s ease;
  background-color: #fff;
  z-index: 1;
}
.product.product-5 .ratings-container {
  margin-bottom: 0;
}
.product.product-5 .ratings-container + .product-nav {
  margin-top: 1.7rem;
}
.product.product-5 .product-nav {
  margin-bottom: 0;
}
.product.product-5:hover .product-footer {
  opacity: 1;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
}

.product.product-7 .btn-product {
  color: #c96;
  color: #c96;
  padding-top: 1.1rem;
  padding-bottom: 1.1rem;
  color: #c96;
  background-color: #fff;
  text-transform: uppercase;
  border-bottom: 0.1rem solid #ebebeb;
}
.product.product-7 .btn-product span {
  color: #c96;
  color: #c96;
  color: #c96;
}
.product.product-7 .btn-product:hover {
  background-color: #c96;
  border-bottom-color: #c96;
  background-color: #c96;
  border-bottom-color: #c96;
  color: #fff;
  background-color: #c96;
  border-bottom-color: #c96;
}
.product.product-7 .btn-product:hover span {
  color: #fff;
  box-shadow: none;
}
.product.product-7 .btn-product:focus {
  background-color: #c96;
  border-bottom-color: #c96;
  background-color: #c96;
  border-bottom-color: #c96;
  color: #fff;
  background-color: #c96;
  border-bottom-color: #c96;
}
.product.product-7 .btn-product:focus span {
  color: #fff;
  box-shadow: none;
}
.product.product-7 .btn-product:before {
  display: inline-block;
  margin-top: -0.1rem;
}
.product.product-7 .product-nav-thumbs {
  margin-bottom: 1rem;
}

.product.product-8 {
  margin-bottom: 2rem;
}
.product.product-8 .new-price {
  color: #c96;
  color: #c96;
  color: #c96;
}
.product.product-8 .btn-product {
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
  color: #fff;
  background-color: #333;
  text-transform: uppercase;
}
.product.product-8 .btn-product:before {
  color: #c96;
  color: #c96;
  color: #c96;
  margin-top: -2px;
  margin-right: 0.6rem;
}
.product.product-8 .btn-product:hover {
  background-color: #c96;
  background-color: #c96;
  color: #fff;
  background-color: #c96;
}
.product.product-8 .btn-product:hover:before {
  color: #fff;
}
.product.product-8 .btn-product:hover span {
  box-shadow: none;
}
.product.product-8 .btn-product:focus {
  background-color: #c96;
  background-color: #c96;
  color: #fff;
  background-color: #c96;
}
.product.product-8 .btn-product:focus:before {
  color: #fff;
}
.product.product-8 .btn-product:focus span {
  box-shadow: none;
}
.product.product-8 .btn-product span {
  font-size: 1rem;
  color: inherit;
  transition-duration: 0s;
}
.product.product-8 .product-media {
  border: 0.1rem solid #ebebeb;
}
.product.product-8 .product-body {
  padding: 1.9rem 0.5rem 4rem;
}
.product.product-8 .product-cat {
  color: #ccc;
  margin-bottom: 0.5rem;
}
.product.product-8 .product-action {
  background-color: transparent;
}
.product.product-8 .product-action-vertical {
  transform: translate(0, 10px);
}
.product.product-8 .product-title {
  margin-bottom: 1rem;
}
.product.product-8 .product-price {
  margin-bottom: 0.9rem;
}
.product.product-8 .product-nav {
  padding-top: 0.4rem;
  margin-bottom: 1.3rem;
}
.product.product-8 .product-nav:last-child {
  margin-bottom: 0;
}
.product.product-8 .ratings-container {
  opacity: 0;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 2.2rem;
  margin-bottom: 0;
  transition: all 0.35s ease;
}
.product.product-8:hover .product-action-vertical {
  transform: translate(0, 0);
}
.product.product-8:hover .ratings-container {
  opacity: 1;
}

.product.product-list {
  box-shadow: none;
  padding-bottom: 2rem;
  border-bottom: 0.1rem solid #ebebeb;
  margin-bottom: 2rem;
}
.product.product-list .btn-product {
  color: #777;
  padding-top: 0;
  padding-bottom: 0;
  line-height: 1.4;
  flex: 1 1 50%;
  max-width: 50%;
}
.product.product-list .btn-product:hover {
  color: #c96;
  color: #c96;
  color: #c96;
}
.product.product-list .btn-product:hover span {
  color: inherit;
}
.product.product-list .btn-product:focus {
  color: #c96;
  color: #c96;
  color: #c96;
}
.product.product-list .btn-product:focus span {
  color: inherit;
}
.product.product-list .btn-product:before {
  margin-right: 0.5rem;
}
.product.product-list .btn-product span {
  color: inherit;
  font-size: 1.2rem;
  transition: box-shadow 0.35s ease, color 0s ease;
}
.product.product-list .btn-product.btn-cart {
  color: #c96;
  border-bolor: #c96;
  color: #c96;
  border-bolor: #c96;
  color: #c96;
  border: 0.1rem solid #c96;
  flex: 1 1 100%;
  max-width: 100%;
  text-transform: uppercase;
  line-height: 1;
  padding-top: 1rem;
  padding-bottom: 1rem;
  transition: all 0.35s ease;
}
.product.product-list .btn-product.btn-cart:hover {
  background-color: #c96;
  background-color: #c96;
  color: #fff;
  background-color: #c96;
}
.product.product-list .btn-product.btn-cart:hover span {
  box-shadow: none;
}
.product.product-list .btn-product.btn-cart:focus {
  background-color: #c96;
  background-color: #c96;
  color: #fff;
  background-color: #c96;
}
.product.product-list .btn-product.btn-cart:focus span {
  box-shadow: none;
}
.product.product-list .btn-product.btn-cart span {
  font-size: 1.3rem;
}
.product.product-list .product-media {
  height: 100%;
}
.product.product-list .product-media > a {
  height: 100%;
}
.product.product-list .product-media img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.product.product-list .product-body {
  padding: 1.6rem 0 0;
}
.product.product-list .product-body .btn-wishlist {
  top: 3.1rem;
}
.product.product-list .product-list-action {
  padding: 3.4rem 0 0;
}
.product.product-list .product-content {
  color: #777;
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.43;
  padding-top: 1rem;
  margin-bottom: 3.4rem;
}
.product.product-list .product-content p {
  margin-bottom: 0;
}
.product.product-list .product-action {
  position: static;
  left: auto;
  right: auto;
  bottom: auto;
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
  margin-bottom: 1.2rem;
}
.product.product-list .ratings-container {
  margin-bottom: 2.6rem;
}
.product.product-list .btn-product.disabled {
  pointer-events: none;
}
.product.product-list .btn-product.btn-cart.disabled {
  color: #ccc;
  border-color: #ebebeb;
  background-color: #fafafa;
}
.product.product-list .btn-product.btn-cart.disabled:before {
  display: none;
}
.product.product-list .product-nav {
  margin-bottom: 0;
}

.entry-video a:hover:after {
  color: #c96;
  color: #c96;
  color: #c96;
  background-color: #fff;
  box-shadow: 0 0 0 1.6rem rgba(255, 255, 255, 0.2);
}
.entry-video a:focus:after {
  color: #c96;
  color: #c96;
  color: #c96;
  background-color: #fff;
  box-shadow: 0 0 0 1.6rem rgba(255, 255, 255, 0.2);
}
.entry-video a:after {
  content: "\ec2c";
  font-family: "molla";
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 7rem;
  height: 7rem;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -3.5rem;
  margin-top: -3.5rem;
  font-size: 1.8rem;
  line-height: 1;
  color: #fff;
  background-color: transparent;
  border-radius: 50%;
  border: 0.2rem solid #fff;
  transition: all 0.35s ease;
}

.entry-meta {
  display: flex;
  align-items: center;
  flex-flow: wrap;
  color: #777;
  font-size: 0.7rem;
  line-height: 0.5;
  margin-bottom: 0.5rem;
}
.entry-meta a {
  color: inherit;
  transition: all 0.3s ease;
}
.entry-meta a:hover {
  color: #c96;
  box-shadow: 0 1px 0 #c96;
}
.entry-meta a:focus {
  color: #c96;
  box-shadow: 0 1px 0 #c96;
}

.entry-title {
  color: #333333;
  font-weight: 600;
  font-size: 2.2rem;
  line-height: 1.25;
  letter-spacing: -0.025em;
  margin-bottom: 0.6rem;
}
.entry-title a {
  color: inherit;
}
.entry-title a:hover {
  color: #c96;
}
.entry-title a:focus {
  color: #c96;
}

.entry-cats {
  color: #777;
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 1.5rem;
}
.entry-cats a {
  color: inherit;
  transition: all 0.3s ease;
}
.entry-cats a:hover {
  color: #c96;
  box-shadow: 0 1px 0 #c96;
}
.entry-cats a:focus {
  color: #c96;
  box-shadow: 0 1px 0 #c96;
}

.read-more {
  display: inline-block;
  position: relative;
  font-weight: 400;
  letter-spacing: -0.01em;
  padding-bottom: 0.1rem;
  transition: all 0.3s ease;
}
.read-more:hover {
  box-shadow: 0 1px 0 0 #c96;
  padding-right: 2.2rem;
}
.read-more:hover:after {
  opacity: 1;
  transform: translateX(0);
}
.read-more:focus {
  padding-right: 2.2rem;
  box-shadow: 0 1px 0 0 #c96;
}
.read-more:focus:after {
  opacity: 1;
  transform: translateX(0);
}
.read-more:after {
  font-family: "molla";
  content: "\f274";
  font-size: 1.5rem;
  line-height: 1;
  display: block;
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -0.75rem;
  opacity: 0;
  transition: all 0.25s ease;
  transform: translateX(-6px);
}

.posts-list {
  list-style: none;
  padding: 0.4rem 0 0.2rem;
  margin: 0;
}
.posts-list a {
  color: #333;
}
.posts-list a:hover {
  color: #c96;
}
.posts-list a:focus {
  color: #c96;
}
.posts-list li {
  margin-bottom: 2rem;
}
.posts-list li::after {
  display: block;
  clear: both;
  content: "";
}
.posts-list li:last-child {
  margin-bottom: 0;
}
.posts-list figure {
  float: left;
  max-width: 80px;
  margin-right: 2rem;
  margin-bottom: 0;
}
.posts-list figure a {
  display: block;
}
.posts-list div {
  padding-top: 0.6rem;
}
.posts-list span {
  display: block;
  color: #cccccc;
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.25;
  letter-spacing: -0.01em;
  margin-bottom: 0.5rem;
}
.posts-list h4 {
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.4;
  letter-spacing: 0;
  margin-bottom: 0;
}

.tagcloud {
  display: flex;
  align-items: center;
  flex-flow: wrap;
  padding-top: 0.3rem;
  margin-right: -1rem;
  margin-bottom: -1rem;
}
.tagcloud a {
  display: block;
  color: #777;
  font-weight: 300;
  font-size: 1.4rem;
  line-height: 1.4;
  border-radius: 0.3rem;
  padding: 0.65rem 0.9rem;
  background-color: #fafafa;
  border: 0.1rem solid #ebebeb;
  margin-right: 1rem;
  margin-bottom: 1rem;
  transition: all 0.3s ease;
}
.tagcloud a:hover {
  color: #c96;
  background-color: #fff;
}
.tagcloud a:focus {
  color: #c96;
  background-color: #fff;
}

.btn.btn-spinner {
  min-width: 0;
  color: #333333;
  padding: 1.25rem 0.4rem;
  font-size: 1rem;
  border: none;
  background: none;
}
.btn.btn-spinner:hover {
  color: #c96;
}
.btn.btn-spinner:focus {
  color: #c96;
}
.btn.btn-spinner i {
  margin: 0 !important;
}

.sidebar-toggler {
  display: inline-flex;
  align-items: center;
  color: #333333;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1;
  letter-spacing: -0.01em;
  text-transform: uppercase;
  margin-right: 3rem;
}
.sidebar-toggler:hover {
  color: #c96;
}
.sidebar-toggler:focus {
  color: #c96;
}
.sidebar-toggler i {
  font-size: 1.6rem;
  margin-right: 0.9rem;
}

#filter-price-range {
  color: #c96;
}

.contact-box {
  margin-bottom: 3rem;
}
.contact-box a {
  color: inherit;
}
.contact-box a:hover {
  color: #c96;
}
.contact-box a:focus {
  color: #c96;
}
.contact-box h3 {
  font-weight: 400;
  font-size: 1.6rem;
  letter-spacing: -0.025em;
  margin-bottom: 1.6rem;
}
.contact-box address {
  font-weight: 300;
  font-style: normal;
}
.contact-box div {
  font-weight: 300;
  font-style: normal;
}
.contact-box .social-icons {
  padding-top: 1.5rem;
}
.contact-box .social-icon {
  font-size: 1.3rem;
}

.contact-list {
  max-width: 270px;
  list-style: none;
  padding-left: 0;
}
.contact-list a {
  color: inherit;
}
.contact-list a:hover {
  color: #c96;
}
.contact-list a:focus {
  color: #c96;
}
.contact-list i {
  color: #c96;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0.3rem;
  font-size: 2rem;
  line-height: 1;
  color: #c96;
}
.contact-list li {
  position: relative;
  padding-left: 3rem;
  margin-bottom: 1.4rem;
}

.store {
  margin-bottom: 3rem;
  color: #777;
}
.store a:not(.btn) {
  color: inherit;
}
.store a:not(.btn):hover {
  color: #c96;
}
.store a:not(.btn):focus {
  color: #c96;
}
.store address {
  font-weight: 300;
  font-style: normal;
  margin-bottom: 0;
}
.store div {
  font-weight: 300;
  font-style: normal;
  margin-bottom: 0;
}
.store .btn.btn-link {
  padding-left: 0;
  padding-right: 0;
  margin-top: 2.1rem;
}

.portfolio-title {
  color: #333;
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 1.3;
  letter-spacing: -0.01em;
  margin-bottom: 0.1rem;
}
.portfolio-title a {
  color: inherit;
}
.portfolio-title a:hover {
  color: #c96;
}
.portfolio-title a:focus {
  color: #c96;
}

.portfolio-tags {
  font-weight: 300;
  font-size: 1rem;
  letter-spacing: -0.01em;
}
.portfolio-tags a {
  color: #c96;
  transition: all 0.35s ease;
}
.portfolio-tags a:hover {
  color: #c96;
  box-shadow: 0 1px 0 #c96;
}
.portfolio-tags a:focus {
  color: #c96;
  box-shadow: 0 1px 0 #c96;
}

.btn-product-gallery {
  position: absolute;
  right: 2rem;
  bottom: 2rem;
  z-index: 49;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 4rem;
  height: 4rem;
  color: #777;
  font-weight: normal;
  font-size: 2rem;
  line-height: 1;
  background-color: #fff;
  box-shadow: 2px 6px 16px rgba(51, 51, 51, 0.05);
  transition: all 0.35s ease;
}
.btn-product-gallery:hover {
  background-color: #c96;
  color: #fff;
}
.btn-product-gallery:focus {
  background-color: #c96;
  color: #fff;
}

.product-gallery-item {
  position: relative;
  display: block;
  flex: 0 0 25%;
  max-width: 25%;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-bottom: 1rem;
}
.product-gallery-item:before {
  border-color: #c96;
  border-color: #c96;
  content: "";
  display: block;
  position: absolute;
  left: 0.5rem;
  right: 0.5rem;
  top: 0;
  bottom: 0;
  transition: all 0.35s ease;
  opacity: 0;
  visibility: hidden;
  border: 0.1rem solid #c96;
}
.product-gallery-item img {
  max-width: none;
  width: 100%;
}
.product-gallery-item:after {
  content: "";
  display: block;
  position: absolute;
  left: 0.5rem;
  right: 0.5rem;
  top: 0;
  bottom: 0;
  transition: all 0.35s ease;
  visibility: visible;
  opacity: 1;
  background-color: rgba(255, 255, 255, 0.4);
}
.product-gallery-item:hover:after {
  opacity: 0;
  visibility: hidden;
}

.product-pager-link {
  color: #ccc;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.3;
  letter-spacing: 0;
}
.product-pager-link:hover {
  color: #c96;
  color: #c96;
  color: #c96;
}
.product-pager-link:hover span {
  box-shadow: 0 1px 0 #c96;
  box-shadow: 0 1px 0 #c96;
  box-shadow: 0 1px 0 #c96;
}
.product-pager-link:hover i {
  color: inherit;
}
.product-pager-link:focus {
  color: #c96;
  color: #c96;
  color: #c96;
}
.product-pager-link:focus span {
  box-shadow: 0 1px 0 #c96;
  box-shadow: 0 1px 0 #c96;
  box-shadow: 0 1px 0 #c96;
}
.product-pager-link:focus i {
  color: inherit;
}
.product-pager-link i {
  color: #666;
  font-size: 2rem;
  line-height: 1;
  transition: color 0.3s;
}
.product-pager-link span {
  display: inline-block;
  transition: box-shadow 0.3s ease;
}
.product-pager-link + .product-pager-link {
  margin-left: 3rem;
}

.product-details {
  margin-bottom: 2rem;
}
.product-details .product-cat {
  font-weight: 400;
  font-size: 1.4rem;
}
.product-details .product-cat a {
  color: #666;
  box-shadow: none;
  transition: all 0.35s ease;
}
.product-details .product-cat a:hover {
  color: #c96;
  box-shadow: 0 1px 0 #c96;
  color: #c96;
  box-shadow: 0 1px 0 #c96;
  color: #c96;
  box-shadow: 0 1px 0 #c96;
}
.product-details .product-cat a:focus {
  color: #c96;
  box-shadow: 0 1px 0 #c96;
  color: #c96;
  box-shadow: 0 1px 0 #c96;
  color: #c96;
  box-shadow: 0 1px 0 #c96;
}
.product-details .product-cat span {
  margin-right: 0.7rem;
}
.product-details .product-size {
  flex-flow: row wrap;
  padding-top: 0;
  margin-bottom: 0;
}
.product-details .product-size a.active {
  color: #c96;
  border-color: #c96;
  color: #c96;
  border-color: #c96;
  color: #c96;
  border-color: #c96;
  background-color: transparent;
}
.product-details .product-size a {
  color: #666;
  width: 4rem;
  height: 4rem;
  font-weight: 400;
  font-size: 1.4rem;
  letter-spacing: -0.01em;
  border: 0.1rem solid #d7d7d7;
  background-color: transparent;
  border-radius: 0;
  margin-right: 1rem;
}
.product-details .product-size a:hover {
  color: #c96;
  border-color: #c96;
  color: #c96;
  border-color: #c96;
  color: #c96;
  border-color: #c96;
  background-color: transparent;
}
.product-details .product-size a:focus {
  color: #c96;
  border-color: #c96;
  color: #c96;
  border-color: #c96;
  color: #c96;
  border-color: #c96;
  background-color: transparent;
}
.product-details .product-size a.disabled {
  color: #dadada;
  border-color: #ebebeb;
  background-color: transparent;
}
.product-details .product-title {
  font-weight: 400;
  font-size: 2.4rem;
  letter-spacing: -0.025em;
  margin-bottom: 1.2rem;
  margin-top: -0.5rem;
  padding-right: 1rem;
}
.product-details .ratings-container {
  margin-bottom: 1.3rem;
}
.product-details .product-price {
  font-size: 2.4rem;
}
.product-details .new-price {
  margin-right: 1rem;
}
.product-details .product-content {
  margin-bottom: 1.6rem;
}
.product-details .product-content p:last-child {
  margin-bottom: 0;
}
.product-details .product-countdown {
  position: static;
  left: auto;
  right: auto;
  bottom: auto;
  top: auto;
  max-width: 260px;
  margin-left: -5px;
  margin-bottom: 2rem;
}
.product-details .product-countdown .countdown-section {
  background-color: #fafafa;
}
.product-details .product-nav-dots a {
  width: 2rem;
  height: 2rem;
}
.product-details .product-nav-dots span {
  width: 2rem;
  height: 2rem;
}

.size-guide {
  color: #777;
  display: inline-flex;
  align-items: center;
}
.size-guide:hover {
  color: #c96;
  color: #c96;
  color: #c96;
}
.size-guide:focus {
  color: #c96;
  color: #c96;
  color: #c96;
}
.size-guide i {
  font-size: 1.8rem;
  margin-right: 0.7rem;
}

.product-details-action {
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  margin-bottom: 3rem;
}
.product-details-action .btn-cart {
  color: #c96;
  border-color: #c96;
  color: #c96;
  border-color: #c96;
  padding: 1rem 1.5rem;
  max-width: 198px;
  color: #c96;
  border: 0.1rem solid #c96;
}
.product-details-action .btn-cart:hover {
  border-color: #c96;
  background-color: #c96;
  border-color: #c96;
  background-color: #c96;
  color: #fff;
  border-color: #c96;
  background-color: #c96;
}
.product-details-action .btn-cart:focus {
  border-color: #c96;
  background-color: #c96;
  border-color: #c96;
  background-color: #c96;
  color: #fff;
  border-color: #c96;
  background-color: #c96;
}
.product-details-action .btn-cart span {
  font-size: 1.4rem;
  color: inherit;
  text-transform: uppercase;
  box-shadow: none;
  transition: box-shadow 0.35s ease, color 0s ease;
}
.product-details-action .btn-cart:before {
  margin-right: 0.4rem;
}

.product-details-tab {
  margin-bottom: 5rem;
}
.product-details-tab .nav.nav-pills .nav-link {
  color: #333333;
  font-size: 1.6rem;
  padding: 0.55rem 3rem;
  border-bottom-width: 0.2rem;
  text-transform: capitalize;
}
.product-details-tab .nav.nav-pills .nav-link:hover {
  color: #c96;
  border-bottom-color: #c96;
}
.product-details-tab .nav.nav-pills .nav-link:focus {
  color: #c96;
  border-bottom-color: #c96;
}
.product-details-tab .tab-content {
  border: 0.1rem solid #dadada;
  margin-top: -0.1rem;
  border-radius: 0.3rem;
}
.product-details-tab .tab-content .tab-pane {
  padding: 2.7rem 3rem;
}

.product-desc-content a {
  color: #333;
  border-bottom: 0.1rem solid #b8b8b8;
  transition: all 0.35s ease;
}
.product-desc-content a:hover {
  color: #c96;
  border-bottom-color: #c96;
  color: #c96;
  border-bottom-color: #c96;
  color: #c96;
  border-bottom-color: #c96;
}
.product-desc-content a:focus {
  color: #c96;
  border-bottom-color: #c96;
  color: #c96;
  border-bottom-color: #c96;
  color: #c96;
  border-bottom-color: #c96;
}
.product-desc-content h2 {
  font-weight: 400;
  font-size: 2.4rem;
  letter-spacing: -0.01em;
  margin-bottom: 1.8rem;
}
.product-desc-content h3 {
  font-weight: 400;
  font-size: 1.6rem;
  letter-spacing: -0.01em;
  margin-bottom: 1.8rem;
}
.product-desc-content ul {
  color: #333333;
}
.product-desc-content ul li {
  position: relative;
  padding-left: 1.3rem;
}
.product-desc-content ul li:before {
  content: "•";
  position: absolute;
  display: block;
  top: 0;
  left: 0.2rem;
}
.product-desc-content blockquote {
  color: #333;
  font-style: normal;
  font-weight: 400;
  font-size: 2.4rem;
  line-height: 1.25;
  letter-spacing: -0.01em;
  margin-bottom: 1.6rem;
}
.product-desc-content blockquote p {
  margin-bottom: 0;
}
.product-desc-content blockquote cite {
  font-weight: 300;
  font-size: 1.4rem;
  letter-spacing: 0;
}

.review {
  padding-bottom: 1.3rem;
  margin-bottom: 2rem;
  border-bottom: 0.1rem solid #ebebeb;
}
.review h4 {
  color: #333;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.25;
  letter-spacing: -0.01em;
  margin-bottom: 0.8rem;
}
.review h4 a {
  color: inherit;
}
.review h4 a:hover {
  color: #c96;
  color: #c96;
  color: #c96;
}
.review h4 a:focus {
  color: #c96;
  color: #c96;
  color: #c96;
}
.review:last-child {
  margin-bottom: 0;
}
.review .col-auto {
  width: 120px;
  padding-right: 2rem;
}
.review .ratings-container {
  padding-top: 0.7rem;
  margin-bottom: 0.5rem;
}
.review .review-date {
  color: #ccc;
}

.review-action {
  font-size: 1.2rem;
}
.review-action a {
  color: #777;
  transition: all 0.35s ease;
}
.review-action a:hover {
  color: #c96;
  box-shadow: 0 1px 0 #c96;
  color: #c96;
  box-shadow: 0 1px 0 #c96;
  color: #c96;
  box-shadow: 0 1px 0 #c96;
}
.review-action a:focus {
  color: #c96;
  box-shadow: 0 1px 0 #c96;
  color: #c96;
  box-shadow: 0 1px 0 #c96;
  color: #c96;
  box-shadow: 0 1px 0 #c96;
}
.review-action a + a {
  margin-left: 1.6rem;
}
.review-action i {
  font-size: 1.4rem;
  margin-right: 0.6rem;
}

.product-details-extended .nav.nav-pills {
  border-top: 0.1rem solid #ebebeb;
}
.product-details-extended .nav.nav-pills .nav-link.active {
  border-color: #c96;
  border-color: #c96;
  border-color: #c96;
}
.product-details-extended .nav.nav-pills .nav-link {
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
  border-top: 0.2rem solid transparent;
  border-bottom: none;
  margin-top: -0.1rem;
}
.product-details-extended .nav.nav-pills .nav-link:hover {
  border-color: #c96;
  border-color: #c96;
  border-color: #c96;
}
.product-details-extended .nav.nav-pills .nav-link:focus {
  border-color: #c96;
  border-color: #c96;
  border-color: #c96;
}
.product-details-extended .tab-content {
  border: none;
}
.product-details-extended .tab-content .tab-pane {
  padding: 0;
}

.editor-content a {
  color: #333;
  box-shadow: 0 1px 0 #333;
  transition: all 0.35s ease;
}
.editor-content a:hover {
  color: #c96;
  box-shadow: 0 1px 0 #c96;
  color: #c96;
  box-shadow: 0 1px 0 #c96;
  color: #c96;
  box-shadow: 0 1px 0 #c96;
}
.editor-content a:focus {
  color: #c96;
  box-shadow: 0 1px 0 #c96;
  color: #c96;
  box-shadow: 0 1px 0 #c96;
  color: #c96;
  box-shadow: 0 1px 0 #c96;
}
.editor-content blockquote {
  border-left-color: #c96;
  border-left-color: #c96;
  position: relative;
  color: #333333;
  font-style: normal;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 1.67;
  padding: 7rem 3rem 4rem 3rem;
  border-left: 0.1rem solid #c96;
  background-color: #fafafa;
  margin-top: 4.2rem;
  margin-bottom: 4.2rem;
}
.editor-content blockquote p:last-child {
  margin-bottom: 0;
}
.editor-content blockquote:before {
  color: #ebebeb;
  content: "\f10e";
  font-weight: normal;
  font-size: 3rem;
  line-height: 1;
  font-family: "molla";
  position: absolute;
  left: 3rem;
  top: 4rem;
  margin-top: -1.5rem;
}
.editor-content::after {
  display: block;
  clear: both;
  content: "";
}
.editor-content .float-left {
  margin-right: 2.8rem;
  margin-bottom: 1.5rem;
}
.editor-content .float-right {
  margin-left: 2.8rem;
  margin-bottom: 1.5rem;
}
.editor-content img {
  margin-bottom: 2rem;
}
.editor-content p {
  margin-bottom: 2.4rem;
}
.editor-content h3 {
  font-weight: 400;
  font-size: 2.2rem;
  margin-bottom: 2rem;
}
.editor-content ul li {
  position: relative;
  margin-bottom: 2.7rem;
}
.editor-content ul li:before {
  display: inline-block;
  content: "•";
  font-size: 1.8rem;
  line-height: 1;
  margin-right: 1rem;
  vertical-align: middle;
  margin-top: -1px;
}

.entry-tags {
  display: flex;
  align-items: center;
  flex-flow: wrap;
  padding-top: 0.2rem;
}
.entry-tags a {
  display: block;
  color: #777;
  font-weight: 300;
  font-size: 1.4rem;
  line-height: 1.4;
  border-radius: 0.3rem;
  padding: 0.45rem 0.9rem;
  background-color: #fafafa;
  border: 0.1rem solid #ebebeb;
  margin-right: 0.5rem;
  margin-bottom: 0;
  transition: all 0.3s ease;
}
.entry-tags a:hover {
  color: #c96;
  color: #c96;
  color: #c96;
  background-color: #fff;
}
.entry-tags a:focus {
  color: #c96;
  color: #c96;
  color: #c96;
  background-color: #fff;
}
.entry-tags span {
  margin-right: 1rem;
}

.entry-author-details {
  display: flex;
  align-items: flex-start;
  padding: 5rem 3rem 4.6rem;
  background-color: #fafafa;
}
.entry-author-details h4 {
  color: #333;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1;
  letter-spacing: -0.01em;
  margin-bottom: 0;
}
.entry-author-details h4 a {
  color: inherit;
}
.entry-author-details h4 a:hover {
  color: #c96;
}
.entry-author-details h4 a:focus {
  color: #c96;
}

.author-link {
  color: #777;
  letter-spacing: -0.01em;
  transition: all 0.35s ease;
}
.author-link:hover {
  color: #c96;
  box-shadow: 0 1px 0 #c96;
}
.author-link:focus {
  color: #c96;
  box-shadow: 0 1px 0 #c96;
}

.pager-link {
  color: #c96;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 0 0 100%;
  max-width: 100%;
  width: 100%;
  font-weight: 400;
  padding-top: 0.95rem;
  padding-bottom: 0.95rem;
  font-size: 1.4rem;
  line-height: 1.5;
  transition: all 0.35s ease;
}
.pager-link:hover {
  color: #c96;
}
.pager-link:hover:after {
  color: #c96;
}
.pager-link:hover .pager-link-title {
  box-shadow: 0 0.1rem 0 #333;
}
.pager-link:focus {
  color: #c96;
}
.pager-link:focus:after {
  color: #c96;
}
.pager-link:focus .pager-link-title {
  box-shadow: 0 0.1rem 0 #333;
}
.pager-link + .pager-link {
  border-top: 0.1rem solid #ebebeb;
}
.pager-link:after {
  color: #333;
  display: block;
  font-family: "molla";
  font-size: 1.5rem;
  line-height: 1;
  position: absolute;
  top: 50%;
  transition: all 0.35s ease 0.05s;
  margin-top: -0.8rem;
}
.pager-link:hover.pager-link-prev {
  padding-left: 7rem;
}
.pager-link:hover.pager-link-prev:after {
  left: 0;
}
.pager-link:focus.pager-link-prev {
  padding-left: 7rem;
}
.pager-link:focus.pager-link-prev:after {
  left: 0;
}
.pager-link:hover.pager-link-next {
  padding-right: 7rem;
}
.pager-link:hover.pager-link-next:after {
  right: 0;
}
.pager-link:focus.pager-link-next {
  padding-right: 7rem;
}
.pager-link:focus.pager-link-next:after {
  right: 0;
}

.product-col .product-title {
  display: inline-block;
  color: #333333;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.25;
  margin: 0;
}
.product-col .product-title a {
  color: inherit;
}
.product-col .product-title a:hover {
  color: #c96;
  color: #c96;
  color: #c96;
}
.product-col .product-title a:focus {
  color: #c96;
  color: #c96;
  color: #c96;
}
.product-col .product {
  display: flex;
  align-items: center;
  margin: 0;
  padding-right: 2rem;
  box-shadow: none;
}
.product-col .product-media {
  display: inline-block;
  margin: 0;
  margin-right: 2.8rem;
  flex-shrink: 0;
  max-width: 60px;
}

.container-fluid .widget-about p {
  max-width: 540px;
}

.widget-list {
  margin-bottom: 0;
}
.widget-list li:not(:last-child) {
  margin-bottom: 0.4rem;
}

.widget-newsletter form {
  padding-top: 0.7rem;
}
.widget-newsletter .form-control {
  height: 46px;
  padding-top: 1.15rem;
  padding-bottom: 1.15rem;
  background-color: #fff;
  border-right-width: 0;
}
.widget-newsletter .form-control:not(:focus) {
  border-color: #dadada;
}
.widget-newsletter .btn {
  min-width: 46px;
  font-size: 1.5rem;
  padding: 1.1rem 1rem;
}
.widget-newsletter .btn i {
  margin: 0 !important;
}

#scroll-top {
  width: 3rem;
  height: 3rem;
  position: fixed;
  right: 50px;
  bottom: 128px;
  z-index: 999;
  color: #cccccc;
  display: none;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 1;
  visibility: hidden;
  opacity: 0;
  border-radius: 0;
  border: none;
  background-color: #fafafa;
  font-size: 2rem;
  transition: all 0.4s ease;
  transform: translateY(60px);
  -ms-transform: translateY(60px);
}
#scroll-top:hover {
  color: #777;
  background-color: #eaeaea;
}
#scroll-top:focus {
  color: #777;
  background-color: #eaeaea;
}

#scroll-top.show {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
}

.title {
  font-size: 2.2rem;
  letter-spacing: -0.03em;
}

.title-lg {
  font-weight: 600;
  font-size: 2.6rem;
  letter-spacing: -0.01em;
}

.title-sm {
  font-size: 2rem;
  letter-spacing: -0.03em;
}

.heading {
  margin-bottom: 1.6rem;
}
.heading .title {
  margin-bottom: 0;
}

.heading.heading-flex {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.heading.heading-center {
  text-align: center;
}
.heading.heading-center .title {
  margin-bottom: 1.5rem;
}
.heading.heading-center .title-lg {
  margin-bottom: 2.4rem;
}

.title-separator {
  font-weight: 300;
  color: #cccccc;
}

.text-white .title-separator {
  color: #777;
}

.title-desc {
  color: #777;
  font-weight: 300;
  font-size: 1.4rem;
  line-height: 1.5;
  letter-spacing: -0.01em;
  margin-bottom: 0;
}

.heading-right {
  margin-top: 0.5rem;
}

.icon-box {
  color: #777;
  font-weight: 300;
  font-size: 1.6rem;
  line-height: 1.5;
  letter-spacing: -0.01em;
  margin-bottom: 2rem;
}

.icon-box.icon-box-sm {
  font-size: 1.4rem;
  line-height: 1.86;
}

.icon-box-title {
  color: #333333;
  font-weight: 400;
  font-size: 1.8rem;
  letter-spacing: -0.025em;
  margin-bottom: 0.9rem;
}

.icon-box-content p:last-child {
  margin-bottom: 0;
}

.icon-box-left {
  position: relative;
  padding-left: 70px;
}
.icon-box-left .icon-box-icon {
  position: absolute;
  left: 0;
  top: -1rem;
}

.icon-box-circle.icon-box-left {
  padding-left: 100px;
}
.icon-box-circle.icon-box-left .icon-box-icon {
  left: 1rem;
}

.icon-box-card {
  background-color: #fafafa;
  padding: 6.5rem 2rem 6rem;
  background-color: transparent;
  padding: 2rem;
}
.icon-box-card .icon-box-title {
  font-weight: 600;
}
.icon-box-card .icon-box-icon {
  margin-bottom: 1.3rem;
}

.icon-boxes-container {
  background-color: #f7f7f7;
  padding-top: 2.8rem;
  padding-bottom: 2.8rem;
  margin-bottom: 0;
}

.icon-boxes-container.bg-dark .icon-box-icon {
  color: #666666;
}
.icon-boxes-container.bg-dark .icon-box-title {
  color: #fff;
}

.icon-box-side {
  display: flex;
  align-items: center;
  width: 100%;
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin-bottom: 0;
}
.icon-box-side p {
  margin-bottom: 0;
}
.icon-box-side .icon-box-icon {
  display: inline-block;
  line-height: 0;
  min-width: 7rem;
  text-align: center;
  margin-bottom: 0;
}
.icon-box-side .icon-box-title {
  font-weight: 500;
  font-size: 1.6rem;
  letter-spacing: -0.01em;
  margin-top: 0.2rem;
  margin-bottom: 0;
}

.accordion {
  margin-bottom: 3rem;
}

.accordion-icon .card-body {
  padding-left: 5.5rem !important;
}

.accordion-plus .card-title a:before {
  content: "\f28e";
}
.accordion-plus .card-title a.collapsed:before {
  content: "\f2c2";
}

.accordion-rounded .card:first-child {
  border-radius: 0.3rem 0.3rem 0 0;
}
.accordion-rounded .card:last-child {
  border-radius: 0 0 0.3rem 0.3rem;
}

.tab-content-border {
  border: 0.1rem solid #d7d7d7;
  border-radius: 0.3rem;
  border-top-left-radius: 0;
  border-top-width: 0;
}

.justify-content-center + .tab-content-border {
  border-top-left-radius: 0.3rem;
}

.tab-pane {
  padding: 1.4rem 2.2rem;
}
.tab-pane p:last-child {
  margin-bottom: 0;
}

.tabs-vertical {
  display: flex;
  align-items: flex-start;
  align-items: stretch;
}
.tabs-vertical .tab-content-border {
  border-top-width: 0.1rem;
  border-left-width: 0;
  border-bottom-left-radius: 0;
}

.tab-content.tab-content-carousel > .tab-pane {
  display: block;
  visibility: hidden;
  height: 0 !important;
}
.tab-content.tab-content-carousel > .tab-pane:not(.active) {
  overflow: hidden !important;
}
.tab-content.tab-content-carousel > .active {
  height: auto !important;
  visibility: visible;
}

.btn-wrap {
  display: inline-block;
  margin-bottom: 2rem;
}
.btn-wrap > span {
  display: block;
  color: #ccc;
  margin-bottom: 0.3rem;
}

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0.85rem 1.5rem;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: -0.01em;
  min-width: 170px;
  border-radius: 0;
  white-space: normal;
  transition: all 0.3s;
}
.btn span {
  line-height: 1;
}
.btn i:first-child {
  margin-right: 1rem;
}
.btn i:last-child {
  margin-left: 1rem;
}
.btn:focus {
  box-shadow: none;
  outline: none !important;
}
.btn:focus .btn-text {
  display: none;
}
.btn:focus .btn-hover-text {
  display: inline-block;
}
.btn:active {
  outline: none !important;
  outline: none;
}
.btn:active:focus {
  box-shadow: none !important;
}
.btn:hover .btn-text {
  display: none;
}
.btn:hover .btn-hover-text {
  display: inline-block;
}

.btn.btn-minwidth-sm {
  min-width: 112px;
}

.btn.btn-minwidth {
  min-width: 170px;
}

.btn.btn-minwidth-lg {
  min-width: 218px;
}

.btn-rounded {
  border-radius: 0.2rem;
}

.btn-round {
  border-radius: 3rem;
}

.btn-lg {
  font-weight: 500;
  font-size: 1.5rem;
  min-width: 170px;
  padding: 1.1rem 2rem;
}
.btn-lg i:first-child {
  margin-right: 1.4rem;
}
.btn-lg i:last-child {
  margin-left: 1.4rem;
}

.btn-more {
  padding-top: 1.15rem;
  padding-bottom: 1.15rem;
  min-width: 230px;
  text-transform: uppercase;
}

.btn-link.btn-link-dark {
  color: #333;
}

.btn-hover-text {
  display: none;
}

.btn-shadow {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05) !important;
}
.btn-shadow:hover {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15) !important;
}
.btn-shadow:focus {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15) !important;
}
.btn-shadow:active {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15) !important;
}

.btn-outline-white-3 {
  color: #fff;
  background-color: transparent;
  background-image: none;
  border-color: #fff;
  box-shadow: none;
}
.btn-outline-white-3:hover {
  color: #333;
  background-color: #fff;
  border-color: #fff;
  box-shadow: none;
}
.btn-outline-white-3:focus {
  color: #333;
  background-color: #fff;
  border-color: #fff;
  box-shadow: none;
}
.btn-outline-white-3:not(:disabled):not(.disabled):active {
  color: #333;
  background-color: #fff;
  border-color: #fff;
  box-shadow: none;
}
.btn-outline-white-3:not(:disabled):not(.disabled).active {
  color: #333;
  background-color: #fff;
  border-color: #fff;
  box-shadow: none;
}
.btn-outline-white-3:disabled {
  color: #fff;
  background-color: transparent;
}

.btn-outline-white-3.focus {
  color: #333;
  background-color: #fff;
  border-color: #fff;
  box-shadow: none;
}

.btn-outline-white-3.disabled {
  color: #fff;
  background-color: transparent;
}

.product {
  position: relative;
  margin-bottom: 1rem;
  transition: box-shadow 0.35s ease;
  background-color: #fff;
  overflow: hidden;
}
.product:hover {
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
}
.product:hover .product-image-hover {
  opacity: 1;
}
.product:hover .product-countdown {
  opacity: 0;
  visibility: hidden;
}
.product:hover .product-countdown-container {
  opacity: 0;
  visibility: hidden;
}
.product:hover .product-action {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}
.product:hover .product-action-vertical {
  visibility: visible;
  opacity: 1;
  transform: translateX(0);
}
.product:focus .product-action {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}

.owl-carousel .product {
  margin-bottom: 0 !important;
}
.owl-carousel .instagram-feed {
  margin-bottom: 0;
}
.owl-carousel .entry {
  margin-bottom: 0;
}

.product-media {
  position: relative;
  display: block;
  background-color: #d7d7d7;
  margin-bottom: 0;
  overflow: hidden;
}
.product-media > a {
  display: block;
}

.product-image {
  display: block;
  width: 100%;
  height: auto;
}

.product-image-hover {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
  height: auto;
  transition: all 0.4s ease;
}

.product-countdown {
  position: absolute;
  left: 0.8rem;
  right: 0.8rem;
  bottom: 1.3rem;
  z-index: 6;
  transition: all 0.4s ease;
  visibility: visible;
  opacity: 1;
}
.product-countdown .countdown-show4 .countdown-section {
  width: calc(25% - 10px);
}
.product-countdown .countdown-section {
  font-size: 1rem;
  line-height: 1;
  padding: 1.4rem 0 1.2rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 0.4rem;
}
.product-countdown .countdown-section:not(:last-child):after {
  color: #fff;
  content: ":";
  display: inline-block;
  font-weight: 400;
  font-size: 20px;
  line-height: 1;
  position: absolute;
  left: 100%;
  margin-left: 3px;
  margin-top: -1px;
  top: 50%;
  transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}
.product-countdown .countdown-period {
  display: block;
  font-weight: 400;
  color: #333333;
  text-transform: lowercase;
  width: 100%;
  padding-left: 0.2rem;
  padding-right: 0.2rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.product-countdown .countdown-amount {
  display: inline-block;
  color: #ef837b;
  font-weight: 500;
  font-size: 1.8rem;
  letter-spacing: -0.03em;
  margin-bottom: 0.6rem;
}

.product-countdown-container {
  position: absolute;
  left: 1.4rem;
  right: 1.4rem;
  bottom: 1.3rem;
  z-index: 6;
  padding-top: 0.9rem;
  padding-bottom: 0.9rem;
  border-radius: 0.3rem;
  background-color: rgba(255, 255, 255, 0.95);
  letter-spacing: -0.01em;
  transition: all 0.4s ease;
  visibility: visible;
  opacity: 1;
}
.product-countdown-container .product-contdown-title {
  display: block;
  color: #333;
  font-weight: 400;
  font-size: 1.3rem;
  line-height: 1.2;
  margin-bottom: 0.3rem;
  text-align: center;
}
.product-countdown-container .product-countdown {
  position: relative;
  left: auto;
  right: auto;
  bottom: auto;
  z-index: 6;
  line-height: 1;
  opacity: 1;
}
.product-countdown-container .product-countdown .countdown-amount {
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1;
  margin-bottom: 0;
  text-transform: uppercase;
}

.product-body.product-action-inner {
  padding-right: 4rem;
}

.product-cat {
  color: #777;
  font-weight: 300;
  font-size: 1.3rem;
  line-height: 1.2;
  letter-spacing: -0.01em;
  margin-bottom: 0.3rem;
}
.product-cat a {
  color: inherit;
}
.product-cat a:hover {
  color: #666;
}
.product-cat a:focus {
  color: #666;
}

.new-price {
  color: #ef837b;
  margin-right: 0.8rem;
}

.old-price {
  color: #cccccc;
  text-decoration: line-through;
}

.out-price {
  color: #cccccc;
}

.out-text {
  display: block;
  width: 100%;
  font-size: 1.3rem;
  margin-top: 0.2rem;
}

.product-label {
  position: absolute;
  z-index: 1;
  top: 2rem;
  left: 2rem;
  font-weight: 400;
  font-size: 1.3rem;
  line-height: 1.6rem;
  letter-spacing: -0.01em;
  padding: 0.5rem 0.9rem;
  min-width: 45px;
  text-align: center;
  color: #333333;
  background-color: #fff;
}
.product-label + .product-label {
  top: 5rem;
}

.product-label.label-sale {
  color: #fff;
  background-color: #ef837b;
}

.product-label.label-new {
  color: #fff;
  background-color: #a6c76c;
}

.product-label.label-top {
  color: #fff;
  background-color: #7dd2ea;
}

.product-label.label-out {
  color: #fff;
  background-color: #ccc;
}

.label-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4.6rem;
  height: 4.6rem;
  min-width: 0;
  border-radius: 50%;
  line-height: 1;
}
.label-circle + .label-circle {
  top: 5.6rem;
}

.label-circle.label-out {
  font-size: 1.1rem;
}

.product-action {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.95);
  z-index: 10;
  transition: all 0.35s ease;
  opacity: 0;
  visibility: hidden;
  transform: translateY(100%);
}

.action-icon-top {
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
}
.action-icon-top .btn-product {
  padding-top: 1rem;
  padding-bottom: 1rem;
  flex-direction: column;
}
.action-icon-top .btn-product span {
  margin-top: 0.9rem;
}
.action-icon-top:before {
  margin-right: 0;
}

.product-action-dark {
  background-color: #333333;
}
.product-action-dark .btn-product:not(:hover):not(:focus) span {
  color: #fff;
}
.product-action-dark .btn-product + .btn-product {
  border-left-color: #4b4b4b;
}

.product-action-vertical {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 2rem;
  top: 2rem;
  background-color: transparent;
  z-index: 10;
  visibility: hidden;
  opacity: 0;
  transition: all 0.35s ease;
  transform: translateX(-15px);
}

.btn-cart:before {
  content: "\e812";
}

.btn-wishlist {
  font-size: 1.6rem;
}
.btn-wishlist:before {
  content: "\f234";
}

.btn-quickview:before {
  content: "\f145";
}

.btn-compare:before {
  content: "\f2d0";
}

.product-size {
  display: flex;
  align-items: center;
  padding-top: 0.2rem;
  margin-bottom: 1.6rem;
}
.product-size a {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 2.1rem;
  height: 2.1rem;
  font-weight: 400;
  font-size: 1.2rem;
  padding-top: 0.1rem;
  line-height: 1;
  color: #666666;
  background-color: #f7f8fa;
  border-radius: 0.2rem;
  transition: all 0.35s ease;
  margin-right: 0.5rem;
}
.product-size a:last-child {
  margin-right: 0;
}
.product-size a:hover {
  color: #fff;
  background-color: #333;
}
.product-size a:focus {
  color: #fff;
  background-color: #333;
}
.product-size a.active {
  color: #fff;
  background-color: #333;
}
.product-size a.disabled {
  color: #cccccc;
  pointer-events: none;
}

.product-nav {
  display: flex;
  align-items: center;
}

.product-nav-dots {
  margin-bottom: 0.5rem;
}
.product-nav-dots a {
  display: block;
  width: 1.7rem;
  height: 1.7rem;
  border-radius: 50%;
  border: 0.2rem solid #fff;
  margin-right: 0.5rem;
  transition: box-shadow 0.35s ease;
  background-repeat: no-repeat;
  background-position: center center;
}
.product-nav-dots a:hover {
  box-shadow: 0 0 0 0.1rem #cccccc;
}
.product-nav-dots a:focus {
  box-shadow: 0 0 0 0.1rem #cccccc;
}
.product-nav-dots a:last-child {
  margin-right: 0;
}
.product-nav-dots span {
  display: block;
  width: 1.7rem;
  height: 1.7rem;
  border-radius: 50%;
  border: 0.2rem solid #fff;
  margin-right: 0.5rem;
  transition: box-shadow 0.35s ease;
  background-repeat: no-repeat;
  background-position: center center;
}
.product-nav-dots span:hover {
  box-shadow: 0 0 0 0.1rem #cccccc;
}
.product-nav-dots span:focus {
  box-shadow: 0 0 0 0.1rem #cccccc;
}
.product-nav-dots span:last-child {
  margin-right: 0;
}
.product-nav-dots a.active {
  box-shadow: 0 0 0 0.1rem #cccccc;
}
.product-nav-dots span.active {
  box-shadow: 0 0 0 0.1rem #cccccc;
}

.product-nav-thumbs {
  padding-top: 0.2rem;
  margin-bottom: 1.6rem;
}
.product-nav-thumbs a {
  display: flex;
  align-items: center;
  width: 4.4rem;
  height: 4.4rem;
  border-radius: 0.2rem;
  border: 0.2rem solid #fff;
  margin-right: 0.4rem;
  transition: box-shadow 0.35s ease;
}
.product-nav-thumbs a:hover {
  box-shadow: 0 0 0 0.1rem #cccccc;
}
.product-nav-thumbs a:focus {
  box-shadow: 0 0 0 0.1rem #cccccc;
}
.product-nav-thumbs a:last-child {
  margin-right: 0;
}
.product-nav-thumbs span {
  display: flex;
  align-items: center;
  width: 4.4rem;
  height: 4.4rem;
  border-radius: 0.2rem;
  border: 0.2rem solid #fff;
  margin-right: 0.4rem;
  transition: box-shadow 0.35s ease;
}
.product-nav-thumbs span:hover {
  box-shadow: 0 0 0 0.1rem #cccccc;
}
.product-nav-thumbs span:focus {
  box-shadow: 0 0 0 0.1rem #cccccc;
}
.product-nav-thumbs span:last-child {
  margin-right: 0;
}
.product-nav-thumbs a.active {
  box-shadow: 0 0 0 0.1rem #cccccc;
}
.product-nav-thumbs span.active {
  box-shadow: 0 0 0 0.1rem #cccccc;
}
.product-nav-thumbs img {
  border-radius: 0.2rem;
}

.product-disabled {
  pointer-events: none;
}
.product-disabled .btn-product {
  pointer-events: none;
  color: #fff;
  background-color: #ccc !important;
}
.product-disabled .btn-product-icon {
  pointer-events: none;
  color: #fff;
  background-color: #ccc !important;
}

.product.text-center .product-price {
  justify-content: center;
}
.product.text-center .ratings-container {
  justify-content: center;
}
.product.text-center .product-nav {
  justify-content: center;
}
.product.text-center .product-size {
  justify-content: center;
}

.product.product-2 .product-body {
  padding-bottom: 0.4rem;
}
.product.product-2 .product-action {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.product.product-2 .btn-product {
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
}
.product.product-2 .btn-product + .btn-product {
  border-left-style: solid;
}
.product.product-2 .ratings-container:last-child {
  margin-bottom: 1.6rem;
}
.product.product-2 .product-action-vertical {
  top: 3rem;
}
.product.product-2 .product-nav-dots {
  margin-bottom: 2rem;
}

.product-footer {
  display: none;
  padding-top: 1.3rem;
}

.product.product-3 {
  position: relative;
  overflow: hidden;
  background-color: #fff;
}
.product.product-3 .product-action {
  position: absolute;
  bottom: 100%;
  border-bottom: 0.1rem solid #ebebeb;
  background-color: #fff;
  transform: translateY(0);
  transition-duration: 0.25s;
}
.product.product-3 .btn-product {
  padding-top: 1.05rem;
  padding-bottom: 1.05rem;
}
.product.product-3 .btn-product:not(:hover):not(:focus) span {
  color: #333;
}
.product.product-3 .product-price {
  margin-bottom: 0;
}
.product.product-3 .product-nav {
  margin-bottom: 0;
}
.product.product-3 .product-body {
  background-color: #fff;
}
.product.product-3 .product-footer {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  transform: translateY(100%);
  visibility: hidden;
  transition: all 0.35s ease;
  padding: 0 2rem 1rem;
}
.product.product-3:hover {
  box-shadow: none;
}

.product-4-carousel .owl-stage {
  padding-bottom: 4rem;
}

.product-5-carousel .owl-stage {
  padding-bottom: 7rem;
}

.product.product-6 {
  overflow: hidden;
}
.product.product-6 .product-body {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding-right: 2rem;
  padding-bottom: 0;
  transform: translateY(100%);
}
.product.product-6 .product-action {
  position: static;
  left: auto;
  right: auto;
  bottom: auto;
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
  background-color: transparent;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-top: 0.1rem solid #ebebeb;
  margin-top: 2.4rem;
}
.product.product-6 .btn-product {
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
}
.product.product-6 .btn-product + .btn-product {
  border-left-style: solid;
}
.product.product-6 .product-cat {
  margin-bottom: 0.5rem;
}
.product.product-6 .product-title {
  margin-bottom: 1rem;
}
.product.product-6:hover {
  box-shadow: none;
}
.product.product-6:hover .product-body {
  transform: translateY(0);
}

.product.product-9 .product-price {
  font-size: 1.4rem;
  font-weight: 500;
  letter-spacing: 0.01em;
  color: #333;
}
.product.product-9 .old-price {
  text-decoration: none;
}
.product.product-9 .label-sale {
  background-color: #c96;
  text-transform: uppercase;
}
.product.product-9 .product-body {
  padding: 1rem 2rem;
  transition: all 0.3s;
}
.product.product-9 .product-action {
  bottom: auto;
  background-color: #fff;
  text-transform: uppercase;
}
.product.product-9 .product-action::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 1rem;
  height: 0.1rem;
  background-color: #ebebeb;
}
.product.product-9 .product-detail {
  position: absolute;
  visibility: hidden;
  padding-top: 1rem;
  opacity: 0;
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.3s;
}
.product.product-9 .btn-product {
  padding-top: 1.2rem;
  padding-bottom: 2.2rem;
}
.product.product-9:hover {
  margin-bottom: -9rem !important;
  box-shadow: none;
}
.product.product-9:hover .product-body {
  transform: translateY(-100%);
}
.product.product-9:hover .product-action {
  transform: translateY(-100%);
}
.product.product-9:hover .product-intro {
  padding-bottom: 9rem;
}
.product.product-9:hover .product-detail {
  bottom: 1rem;
  opacity: 1;
  visibility: visible;
}
.product.product-9 .product-nav-dots a {
  width: 4rem;
  height: 5rem;
  border-radius: 0.5rem;
}
.product.product-9 .product-nav-dots span {
  width: 4rem;
  height: 5rem;
  border-radius: 0.5rem;
}

.product.product-10:hover .product-body {
  transform: translateY(-56px);
}
.product.product-10:hover .product-action {
  transform: translateY(22%);
}
.product.product-10:focus .product-body {
  transform: translateY(-56px);
}
.product.product-10:focus .product-action {
  transform: translateY(22%);
}
.product.product-10 .btn-expandable span {
  background-color: #fcb941;
}
.product.product-10 .product-media {
  background-color: #fff;
  padding: 2rem;
}
.product.product-10 .product-image-hover {
  padding: 2rem;
}
.product.product-10 .product-body {
  padding-bottom: 5rem;
}
.product.product-10 .product-body .btn-wishlist {
  width: -moz-fit-content;
  width: fit-content;
  left: 50%;
  right: 0;
  top: 4.5rem;
  transform: translateX(-50%);
}
.product.product-10 .product-body .btn-wishlist span {
  margin-left: 0.5rem;
  display: flex;
}
.product.product-10 .product-body .btn-wishlist:hover {
  color: #666;
}
.product.product-10 .product-body .btn-wishlist:hover::before {
  content: "\f233";
}
.product.product-10 .product-body .btn-wishlist:focus {
  color: #666;
}
.product.product-10 .product-body .btn-wishlist:focus::before {
  content: "\f233";
}
.product.product-10 .btn-product:hover span {
  color: #c96;
  box-shadow: 0 1px 0 0 #fcb941;
}
.product.product-10 .btn-product:focus span {
  color: #c96;
  box-shadow: 0 1px 0 0 #fcb941;
}
.product.product-10 .product-action {
  display: flex;
  justify-content: center;
}
.product.product-10 .btn-cart {
  padding: 0.5rem 3.5rem;
  border: 0.2rem solid #fcb941;
  border-radius: 0.5rem;
  color: #fcb941;
}
.product.product-10 .btn-cart span {
  text-transform: uppercase;
}
.product.product-10 .btn-cart::before {
  content: "";
}
.product.product-10 .btn-cart:hover {
  color: #222;
  background-color: #fcb941;
}
.product.product-10 .btn-cart:focus {
  color: #222;
  background-color: #fcb941;
}
.product.product-10 .btn-quickview {
  color: #fcb941;
}
.product.product-10 .btn-quickview:hover {
  color: #fff;
  background-color: #fcb941;
}
.product.product-10 .btn-quickview:focus {
  color: #fff;
  background-color: #fcb941;
}
.product.product-10 .product-title a {
  font-size: 1.3rem;
  font-weight: 400;
  font-family: "Poppins";
  letter-spacing: 0;
  color: #333;
}
.product.product-10 .product-title a:hover {
  color: #fcb941;
}
.product.product-10 .product-title a:focus {
  color: #fcb941;
}
.product.product-10 .product-price {
  margin-top: 0.8rem;
  font-size: 1.3rem;
  font-weight: 700;
  font-family: "Poppins";
  letter-spacing: 0;
  color: #333333;
}
.product.product-10 .new-price {
  font-size: 1.3rem;
  font-weight: 700;
  font-family: "Poppins";
  letter-spacing: 0;
}
.product.product-10 .old-price {
  font-size: 1.3rem;
  font-weight: 400;
  font-family: "Poppins";
  letter-spacing: 0;
  text-decoration: none;
}
.product.product-10 .product-label {
  left: 3rem;
  text-transform: uppercase;
  font-size: 1.3rem;
  font-weight: 700;
  font-family: "Poppins";
  letter-spacing: -0.01em;
}
.product.product-10 .product-label.label-deal {
  color: #fff;
  background-color: #ef837b;
  padding: 0.5rem 2rem;
  left: 0;
  box-shadow: 0 5px 10px rgba(34, 34, 34, 0.2);
}

.product.product-11 a:hover {
  color: #cc9966;
}
.product.product-11 a:focus {
  color: #cc9966;
}
.product.product-11 .btn-product-icon {
  color: #333;
  border: 0.1rem solid #ebebeb;
}
.product.product-11 .btn-product-icon:hover {
  color: #fff;
  background-color: #cc9966;
  border-color: #cc9966;
}
.product.product-11 .btn-product-icon:focus {
  color: #fff;
  background-color: #cc9966;
  border-color: #cc9966;
}
.product.product-11 .product-price {
  color: #333;
}
.product.product-11 .old-price {
  text-decoration: none;
}
.product.product-11 .product-body {
  padding-bottom: 7rem;
}
.product.product-11 .product-action {
  bottom: 2rem;
}
.product.product-11 .btn-product {
  text-transform: uppercase;
  color: #333;
  border: 0.1rem solid #ebebeb;
  margin-left: 2rem;
  margin-right: 2rem;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
}
.product.product-11 .btn-product span {
  font-size: 1.4rem;
}
.product.product-11 .btn-product:hover {
  color: #fff;
  border-color: #cc9966;
  background-color: #cc9966;
}
.product.product-11 .btn-product:hover span {
  color: #fff;
  box-shadow: none;
}
.product.product-11 .btn-product:focus {
  color: #fff;
  border-color: #cc9966;
  background-color: #cc9966;
}
.product.product-11 .btn-product:focus span {
  color: #fff;
  box-shadow: none;
}
.product.product-11:hover {
  z-index: 10;
}
.product.product-11:focus {
  z-index: 10;
}

.product.product-sm {
  display: flex;
  align-items: flex-start;
  margin-bottom: 2rem;
}
.product.product-sm:hover {
  box-shadow: none;
}
.product.product-sm .product-media {
  flex: 0 0 80px;
  max-width: 80px;
}
.product.product-sm .product-body {
  flex: 1 1 auto;
  padding: 1rem 0 0 2rem;
}
.product.product-sm .product-cat {
  color: #cccccc;
  font-weight: 400;
  font-size: 1.3rem;
  text-transform: uppercase;
  margin-bottom: 0.2rem;
}
.product.product-sm .product-title {
  font-size: 1.4rem;
  margin-bottom: 0.2rem;
}
.product.product-sm .product-price {
  font-size: 1.6rem;
  margin-bottom: 0;
}
.product.product-sm .product-price .old-price {
  text-decoration: none;
}

.banners-group {
  margin-bottom: 1.9rem;
}

.banner {
  display: block;
  position: relative;
  margin-bottom: 2rem;
  background-color: #ccc;
}
.banner > a {
  position: relative;
  display: block;
  outline: none !important;
}
.banner img {
  display: block;
  max-width: none;
  width: 100%;
  height: auto;
}
.banner:hover .banner-content-overlay {
  padding-bottom: 3.7rem;
  background-color: white;
}
.banner:hover .banner-content-overlay .banner-link {
  opacity: 1;
  transform: translateY(0);
  -ms-transform: translateY(0);
}
.banner:hover .banner-content-static {
  background-color: white;
}
.banner:hover .banner-content-static .banner-subtitle {
  opacity: 0;
}
.banner:hover .banner-content-static .banner-link {
  opacity: 1;
  transform: translateY(0);
  -ms-transform: translateY(0);
}

.banner.banner-menu img {
  height: 100%;
}

.banner-content {
  display: inline-block;
  position: absolute;
  padding-top: 0.4rem;
  left: 2rem;
  top: 50%;
  z-index: 2;
  transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}
.banner-content a:hover {
  text-decoration: underline;
}
.banner-content a:focus {
  text-decoration: underline;
}

.banner-big {
  color: #cccccc;
}
.banner-big .banner-content.banner-content-center {
  max-width: none;
  left: 0;
  right: 0;
  text-align: center;
}
.banner-big .banner-content {
  max-width: 470px;
  left: 3rem;
}
.banner-big .banner-subtitle {
  font-size: 1.8rem;
  margin-bottom: 0.6rem;
}
.banner-big .banner-title {
  font-size: 3rem;
  line-height: 1.15;
  margin-bottom: 1rem;
}
.banner-big .banner-title + .btn {
  margin-top: 1rem;
}
.banner-big p:last-of-type {
  margin-bottom: 2.3rem;
}

.banner-content-center.banner-content {
  max-width: none;
  left: 0;
  right: 0;
  text-align: center;
}

.banner-title {
  color: #333333;
  font-weight: 500;
  font-size: 2rem;
  line-height: 1.3;
  margin-bottom: 1.5rem;
  letter-spacing: -0.03em;
}
.banner-title a {
  color: inherit;
}
.banner-title a:hover {
  text-decoration: none;
}
.banner-title a:focus {
  text-decoration: none;
}

.banner-title.text-white a {
  color: #fff;
}

.banner-subtitle {
  color: #777;
  font-weight: 300;
  font-size: 1.6rem;
  line-height: 1.2;
  letter-spacing: 0;
  margin-bottom: 1rem;
}
.banner-subtitle a {
  color: inherit;
}
.banner-subtitle a:hover {
  text-decoration: none;
}
.banner-subtitle a:focus {
  text-decoration: none;
}

.banner-subtitle.text-white a {
  color: #fff;
}

.banner-link {
  font-weight: 400;
  display: inline-block;
}

.banner-display > a:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  background-color: rgba(51, 51, 51, 0.4);
  transition: all 0.35s ease;
}
.banner-display:hover > a:before {
  visibility: visible;
  opacity: 1;
}
.banner-display .banner-content {
  padding-top: 0;
}
.banner-display .banner-title {
  font-weight: 600;
  font-size: 2rem;
}

.banner-display.banner-link-anim .banner-title {
  margin-bottom: 0;
}
.banner-display.banner-link-anim:hover .banner-content {
  padding-bottom: 4.5rem;
}

.btn.banner-link {
  font-size: 1.4rem;
  line-height: 1;
  padding: 0.8rem 1.4rem;
  min-width: 0;
  text-transform: uppercase;
  text-decoration: none !important;
}
.btn.banner-link i {
  margin-right: 0;
  margin-left: 0;
}
.btn.banner-link i:first-child {
  margin-right: 0;
}
.btn.banner-link i:last-child {
  margin-left: 0.9rem;
}

.banner-overlay > a {
  position: relative;
}
.banner-overlay > a:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(51, 51, 51, 0.25);
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease;
}
.banner-overlay:hover > a:after {
  visibility: visible;
  opacity: 1;
}
.banner-overlay:focus > a:after {
  visibility: visible;
  opacity: 1;
}

.banner-overlay.banner-overlay-light > a:after {
  background-color: rgba(255, 255, 255, 0.3);
}

.video-banner p {
  max-width: 500px;
}
.video-banner p:last-child {
  margin-bottom: 0;
}

.video-banner.text-center p {
  margin-left: auto;
  margin-right: auto;
}

.video-banner-bg {
  padding-top: 8.5rem;
  padding-bottom: 7.5rem;
}

.video-banner-title {
  letter-spacing: -0.025em;
  margin-bottom: 3.6rem;
}
.video-banner-title > span {
  display: block;
  font-weight: 400;
  font-size: 2rem;
  letter-spacing: -0.03em;
  margin-bottom: 1.6rem;
}

.video-fullheight {
  display: flex;
  align-items: center;
}

.video-banner-poster .video-banner-title {
  font-weight: 400;
  margin-bottom: 1.6rem;
}
.video-banner-poster .video-banner-title span {
  font-weight: 300;
  font-size: 1.6rem;
}

.video-poster {
  position: relative;
}
.video-poster img {
  border-radius: 0.4rem;
}

.video-poster-content {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.video-poster-title {
  font-weight: 400;
  margin-bottom: 3rem;
  letter-spacing: -0.025em;
}

.video-banner-box {
  padding: 3rem;
}
.video-banner-box .video-poster img {
  border-radius: 0;
}

.video-box-content {
  padding-left: 0;
  max-width: 460px;
  font-size: 1.5rem;
  color: #777;
}
.video-box-content .video-banner-title {
  font-weight: 400;
  margin-bottom: 1.2rem;
}
.video-box-content .video-banner-title span {
  font-weight: 300;
  font-size: 1.8rem;
  margin-bottom: 0.6rem;
}
.video-box-content p:not(:last-child) {
  margin-bottom: 2rem;
}

.banner-cat .banner-title {
  margin-bottom: 0.1rem;
}
.banner-cat .banner-subtitle {
  margin-bottom: 1.6rem;
}

.banner-content-overlay {
  overflow: hidden;
  padding: 1.8rem 2rem 0.7rem;
  background-color: rgba(255, 255, 255, 0.9);
  left: 0;
  right: 0;
  top: auto;
  bottom: 0;
  transform: translateY(0);
  -ms-transform: translateY(0);
  transition: all 0.3s ease;
}
.banner-content-overlay .banner-subtitle {
  margin-bottom: 0.9rem;
}
.banner-content-overlay .banner-link {
  opacity: 0;
  position: absolute;
  bottom: 12px;
  left: 0;
  right: 0;
  transition: all 0.3s ease;
  transform: translateY(30px);
  -ms-transform: translateY(30px);
}

.banner-content-static {
  overflow: hidden;
  display: block;
  width: 100%;
  margin-top: 0;
  padding: 1.2rem 2rem;
  background-color: white;
  position: static;
  left: auto;
  right: auto;
  top: auto;
  bottom: auto;
  transform: translateY(0);
  -ms-transform: translateY(0);
  transition: all 0.3s ease;
}
.banner-content-static .banner-subtitle {
  transition: all 0.25s ease;
  margin-bottom: 0.9rem;
}
.banner-content-static .banner-link {
  opacity: 0;
  position: absolute;
  bottom: 19px;
  left: 0;
  right: 0;
  transition: all 0.3s ease;
  transform: translateY(30px);
  -ms-transform: translateY(30px);
}

.banner-content.banner-content-top {
  top: 105px;
}

.banner-content.banner-content-bottom {
  top: auto;
  bottom: 60px;
  transform: translateY(0);
  -ms-transform: translateY(0);
}

.banner-content.banner-content-right {
  left: auto;
  right: 30px;
}

.banner-link-anim {
  background-color: transparent;
}
.banner-link-anim .banner-content {
  transition: all 0.3s ease;
}
.banner-link-anim .banner-subtitle {
  margin-bottom: 0;
}
.banner-link-anim .banner-link {
  opacity: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  min-width: 130px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  transition: all 0.3s ease;
  transform: translateY(-20px);
  -ms-transform: translateY(-20px);
}
.banner-link-anim .banner-content-center .banner-link {
  left: 50%;
  transform: translateY(-20px) translateX(-50%);
  -ms-transform: translateY(-20px) translateX(-50%);
}
.banner-link-anim:hover .banner-content {
  padding-bottom: 4rem;
}
.banner-link-anim:hover .banner-link {
  opacity: 1;
  transform: translateY(0);
  -ms-transform: translateY(0);
}
.banner-link-anim:hover .banner-content-center .banner-link {
  transform: translateY(0) translateX(-50%);
  -ms-transform: translateY(0) translateX(-50%);
}

.social-icons {
  display: flex;
  align-items: center;
  flex-flow: row wrap;
}

.social-icon.social-facebook {
  color: #8f79ed;
}

.social-icon.social-twitter {
  color: #79c8ed;
}

.social-icon.social-instagram {
  color: #dd6d9a;
}

.social-icon.social-youtube {
  color: #e66262;
}

.social-icon.social-pinterest {
  color: #e66262;
}

.social-icon.social-linkedin {
  color: #3399cc;
}

.social-icons-color {
  line-height: 1;
}
.social-icons-color .social-icon {
  width: auto;
  height: auto;
  border: none;
}
.social-icons-color .social-icon:not(:last-child) {
  margin-right: 2rem;
}

.social-icons-simple {
  line-height: 1;
}
.social-icons-simple .social-icon {
  width: auto;
  height: auto;
  border: none;
}
.social-icons-simple .social-icon:not(:last-child) {
  margin-right: 2rem;
}

.social-label {
  margin-bottom: 0;
  margin-right: 2rem;
}

.social-icons-sm .social-icon {
  font-size: 1.2rem;
  width: 3rem;
  height: 3rem;
}
.social-icons-sm .social-icon:not(:last-child) {
  margin-right: 0.5rem;
}
.social-icons-sm .social-label {
  margin-right: 1rem;
}

.social-icons-colored .social-icon.social-facebook {
  color: #fff;
  border-color: #8f79ed;
  background-color: #8f79ed;
}
.social-icons-colored .social-icon.social-twitter {
  color: #fff;
  border-color: #79c8ed;
  background-color: #79c8ed;
}
.social-icons-colored .social-icon.social-instagram {
  color: #fff;
  border-color: #dd6d9a;
  background-color: #dd6d9a;
}
.social-icons-colored .social-icon.social-youtube {
  color: #fff;
  border-color: #e66262;
  background-color: #e66262;
}
.social-icons-colored .social-icon.social-pinterest {
  color: #fff;
  border-color: #e66262;
  background-color: #e66262;
}
.social-icons-colored .social-icon.social-linkedin {
  color: #fff;
  border-color: #3399cc;
  background-color: #3399cc;
}
.social-icons-colored .social-icon:hover {
  background-color: #fff !important;
  border-color: #d7d7d7 !important;
}
.social-icons-colored .social-icon:focus {
  background-color: #fff !important;
  border-color: #d7d7d7 !important;
}
.social-icons-colored .social-icon:hover.social-facebook {
  color: #8f79ed;
}
.social-icons-colored .social-icon:focus.social-facebook {
  color: #8f79ed;
}
.social-icons-colored .social-icon:hover.social-twitter {
  color: #79c8ed;
}
.social-icons-colored .social-icon:focus.social-twitter {
  color: #79c8ed;
}
.social-icons-colored .social-icon:hover.social-instagram {
  color: #dd6d9a;
}
.social-icons-colored .social-icon:focus.social-instagram {
  color: #dd6d9a;
}
.social-icons-colored .social-icon:hover.social-youtube {
  color: #e66262;
}
.social-icons-colored .social-icon:focus.social-youtube {
  color: #e66262;
}
.social-icons-colored .social-icon:hover.social-pinterest {
  color: #e66262;
}
.social-icons-colored .social-icon:focus.social-pinterest {
  color: #e66262;
}
.social-icons-colored .social-icon:hover.social-linkedin {
  color: #3399cc;
}
.social-icons-colored .social-icon:focus.social-linkedin {
  color: #3399cc;
}

.testimonial img {
  display: inline-block !important;
  max-width: 70px;
  border-radius: 50%;
  margin-top: 1rem;
  margin-bottom: 2.1rem;
}

.required-field > label::after {
  content: "*";
  color: #e02b27;
  font-size: 1.2rem;
  margin: 0 0 0 0.45rem;
}

.required {
  color: #e02b27;
  font-size: 1.2rem;
}
.required + .form-footer {
  margin-top: 0.8rem;
}

.cta .input-group {
  margin-bottom: 0;
}
.cta .form-control {
  height: 53px;
  padding-top: 1.15rem;
  padding-bottom: 1.15rem;
  background-color: #fff;
}
.cta .form-control:not(:focus):not(.form-control-white) {
  border-color: #dadada;
}
.cta .btn {
  padding-top: 1.15rem;
  padding-bottom: 1.15rem;
}
.cta .btn:not(.btn-block) {
  min-width: 170px;
}

.cta.bg-image {
  background-color: #f5f5f5;
}

.cta-title {
  font-weight: 500;
  font-size: 2rem;
  line-height: 1.2;
  letter-spacing: -0.03em;
  margin-bottom: 0;
}

.cta-desc {
  margin-bottom: 1.5rem;
}

.cta-horizontal .form-control {
  flex-grow: 1;
  margin-right: 2rem;
  margin-bottom: 0;
}
.cta-horizontal .cta-desc {
  margin-bottom: 0;
}

.cta-horizontal-box {
  padding-top: 5.2rem;
  padding-bottom: 4.8rem;
}

.cta-heading {
  padding-top: 0.7rem;
  margin-bottom: 2.7rem;
}
.cta-heading .cta-title {
  margin-bottom: 0.5rem;
}

.cta-text {
  padding-top: 0.5rem;
}
.cta-text .cta-title {
  margin-bottom: 2.1rem;
}
.cta-text .cta-desc {
  margin-bottom: 2.3rem;
}

.cta-separator {
  position: relative;
}
.cta-separator [class*=col-] + [class*=col-]:before {
  content: "";
  width: 0.1rem;
  display: block;
  height: 80%;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  background-color: #ebebeb;
}

.cta-separator.bg-image [class*=col-] + [class*=col-]:before {
  background-color: #dadada;
}

.cta-wrapper {
  padding-top: 5.6rem;
  padding-bottom: 5.6rem;
  max-width: 370px;
  margin-left: auto;
  margin-right: auto;
}
.cta-wrapper .cta-title {
  margin-bottom: 0.6rem;
}
.cta-wrapper .cta-desc {
  margin-bottom: 2rem;
}

.cta-wrapper.cta-text {
  max-width: 330px;
}
.cta-wrapper.cta-text .cta-title {
  margin-bottom: 1.6rem;
}
.cta-wrapper.cta-text .cta-desc {
  margin-bottom: 2.6rem;
}

.cta-border {
  padding: 3.9rem 2rem;
  border: 1rem solid #f5f5f5;
  text-align: center;
}
.cta-border .cta-content {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.cta-border .cta-heading {
  width: 100%;
  flex: 0 0 100%;
  max-width: 100%;
  margin: 0;
  padding-top: 0;
  padding-right: 0;
}
.cta-border .cta-title {
  margin: 0 0 2rem;
}
.cta-border .cta-text {
  position: relative;
  padding: 0;
  flex-grow: 1;
  margin-bottom: 2rem;
}
.cta-border .cta-text::before {
  content: "";
  background-color: #f5f5f5;
  width: 0.5rem;
  height: 9rem;
  position: absolute;
  left: -3px;
  top: 50%;
  margin-top: -4.5rem;
  display: none;
}
.cta-border p {
  max-width: 460px;
  margin-bottom: 0;
}

.count-container {
  margin-bottom: 3.3rem;
}

.count-title {
  font-weight: 400;
  font-size: 1.6rem;
  letter-spacing: -0.01em;
}

.pagination {
  border-radius: 0;
  align-items: center;
  margin-bottom: 2rem;
}

.page-link.page-link-prev {
  margin-right: 1.7rem;
  padding-left: 1rem;
  padding-right: 1rem;
  min-width: 70px;
}
.page-link.page-link-prev i {
  margin-left: 0;
  margin-right: 1rem;
}

.page-link.page-link-next {
  margin-left: 1.8rem;
  padding-left: 1rem;
  padding-right: 1rem;
  min-width: 70px;
}
.page-link.page-link-next i {
  margin-left: 1rem;
  margin-right: 0;
}

.page-item-total {
  color: #cccccc;
  font-size: 1.4rem;
  font-weight: 400;
  margin-left: 1.2rem;
}

.page-item + .page-item {
  margin-left: 0.4rem;
}
.page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.page-item.disabled .page-link {
  border-color: transparent;
  color: #eaeaea;
}

.modal-backdrop {
  background-color: #333;
}

.modal-backdrop.show {
  opacity: 0.4;
}

.modal-content {
  border: none;
  box-shadow: 5px 10px 16px rgba(51, 51, 51, 0.05), -5px 10px 16px rgba(51, 51, 51, 0.05);
}

.alert {
  font-weight: 300;
  font-size: 1.4rem;
  line-height: 1.43;
  letter-spacing: -0.01em;
  border-radius: 0;
  margin: 0;
  padding: 1rem 1.5rem;
  border: none;
}
.alert .container {
  position: relative;
}
.alert a {
  color: rgba(255, 255, 255, 0.7);
}
.alert a:hover {
  color: rgba(255, 255, 255, 0.9);
}
.alert a:focus {
  color: rgba(255, 255, 255, 0.9);
}
.alert .close {
  color: rgba(255, 255, 255, 0.5);
  font-weight: 300;
  font-size: 1.6rem;
  line-height: 1;
  top: 50%;
  transform: translateY(-50%);
}
.alert .close span {
  display: inline-block;
  line-height: 1;
  padding-bottom: 0.2rem;
}

.alert-dark {
  color: #fff;
  background-color: #333;
}

.alert-danger {
  color: #fff;
  background-color: #da2828;
}

.alert-info {
  color: #fff;
  background-color: #32b1d6;
}

.alert-success {
  color: #fff;
  background-color: #20cb55;
}

.alert-warning {
  color: #fff;
  background-color: #ea8745;
}

.elements > div {
  padding: 1rem;
}
.elements > div:nth-child(1) .element-img {
  background-position: 0px 0;
}
.elements > div:nth-child(1) .element-hover-img {
  background-position: -100px 0;
}
.elements > div:nth-child(2) .element-img {
  background-position: -200px 0;
}
.elements > div:nth-child(2) .element-hover-img {
  background-position: -300px 0;
}
.elements > div:nth-child(3) .element-img {
  background-position: -400px 0;
}
.elements > div:nth-child(3) .element-hover-img {
  background-position: -500px 0;
}
.elements > div:nth-child(4) .element-img {
  background-position: -600px 0;
}
.elements > div:nth-child(4) .element-hover-img {
  background-position: -700px 0;
}
.elements > div:nth-child(5) .element-img {
  background-position: -800px 0;
}
.elements > div:nth-child(5) .element-hover-img {
  background-position: -900px 0;
}
.elements > div:nth-child(6) .element-img {
  background-position: -1000px 0;
}
.elements > div:nth-child(6) .element-hover-img {
  background-position: -1100px 0;
}
.elements > div:nth-child(7) .element-img {
  background-position: -1200px 0;
}
.elements > div:nth-child(7) .element-hover-img {
  background-position: -1300px 0;
}
.elements > div:nth-child(8) .element-img {
  background-position: -1400px 0;
}
.elements > div:nth-child(8) .element-hover-img {
  background-position: -1500px 0;
}
.elements > div:nth-child(9) .element-img {
  background-position: -1600px 0;
}
.elements > div:nth-child(9) .element-hover-img {
  background-position: -1700px 0;
}
.elements > div:nth-child(10) .element-img {
  background-position: -1800px 0;
}
.elements > div:nth-child(10) .element-hover-img {
  background-position: -1900px 0;
}
.elements > div:nth-child(11) .element-img {
  background-position: -2000px 0;
}
.elements > div:nth-child(11) .element-hover-img {
  background-position: -2100px 0;
}
.elements > div:nth-child(12) .element-img {
  background-position: -2200px 0;
}
.elements > div:nth-child(12) .element-hover-img {
  background-position: -2300px 0;
}
.elements > div:nth-child(13) .element-img {
  background-position: -2400px 0;
}
.elements > div:nth-child(13) .element-hover-img {
  background-position: -2500px 0;
}
.elements > div:nth-child(14) .element-img {
  background-position: -2600px 0;
}
.elements > div:nth-child(14) .element-hover-img {
  background-position: -2700px 0;
}
.elements > div > a {
  display: block;
}

.element {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 0.1rem solid #ebebeb;
  position: relative;
  transition: all 0.3s;
}
.element:hover {
  box-shadow: 0 5px 20px rgba(234, 234, 234, 0.8);
}
.element:hover .element-hover-img {
  visibility: visible;
  opacity: 1;
}
.element:hover .element-img {
  opacity: 0;
}
.element:hover p {
  color: #333;
}
.element:focus {
  box-shadow: 0 5px 20px rgba(234, 234, 234, 0.8);
}
.element:focus .element-hover-img {
  visibility: visible;
  opacity: 1;
}
.element:focus .element-img {
  opacity: 0;
}
.element:focus p {
  color: #333;
}
.element p {
  margin-top: -1rem;
  padding-bottom: 1rem;
}

.element-img {
  width: 100px;
  height: 100px;
  background-repeat: no-repeat;
  transition: all 0.3s;
}

.element-hover-img {
  width: 100px;
  height: 100px;
  background-repeat: no-repeat;
  transition: all 0.3s;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s;
}

.element-type {
  font-size: 1.3rem;
  font-weight: 400;
  font-family: "Poppins";
  letter-spacing: 0.01em;
  color: #666;
  text-transform: uppercase;
}

.intro-section {
  margin-top: -1px;
}

.intro-banners .banner {
  margin-bottom: 2rem;
}

.text-darkwhite {
  color: #ebebeb;
}

.intro-slider-container {
  background-color: #cccccc;
  position: relative;
}
.intro-slider-container:before {
  display: block;
  content: "";
  width: 100%;
}

.intro-slide {
  background-color: #cccccc;
}
.intro-slide .slide-image {
  position: relative;
}
.intro-slide .slide-image:before {
  display: block;
  content: "";
  width: 100%;
}

.slider-container-1:before {
  padding-top: 56.5384615385%;
}

.intro-slider-1 .slide-image:before {
  padding-top: 56.5384615385%;
}

.slider-container-ratio .intro-slider {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  width: 100%;
}

.slide-image > picture {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  width: 100%;
}
.slide-image > img {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  width: 100%;
}
.slide-image img {
  display: block;
  width: 100%;
  max-width: none;
  height: auto;
}

.slider-loader {
  color: #333;
}
.slider-loader::after {
  content: "\f311";
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5rem;
  height: 5rem;
  margin-top: -2.5rem;
  margin-left: -2.5rem;
  font-family: "molla";
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 4rem;
  transform-origin: center center;
  animation: rotating 3s linear infinite;
  z-index: 20;
}

.owl-loaded + .slider-loader {
  display: none;
}

button:focus {
  outline: none;
}

.intro-content {
  position: absolute;
  left: 20px;
  top: 50%;
  z-index: 10;
  transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}
.intro-content .btn {
  font-size: 1.2rem;
  min-width: 90px;
  padding: 0.65rem 1rem;
}

.intro-subtitle {
  color: #fff;
  font-weight: 300;
  font-size: 1.4rem;
  letter-spacing: -0.01em;
  margin-bottom: 0.5rem;
}

.intro-title {
  color: #fff;
  font-weight: 700;
  font-size: 3rem;
  line-height: 1.2;
  letter-spacing: -0.03em;
  margin-bottom: 1.2rem;
  text-indent: -0.2rem;
}

.brand {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 48px;
}
.brand img {
  width: auto !important;
  max-width: 100% !important;
}
.brand:focus {
  outline: none !important;
}

.mt-v3 {
  margin-top: -3rem;
}

.categories.container {
  position: relative;
}
.categories.container::before {
  content: "";
  position: absolute;
  left: 1rem;
  right: 1rem;
  height: 0.1rem;
  top: 0;
  background-color: #ebebeb;
}

.more-container {
  margin-bottom: 7rem;
}

.carousel-with-shadow .owl-stage-outer {
  padding: 0 1rem 2rem;
  margin-left: -1rem;
  margin-right: -1rem;
  margin-top: -3rem;
  padding-top: 3rem;
}

.carousel-with-shadow.owl-simple .owl-nav [class*=owl-] {
  margin-top: -31px;
}

.blog-posts .entry {
  color: #777;
  font-size: 0.8rem;
  line-height: 1.73;
  letter-spacing: -0.01em;
  margin-bottom: 3rem;
}
.blog-posts .entry-media {
  margin-bottom: 1.7rem;
}
.blog-posts .entry-meta {
  color: #cccccc;
  margin-bottom: 0.8rem;
}
.blog-posts .entry-title {
  font-weight: 500;
  font-size: 1.1rem;
  letter-spacing: -0.01em;
  margin-bottom: 0.5rem;
}
.blog-posts .read-more {
  padding-right: 0;
  font-weight: 400;
  text-transform: uppercase;
}
.blog-posts .read-more:after {
  display: none;
}
.blog-posts .read-more:hover {
  padding-right: 0;
}
.blog-posts .read-more:focus {
  padding-right: 0;
}

.entry.entry-display {
  transition: box-shadow 0.35s ease;
}
.entry.entry-display .entry-body {
  padding: 0 2rem 2.5rem;
}
.entry.entry-display:hover {
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.03), -5px 5px 10px rgba(0, 0, 0, 0.03);
}

.cta-display .cta-title {
  font-weight: 600;
  font-size: 2.6rem;
  letter-spacing: -0.01em;
}
.cta-display .cta-desc {
  font-size: 1.6rem;
  margin-bottom: 0;
}

.cta-display-content {
  max-width: 630px;
  margin-left: auto;
  margin-right: auto;
}

.cross-txt {
  position: absolute;
  left: -1px;
  right: -1px;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
}
.cross-txt:before {
  content: "";
  flex: 1;
  height: 1px;
  background: #fff;
  margin-right: 2.5rem;
}
.cross-txt:after {
  content: "";
  flex: 1;
  height: 1px;
  background: #fff;
  margin-left: 2.5rem;
}

.mfp-ready.mfp-bg {
  opacity: 0.6;
}

.container.quickView-container {
  width: 1188px;
  max-width: 100%;
}

.fullscreen-container {
  position: relative;
  margin-left: -0.8rem;
  margin-right: -0.8rem;
}
.fullscreen-container .container {
  width: 1188px;
  max-width: 100%;
}
.fullscreen-container button.mfp-close {
  color: #666;
  font-size: 1.8rem;
  top: 1.5rem;
  right: 2rem;
  display: block;
  width: 2rem;
  height: 2rem;
}
.fullscreen-container .carousel-dots {
  margin-top: 2rem;
  display: flex;
  padding-left: 6rem;
  padding-right: 6rem;
}
.fullscreen-container .carousel-dot {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.fullscreen-container .quickView-content {
  padding: 1rem 0 4rem;
}

.container.newsletter-popup-container {
  width: 1188px;
  max-width: 100%;
}

.quickView-container {
  position: relative;
}
.quickView-container button.mfp-close {
  color: #666;
  font-size: 1.8rem;
  top: 1.5rem;
  right: 2rem;
  display: block;
  width: 2rem;
  height: 2rem;
}

.mfp-newsletter.mfp-bg {
  background: #222;
  opacity: 0.6;
}

.mfp-wrap .mfp-content {
  transition: all 0.35s;
  opacity: 0;
}

.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
}

.btn-layout {
  display: inline-flex;
  padding: 0.5rem;
}
.btn-layout + .btn-layout {
  margin-left: 0.5rem;
}
.btn-layout svg {
  fill: #dadada;
  transition: fill 0.35s;
}
.btn-layout:hover svg {
  fill: #333;
}
.btn-layout:focus svg {
  fill: #333;
}

.btn-layout.active svg {
  fill: #333;
}

.products > .row > [class*=col-] {
  display: flex;
  align-items: stretch;
  align-content: stretch;
}
.products .product {
  width: 100%;
}

.carousel-equal-height .owl-stage {
  display: flex;
  align-items: stretch;
}
.carousel-equal-height .owl-item {
  display: flex;
  align-items: stretch;
}
.carousel-equal-height .product {
  width: 100%;
}

.load-more-container {
  margin-top: 4rem;
  margin-bottom: 2rem;
}

.btn.btn-load-more {
  text-transform: uppercase;
  min-width: 190px;
  justify-content: space-between;
  padding-left: 2rem;
  padding-right: 2rem;
}
.btn.btn-load-more i {
  margin: 0 !important;
}

.sidebar-shop .widget {
  color: #333333;
  margin: 0 0 2rem;
  border-bottom: 0.1rem solid #ebebeb;
}
.sidebar-shop .widget:last-child {
  border-bottom: none;
}
.sidebar-shop .widget-title {
  color: #333333;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 1.15;
  letter-spacing: -0.01em;
  margin-bottom: 0.7rem;
}
.sidebar-shop .custom-control {
  padding-left: 2.7rem;
  margin: 0;
}
.sidebar-shop .custom-control-label {
  font-weight: 400;
  color: #333333;
  cursor: pointer;
}
.sidebar-shop .custom-control-label::before {
  border-radius: 0.2rem;
}

.widget-clean {
  display: flex;
  align-items: center;
  padding-top: 0.6rem;
  padding-bottom: 2.1rem;
}
.widget-clean label {
  color: inherit;
  font-weight: 400;
  font-size: 1.4rem;
  letter-spacing: -0.01em;
  margin: 0;
}
.widget-clean a {
  display: inline-block;
  font-weight: 400;
  font-size: 1.2rem;
  margin-left: auto;
}

.widget-collapsible .widget-title a {
  position: relative;
  display: block;
  color: inherit;
  text-decoration: none;
  padding-right: 3rem;
}
.widget-collapsible .widget-title a:after {
  color: #333333;
  content: "\f110";
  display: block;
  font-family: "molla";
  font-size: 1.4rem;
  line-height: 1;
  position: absolute;
  top: 50%;
  right: 1.6rem;
  margin-top: -0.7rem;
}
.widget-collapsible .widget-title a:focus {
  outline: none !important;
}
.widget-collapsible .widget-title a.collapsed:after {
  content: "\e802";
}
.widget-collapsible .widget-body {
  padding-top: 1rem;
  padding-bottom: 2rem;
}

.filter-item {
  position: relative;
  margin-bottom: 0.3rem;
}

.filter-items-count .filter-item {
  padding-right: 4rem;
}
.filter-items-count .item-count {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 1rem;
  top: 50%;
  min-width: 2.5rem;
  height: 2rem;
  margin-top: -1rem;
  font-weight: 300;
  font-size: 1.3rem;
  line-height: 1;
  border-radius: 0.6rem;
  color: #777;
  background-color: #f8f8f8;
}

.sub-filter-items {
  padding-left: 2.7rem;
}

.filter-colors {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 0.7rem;
  padding-bottom: 0.5rem;
}
.filter-colors a {
  position: relative;
  display: block;
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 50%;
  border: 0.2rem solid #fff;
  margin: 0 0.3rem 0.3rem;
  transition: box-shadow 0.35s ease;
}
.filter-colors a:after {
  color: #fff;
  font-family: "molla";
  content: "\ec2d";
  font-size: 1rem;
  line-height: 1;
  position: absolute;
  left: 50%;
  top: 50%;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.01s;
  margin-top: -0.4rem;
  margin-left: -0.5rem;
}
.filter-colors a:hover {
  box-shadow: 0 0 0 0.1rem #cccccc;
}
.filter-colors a:focus {
  box-shadow: 0 0 0 0.1rem #cccccc;
}
.filter-colors a.selected {
  box-shadow: 0 0 0 0.1rem #cccccc;
}
.filter-colors a.selected:after {
  visibility: visible;
  opacity: 1;
}

.filter-price {
  padding-top: 0.2rem;
  padding-bottom: 3rem;
}

.filter-price-text {
  margin-bottom: 2rem;
  letter-spacing: -0.01em;
}

.product-details-top {
  margin-bottom: 1rem;
}

.product-gallery {
  margin-bottom: 2rem;
}

.product-main-image {
  position: relative;
  margin-bottom: 1rem;
}
.product-main-image img {
  max-width: none;
  width: 100%;
}

.product-image-gallery {
  display: flex;
  flex-flow: row wrap;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}

.max-col-6 .product-gallery-item {
  flex: 0 0 16.66%;
  max-width: 16.66%;
}

.product-gallery-item.active:before {
  visibility: visible;
  opacity: 1;
}
.product-gallery-item.active:after {
  opacity: 0;
  visibility: hidden;
}

.product-gallery-vertical .row {
  margin-left: 0;
  margin-right: 0;
}
.product-gallery-vertical .product-image-gallery {
  width: 100%;
  width: calc(100% + 10px);
}
.product-gallery-vertical .product-main-image {
  width: 100%;
}

.product-gallery-masonry {
  position: relative;
  display: block !important;
}
.product-gallery-masonry .product-gallery-item {
  max-width: 50%;
  width: 50%;
}

.product-gallery-carousel.owl-carousel {
  z-index: initial;
}
.product-gallery-carousel.owl-carousel .owl-item:not(.active) {
  pointer-events: none;
}

.product-gallery-image {
  margin-bottom: 0;
  padding-right: 1rem;
}

.product-gallery-separated {
  position: relative;
}

.product-separated-item {
  position: relative;
  display: block;
  margin-bottom: 1rem;
}
.product-separated-item img {
  max-width: none;
  width: 100%;
}

.product-pager {
  display: flex;
  align-items: center;
}

.product-pager-prev i {
  margin-right: 0.5rem;
}

.product-pager-next i {
  margin-left: 0.5rem;
}

.details-filter-row {
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  margin-bottom: 2rem;
}
.details-filter-row .form-control {
  font-weight: 400;
  margin-bottom: 0;
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: #fff;
  border-color: #d7d7d7;
  min-width: 131px;
}
.details-filter-row .select-custom {
  margin-bottom: 0;
  margin-right: 3.9rem;
}
.details-filter-row .select-custom .form-control {
  padding-right: 3rem;
}
.details-filter-row .select-custom::after {
  font-size: 1rem;
  right: 0.9rem;
}
.details-filter-row label {
  display: inline-block;
  width: 67px;
  font-weight: 400;
  font-size: 1.4rem;
  margin-bottom: 0;
}
.details-filter-row .product-nav {
  margin-bottom: 0;
}
.details-filter-row .product-nav-thumbs {
  padding-top: 0;
}

.product-details-quantity {
  max-width: 131px;
}
.product-details-quantity .form-control {
  padding-left: 2.6rem;
  padding-right: 2.6rem;
  text-align: center;
  margin-bottom: 0;
}

.details-action-wrapper {
  display: flex;
  align-items: center;
  margin-left: 4rem;
}
.details-action-wrapper .btn-product {
  padding-top: 0;
  padding-bottom: 0;
  white-space: nowrap;
  align-self: stretch;
  line-height: 1.4;
}
.details-action-wrapper .btn-product:before {
  margin-right: 1rem;
}
.details-action-wrapper .btn-product span {
  font-size: 1.4rem;
}
.details-action-wrapper .btn-product + .btn-product {
  padding-left: 1.3rem;
  margin-left: 1.6rem;
}
.details-action-wrapper .btn-product.btn-wishlist:before {
  margin-top: -0.2rem;
}

.product-details-footer {
  color: #777;
  display: flex;
  font-weight: 400;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 1.7rem;
  border-top: 0.1rem solid #ebebeb;
  padding-bottom: 1.7rem;
}
.product-details-footer .social-icons {
  margin-left: auto;
}
.product-details-footer .social-icon {
  margin-bottom: 0;
}

.reviews h3 {
  font-size: 1.6rem;
  letter-spacing: -0.01em;
  margin-bottom: 2.3rem;
}

.review-content {
  margin-bottom: 0.8rem;
}

.product-details-centered {
  text-align: center;
}
.product-details-centered .ratings-container {
  justify-content: center;
}
.product-details-centered .product-price {
  justify-content: center;
}
.product-details-centered .details-filter-row {
  justify-content: center;
}
.product-details-centered .details-filter-row label {
  width: auto;
  margin-right: 1rem;
}
.product-details-centered .details-filter-row .form-control {
  min-width: 104px;
}
.product-details-centered .product-content {
  max-width: 470px;
  margin-left: auto;
  margin-right: auto;
}
.product-details-centered .details-row-size .select-custom {
  margin-right: 2rem;
}
.product-details-centered .product-details-action {
  flex-direction: column;
  align-items: center;
}
.product-details-centered .product-details-action .btn-cart {
  min-width: 218px;
}
.product-details-centered .details-action-wrapper {
  margin-left: 0;
}
.product-details-centered .details-action-wrapper .btn-product + .btn-product {
  border-left-width: 0;
}
.product-details-centered .product-details-quantity {
  max-width: 100px;
}
.product-details-centered .details-footer-col {
  align-items: center;
}

.details-action-col {
  display: flex;
  align-items: center;
  margin-bottom: 2.2rem;
}
.details-action-col .product-details-quantity {
  margin-right: 2rem;
}
.details-action-col label {
  display: inline-block;
  width: 67px;
  font-weight: 400;
  font-size: 1.4rem;
  margin-bottom: 0;
}

.product-desc-row {
  display: flex;
  align-items: center;
  padding-top: 5rem;
  padding-bottom: 5rem;
  min-height: 500px;
}

.product-desc-row.text-white h2 {
  color: inherit;
}
.product-desc-row.text-white h3 {
  color: inherit;
}
.product-desc-row.text-white h4 {
  color: inherit;
}

.product-details-separator {
  margin-bottom: 6rem;
}
.product-details-separator .details-filter-row label {
  margin-right: 2.4rem;
}
.product-details-separator .details-filter-row .product-size {
  margin-right: 2rem;
}

.details-footer-col {
  padding-top: 0;
  padding-bottom: 0;
  flex-direction: column;
  align-items: flex-start;
  border-top: none;
}
.details-footer-col .product-cat {
  margin-bottom: 2.6rem;
}
.details-footer-col .social-icons {
  margin-left: 0;
}

.product-details-accordion .card {
  border: 0.1rem solid #ebebeb;
}
.product-details-accordion .card .card-title {
  font-weight: 400;
}
.product-details-accordion .card .card-title a {
  background-color: #fafafa;
}
.product-details-accordion .card .card-title a:not(.collapsed) {
  background-color: #fff;
}
.product-details-accordion .card .card-body {
  padding-top: 0;
}
.product-details-accordion .card .card-body p:last-child {
  margin-bottom: 0.2rem;
}

.product-details-sidebar {
  margin-bottom: 3rem;
}
.product-details-sidebar .details-filter-row .select-custom {
  margin-right: 1.9rem;
}
.product-details-sidebar .product-details-action {
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 2.6rem;
}
.product-details-sidebar .details-action-wrapper {
  margin-left: 0;
}
.product-details-sidebar .details-action-col {
  width: 100%;
}
.product-details-sidebar .details-action-col .btn-product {
  flex: 1 1 auto;
  max-width: 230px;
}

.sidebar-product {
  max-width: 280px;
  margin-left: auto;
  margin-right: auto;
}
.sidebar-product .widget {
  margin-bottom: 4.5rem;
}
.sidebar-product .widget-title {
  font-size: 2.4rem;
  letter-spacing: -0.025em;
  margin-bottom: 2.4rem;
}
.sidebar-product .products {
  margin-bottom: 3rem;
}
.sidebar-product .btn {
  display: block;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
}

.sticky-bar {
  display: none;
}

.entry {
  position: relative;
  margin-bottom: 3rem;
  font-size: 1.4rem;
  line-height: 1.85;
}

.entry-media {
  position: relative;
  background-color: #ccc;
  margin-bottom: 2.4rem;
}
.entry-media > a {
  position: relative;
  display: block;
}
.entry-media img {
  display: block;
  max-width: none;
  width: 100%;
  height: auto;
}

.text-center .entry-meta {
  justify-content: center;
}

.meta-separator {
  margin-left: 1.1rem;
  margin-right: 1.1rem;
}

.entry-content p {
  margin-bottom: 0.8rem;
}

.menu-cat.justify-content-center li {
  margin-left: 1.8rem;
  margin-right: 1.8rem;
}
.menu-cat.justify-content-center li:lat-child {
  margin-right: 1.8rem;
}

.entry-grid .entry-title {
  font-size: 1.8rem;
}
.entry-grid .entry-media {
  margin-bottom: 1.4rem;
}
.entry-grid .entry-cats {
  margin-bottom: 0.9rem;
}

.entry-container {
  position: relative;
  margin: 0 -1rem 0.5rem;
  transition: height 0.4s;
}
.entry-container::after {
  display: block;
  clear: both;
  content: "";
}

.entry-item {
  float: left;
  padding: 0 10px;
}

.entry-list p {
  margin-bottom: 1rem;
}

.entry-mask.entry {
  margin-bottom: 2rem;
}

.entry-mask:before {
  content: "";
  display: block;
  height: 50%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
  background: linear-gradient(to bottom, rgba(125, 185, 232, 0) 0%, black 100%);
  opacity: 0.75;
  transition: all 0.3s;
  pointer-events: none;
}
.entry-mask .entry-media {
  margin-bottom: 0;
}
.entry-mask .entry-video a:after {
  width: auto;
  height: auto;
  left: 3rem;
  top: 3rem;
  margin-left: 0;
  margin-top: 0;
  font-size: 1.6rem;
  line-height: 1;
  border: none;
}
.entry-mask .entry-video a:hover:after {
  color: #fff;
  background-color: transparent;
  box-shadow: none;
}
.entry-mask .entry-video a:focus:after {
  color: #fff;
  background-color: transparent;
  box-shadow: none;
}
.entry-mask .entry-gallery a:after {
  width: auto;
  height: auto;
  left: 3rem;
  margin-left: 0;
  margin-top: 0;
  font-size: 1.6rem;
  line-height: 1;
  border: none;
  color: #fff;
  position: absolute;
  content: "\f24d";
  font-family: "molla";
  top: 2.6rem;
}
.entry-mask .entry-gallery a:hover:after {
  color: #fff;
  background-color: transparent;
  box-shadow: none;
}
.entry-mask .entry-gallery a:focus:after {
  color: #fff;
  background-color: transparent;
  box-shadow: none;
}
.entry-mask .entry-body {
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 1.7rem 1.8rem 2.7rem 2.8rem;
  z-index: 5;
}
.entry-mask .entry-meta {
  color: #ebebeb;
}
.entry-mask .entry-cats {
  color: #ebebeb;
  margin-bottom: 0;
}
.entry-mask .entry-title {
  color: #fff;
  font-size: 2rem;
}
.entry-mask:hover:before {
  opacity: 0.9;
}

.widget {
  margin-bottom: 4.5rem;
}

.sidebar {
  margin-top: 3rem;
}
.sidebar .widget:last-child {
  margin-bottom: 1.5rem;
}

.widget-title {
  color: #333;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 1.1;
  letter-spacing: -0.01em;
  margin-bottom: 2rem;
}

.widget-banner-sidebar {
  position: relative;
}

.banner-sidebar-title {
  color: #777;
  text-align: center;
  font-weight: 300;
  font-size: 1.3rem;
  line-height: 1;
  letter-spacing: -0.01em;
  margin-bottom: 0.8rem;
  text-transform: uppercase;
}

.banner-sidebar {
  position: relative;
  text-align: center;
  line-height: 0;
}
.banner-sidebar > a {
  display: inline-block;
}

.widget-text p:last-child {
  margin-bottom: 0;
}

.entry.single-entry {
  margin-bottom: 4rem;
}

.single-entry .entry-body {
  margin-bottom: 4rem;
}
.single-entry .entry-meta {
  color: #ccc;
}
.single-entry .entry-meta a:not(:hover):not(:focus) {
  color: #666;
}
.single-entry .entry-cats {
  color: #ccc;
}
.single-entry .entry-cats a:not(:hover):not(:focus) {
  color: #666;
}
.single-entry .entry-title-big {
  font-weight: 400;
  font-size: 2.8rem;
}

.entry-footer {
  margin-top: 3.6rem;
  margin-bottom: 5rem;
  align-items: center;
}
.entry-footer .social-icon {
  font-size: 1.3rem;
}

.author-media {
  flex: 0 0 50px;
  max-width: 50px;
}
.author-media img {
  border-radius: 50%;
}

.comment-media {
  flex: 0 0 50px;
  max-width: 50px;
}
.comment-media img {
  border-radius: 50%;
}

.author-content p:last-child {
  margin-bottom: 0;
}

.comment-content p:last-child {
  margin-bottom: 0;
}

.pager-nav {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 4.5rem;
  padding-bottom: 1rem;
}

.pager-link.pager-link-prev {
  padding-left: 10rem;
  padding-right: 1rem;
}
.pager-link.pager-link-prev:after {
  content: "\f273";
  left: 3rem;
}

.pager-link.pager-link-next {
  padding-left: 1rem;
  padding-right: 10rem;
  align-items: flex-end;
  text-align: right;
}
.pager-link.pager-link-next:after {
  content: "\f274";
  right: 3rem;
}

.pager-link-title {
  display: inline-block;
  margin-top: 0.6rem;
  flex: 0 0 auto;
  color: #333;
  font-size: 1.5rem;
  transition: all 0.35s ease;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.related-posts {
  padding-bottom: 4.5rem;
  margin-bottom: 4.5rem;
  border-bottom: 0.1rem solid #ebebeb;
}
.related-posts .title {
  font-weight: 600;
  font-size: 1.8rem;
  letter-spacing: -0.025em;
  margin-bottom: 3rem;
}
.related-posts .entry.entry-grid {
  margin-bottom: 0;
}
.related-posts .entry-grid .entry-title {
  font-weight: 400;
}

.entry-fullwidth .entry-body {
  position: relative;
}
.entry-fullwidth .author-body {
  padding-left: 4rem;
}
.entry-fullwidth .social-icons.social-icons-vertical {
  margin-bottom: 2rem;
}

.portfolio-container {
  position: relative;
  margin: 0 -1rem 4rem;
  transition: height 0.4s;
}
.portfolio-container::after {
  display: block;
  clear: both;
  content: "";
}

.portfolio-nogap {
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 1rem;
}
.portfolio-nogap .col {
  padding-left: 0;
  padding-right: 0;
}
.portfolio-nogap [class*=col-] {
  padding-left: 0;
  padding-right: 0;
}
.portfolio-nogap .portfolio-item {
  margin-bottom: 0;
}

.portfolio-item {
  float: left;
  margin-bottom: 2rem;
}
.portfolio-item:hover .portfolio-media > a:after {
  visibility: visible;
  opacity: 0.4;
}
.portfolio-item:hover .portfolio-content {
  background-color: #f6f6f6;
}

.portfolio {
  position: relative;
}

.portfolio-media {
  background-color: #ccc;
  margin: 0;
}
.portfolio-media > a {
  position: relative;
  display: block;
  overflow: hidden;
  outline: none !important;
}
.portfolio-media > a:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  background-color: #444444;
  visibility: hidden;
  opacity: 0;
  transition: all 0.45s ease;
}
.portfolio-media img {
  display: block;
  max-width: none;
  width: 100%;
  height: auto;
}

.portfolio-content {
  padding: 1.6rem 2rem;
  transition: all 0.45s ease;
}

.portfolio-overlay {
  overflow: hidden;
}
.portfolio-overlay .portfolio-content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 2rem 3rem;
  opacity: 0;
  z-index: 2;
  visibility: hidden;
  -webkit-backface-visibility: hidden;
}
.portfolio-overlay .portfolio-content.portfolio-content-center {
  bottom: auto;
  top: 50%;
  text-align: center;
  transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}
.portfolio-overlay .portfolio-title {
  color: #fff;
}
.portfolio-overlay .portfolio-title a:hover {
  color: #f1f1f1;
}
.portfolio-overlay .portfolio-title a:focus {
  color: #f1f1f1;
}
.portfolio-overlay .portfolio-tags a {
  color: #ccc;
}
.portfolio-overlay .portfolio-tags a:hover {
  color: #fff;
  box-shadow: 0 1px 0 #fff;
}
.portfolio-overlay .portfolio-tags a:focus {
  color: #fff;
  box-shadow: 0 1px 0 #fff;
}
.portfolio-overlay:hover .portfolio-content {
  visibility: visible;
  opacity: 1;
  background-color: transparent;
}

.about-images {
  position: relative;
  padding-top: 4rem;
}

.about-img-front {
  position: relative;
  z-index: 2;
  border: 2rem solid #f9f9f9;
}

.about-img-back {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}

.brands-text {
  max-width: 430px;
  margin-top: 0.5rem;
  margin-bottom: 3rem;
}
.brands-text .title {
  line-height: 1.5;
}

.brands-display {
  margin-top: 0.5rem;
}
.brands-display .brand {
  min-height: 0;
  margin-bottom: 4rem;
}
.brands-display [class*=col-] {
  display: flex;
}
.brands-display [class*=col-] .brand {
  width: 100%;
}

.about-testimonials blockquote {
  color: #666666;
  max-width: 850px;
  margin-left: auto;
  margin-right: auto;
  font-style: normal;
  line-height: 1.875;
}

.member {
  margin-bottom: 2rem;
  max-width: 376px;
  margin-left: auto;
  margin-right: auto;
}
.member p {
  max-width: 240px;
  color: #999;
}
.member:hover .member-content {
  opacity: 0;
}
.member:hover .member-overlay {
  visibility: visible;
  opacity: 1;
}
.member:hover.member-anim .member-overlay .member-title {
  animation-name: fadeInUpShort;
  animation-duration: 0.65s;
  animation-fill-mode: both;
}
.member:hover.member-anim .member-overlay p {
  animation-name: fadeInUpShort;
  animation-duration: 0.65s;
  animation-fill-mode: both;
  animation-delay: 0.1s;
}
.member:hover.member-anim .member-overlay .social-icons {
  animation-name: fadeInUpShort;
  animation-duration: 0.65s;
  animation-fill-mode: both;
  animation-delay: 0.2s;
}
.member:hover.member-anim .member-content .member-title {
  animation-name: fadeOutUpShort;
  animation-duration: 0.65s;
  animation-fill-mode: both;
}

.member.text-center p {
  margin-left: auto;
  margin-right: auto;
}
.member.text-center .social-icons {
  justify-content: center;
}

.member-media {
  position: relative;
  margin: 0;
}

.member-content {
  padding-top: 2.4rem;
  padding-bottom: 2.4rem;
  overflow: hidden;
  transition: all 0.45s ease;
}

.member-title {
  font-weight: 400;
  font-size: 1.6rem;
  letter-spacing: 0;
  margin-bottom: 0;
}
.member-title span {
  display: block;
  color: #999999;
  font-weight: 300;
  font-size: 1.4rem;
  margin-top: 0.3rem;
}

.member-overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(102, 102, 102, 0.7);
  color: #fff;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  transition: all 0.45s ease;
}
.member-overlay .member-title {
  color: inherit;
  margin-bottom: 1.5rem;
}
.member-overlay .member-title span {
  color: #ebebeb;
}
.member-overlay .social-icons {
  margin-top: 2.7rem;
}
.member-overlay .social-icon {
  font-size: 1.5rem;
}
.member-overlay .social-icon + .social-icon {
  margin-left: 2.8rem;
}
.member-overlay .social-icon:not(:hover) {
  color: inherit;
}

.member.member-2 {
  background-color: #fff;
  max-width: 277px;
  margin-left: auto;
  margin-right: auto;
}
.member.member-2 .member-content {
  visibility: visible;
  opacity: 1;
  padding-top: 1.7rem;
  padding-bottom: 2.7rem;
}
.member.member-2 .member-overlay {
  color: #333333;
  background-color: rgba(249, 249, 249, 0.7);
}
.member.member-2 .member-overlay .social-icons {
  margin-top: 0;
  margin-bottom: 0;
}

.direction-link {
  display: inline-block;
  margin-top: 1rem;
  line-height: 1;
}

.contact-info {
  margin-bottom: 3rem;
}
.contact-info h3 {
  margin-top: 0.5rem;
  font-weight: 400;
  font-size: 1.8rem;
  letter-spacing: -0.025em;
  margin-bottom: 1.5rem;
}

.store-media {
  margin-bottom: 0;
}

.store-title {
  font-weight: 400;
  font-size: 1.6rem;
  letter-spacing: 0;
  margin-bottom: 0.8rem;
  margin-top: -0.2rem;
}

.store-subtitle {
  font-weight: 400;
  font-size: 1.4rem;
  letter-spacing: 0;
  margin-bottom: 0.4rem;
  margin-top: 3.2rem;
}

.touch-container .lead {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.form-box {
  max-width: 575px;
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;
  padding: 2.2rem 2rem 4.4rem;
  box-shadow: 0 3px 16px rgba(51, 51, 51, 0.1);
}
.form-box .btn {
  min-width: 115px;
}

.form-choice {
  color: #333333;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.5;
  letter-spacing: -0.025em;
}
.form-choice p {
  margin-bottom: 2.7rem;
}
.form-choice .col-sm-6:not(:last-child) {
  margin-bottom: 0.6rem;
}

.btn.btn-login {
  color: #333333;
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.5;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0.85rem 1rem;
  border: 0.1rem solid #ebebeb;
  min-width: 0;
}
.btn.btn-login i {
  text-align: left;
  display: inline-block;
  margin-right: 0 !important;
  margin-left: 0 !important;
  min-width: 2rem;
}
.btn.btn-login:hover {
  background-color: #f5f6f9;
}
.btn.btn-login:focus {
  background-color: #f5f6f9;
}

.btn.btn-login.btn-g i {
  color: #cc3333;
  min-width: 2.6rem;
}

.btn.btn-login.btn-f i {
  color: #3366cc;
}

.categories-page .banner {
  overflow: hidden;
}
.categories-page .banner > a:not(.banner-link):after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: all 0.35s ease;
  background-color: rgba(25, 25, 25, 0.3);
  z-index: 1;
  visibility: hidden;
  opacity: 0;
}
.categories-page .banner:hover > a:not(.banner-link):after {
  visibility: visible;
  opacity: 1;
}
.categories-page .banner-badge .banner-link {
  padding-top: 1.45rem;
  padding-bottom: 1.45rem;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 5;
  bottom: 2rem;
}
.categories-page .banner-badge .banner-title {
  font-size: 1.6rem;
  margin-bottom: 0.5rem;
}
.categories-page .banner-badge .banner-subtitle {
  font-size: 1.4rem;
}

.sidebar-filter-overlay {
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(25, 25, 25, 0.25);
  z-index: 1000;
  transition: all 0.4s;
  visibility: hidden;
  opacity: 0;
}

.sidebar-filter-active .sidebar-filter-overlay {
  visibility: visible;
  opacity: 1;
}
.sidebar-filter-active .sidebar-filter {
  visibility: visible;
  transform: translateX(350px);
}

.sidebar-filter {
  position: fixed;
  left: -350px;
  top: 0;
  bottom: 0;
  z-index: 1001;
  background-color: #fff;
  width: 100%;
  max-width: 350px;
  overflow-y: scroll;
  box-shadow: 0.1rem 0 0.6rem 0 rgba(51, 51, 51, 0.5);
  will-change: transform;
  visibility: hidden;
  transition: all 0.35s;
}
.sidebar-filter .widget {
  padding-left: 1rem;
  padding-right: 1rem;
}
.sidebar-filter .widget:last-child {
  border-bottom: none;
}
.sidebar-filter .widget-clean {
  color: #333333;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.5;
  letter-spacing: -0.01em;
  border-bottom: 0.1rem solid #ebebeb;
  padding: 0 1rem 2.4rem;
  margin-bottom: 2rem;
}
.sidebar-filter .widget-clean i {
  font-size: 1.6rem;
  line-height: 1;
  margin-right: 1rem;
}
.sidebar-filter .widget-clean a {
  font-size: 1.4rem;
}

.sidebar-filter-banner.sidebar-shop .widget:not(.widget-clean) {
  border-bottom: none;
  margin-bottom: 4rem;
}
.sidebar-filter-banner.sidebar-shop .widget-title {
  margin-bottom: 1.7rem;
}

.sidebar-filter-wrapper {
  position: relative;
  padding: 3.5rem 2.7rem 1.5rem;
}

.sidebar-filter-clear {
  text-transform: capitalize;
  margin-left: auto;
}

.checkout label {
  margin-bottom: 0.2rem;
}
.checkout .form-control {
  margin-bottom: 1.3rem;
}
.checkout .form-control + .form-control {
  margin-top: 2rem;
}
.checkout .form-control:not(:focus) {
  background-color: #f9f9f9;
}
.checkout textarea.form-control {
  min-height: 150px;
}
.checkout .custom-control {
  margin-top: 2.5rem;
}
.checkout .custom-control + .custom-control {
  margin-top: 1.8rem;
}
.checkout .custom-control + label {
  margin-top: 1rem;
}
.checkout .custom-control .custom-control-label {
  font-weight: 400;
  color: #333;
}

.checkout-title {
  font-weight: 500;
  font-size: 1.6rem;
  letter-spacing: 0;
  margin-top: 2.2rem;
  margin-bottom: 1.8rem;
}

.summary {
  padding: 2.5rem 3rem 3rem;
  border: 0.1rem dashed #d7d7d7;
  background-color: #f9f9f9;
  border-radius: 0.3rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.summary-title {
  font-weight: 500;
  font-size: 1.6rem;
  letter-spacing: 0;
  padding-bottom: 1.7rem;
  border-bottom: 0.1rem solid #cccccc;
  margin-bottom: 2.1rem;
}

.in-stock {
  color: #a6c76c;
}

.out-of-stock {
  color: #ef837b;
}

.btn-remove {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  color: #cccccc;
  background-color: transparent;
  font-size: 1.7rem;
  line-height: 1;
  border: none;
  outline: none;
  transition: all 0.35s;
}
.btn-remove:hover {
  color: #333333;
}
.btn-remove:focus {
  color: #333333;
}

.action-col .dropdown-menu {
  color: #666;
  border-radius: 0;
  width: 100%;
  font-size: 1.3rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.action-col .dropdown-item {
  padding: 0.5rem 1.5rem;
  transition: background 0.35s ease, color 0.1s ease;
}
.action-col .dropdown-item:active {
  color: #fff;
  background-color: #c96;
}
.action-col .dropdown-item:hover {
  color: #fff;
  background-color: #c96;
}
.action-col .dropdown-item:focus {
  color: #fff;
  background-color: #c96;
}
.action-col .dropdown-item.active {
  color: #fff;
  background-color: #c96;
}

.table.table-cart .price-col {
  width: 120px;
}
.table.table-cart .total-col {
  width: 80px;
}
.table.table-cart .quantity-col {
  width: 135px;
}

.cart-product-quantity {
  width: 100px;
}
.cart-product-quantity .form-control {
  padding: 0.85rem 2.6rem;
  height: 40px;
  max-width: 100%;
  border-color: #dadada;
  background-color: #fff;
  margin: 0;
}

.input-spinner {
  position: relative;
}
.input-spinner .input-group-prepend {
  position: absolute;
  top: 0;
  left: 0;
}
.input-spinner .input-group-append {
  position: absolute;
  top: 0;
  right: 0;
}
.input-spinner .form-control {
  padding: 0.85rem 2.6rem;
  height: auto;
  border-color: #dadada;
  background-color: #fff;
  margin: 0;
}
.input-spinner .form-control[disabled] {
  background-color: #f9f9f9;
}

.cart-bottom {
  display: flex;
  align-items: flex-start;
  margin-bottom: 5rem;
}
.cart-bottom .btn {
  margin-left: auto;
  min-width: 152px;
}

.cart-discount {
  flex: 0 0 50%;
  max-width: 326px;
}
.cart-discount .form-control {
  margin-right: 1.1rem;
}
.cart-discount .btn {
  min-width: 40px;
  padding-left: 0;
  padding-right: 0;
}

.summary.summary-cart {
  margin-bottom: 3rem;
}
.summary.summary-cart .summary-title {
  margin-bottom: 0;
}

.summary-cart .table.table-summary thead th:last-child {
  min-width: 0;
}
.summary-cart .table.table-summary tbody td:last-child {
  min-width: 0;
}

.error-content {
  color: #777;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 1.5;
  letter-spacing: -0.025em;
  padding-top: 8.5rem;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  min-height: 480px;
  background-color: #fdfdfd;
}
.error-content p {
  margin-bottom: 2.5rem;
}

.error-title {
  letter-spacing: -0.025em;
  margin-bottom: 1.4rem;
}

.mt-0 {
  margin-top: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.mt-1 {
  margin-top: 1rem !important;
}

.mb-1 {
  margin-bottom: 1rem !important;
}

.pt-1 {
  padding-top: 1rem !important;
}

.pb-1 {
  padding-bottom: 1rem !important;
}

.mt-2 {
  margin-top: 2rem !important;
}

.mb-2 {
  margin-bottom: 2rem !important;
}

.pt-2 {
  padding-top: 2rem !important;
}

.pb-2 {
  padding-bottom: 2rem !important;
}

.mt-3 {
  margin-top: 3rem !important;
}

.mb-3 {
  margin-bottom: 3rem !important;
}

.pt-3 {
  padding-top: 3rem !important;
}

.pb-3 {
  padding-bottom: 3rem !important;
}

.mt-4 {
  margin-top: 4rem !important;
}

.mb-4 {
  margin-bottom: 4rem !important;
}

.pt-4 {
  padding-top: 4rem !important;
}

.pb-4 {
  padding-bottom: 4rem !important;
}

.mt-5 {
  margin-top: 5rem !important;
}

.mb-5 {
  margin-bottom: 5rem !important;
}

.pt-5 {
  padding-top: 5rem !important;
}

.pb-5 {
  padding-bottom: 5rem !important;
}

.mt-6 {
  margin-top: 6rem !important;
}

.mb-6 {
  margin-bottom: 6rem !important;
}

.pt-6 {
  padding-top: 6rem !important;
}

.pb-6 {
  padding-bottom: 6rem !important;
}

.mt-7 {
  margin-top: 7rem !important;
}

.mb-7 {
  margin-bottom: 7rem !important;
}

.pt-7 {
  padding-top: 7rem !important;
}

.pb-7 {
  padding-bottom: 7rem !important;
}

.mt-8 {
  margin-top: 8rem !important;
}

.mb-8 {
  margin-bottom: 8rem !important;
}

.pt-8 {
  padding-top: 8rem !important;
}

.pb-8 {
  padding-bottom: 8rem !important;
}

.mt-9 {
  margin-top: 9rem !important;
}

.mb-9 {
  margin-bottom: 9rem !important;
}

.pt-9 {
  padding-top: 9rem !important;
}

.pb-9 {
  padding-bottom: 9rem !important;
}

.mt-10 {
  margin-top: 10rem !important;
}

.mb-10 {
  margin-bottom: 10rem !important;
}

.pt-10 {
  padding-top: 10rem !important;
}

.pb-10 {
  padding-bottom: 10rem !important;
}

.mt-11 {
  margin-top: 11rem !important;
}

.mb-11 {
  margin-bottom: 11rem !important;
}

.pt-11 {
  padding-top: 11rem !important;
}

.pb-11 {
  padding-bottom: 11rem !important;
}

.mt-12 {
  margin-top: 12rem !important;
}

.mb-12 {
  margin-bottom: 12rem !important;
}

.pt-12 {
  padding-top: 12rem !important;
}

.pb-12 {
  padding-bottom: 12rem !important;
}

.mt-13 {
  margin-top: 13rem !important;
}

.mb-13 {
  margin-bottom: 13rem !important;
}

.pt-13 {
  padding-top: 13rem !important;
}

.pb-13 {
  padding-bottom: 13rem !important;
}

.mt-14 {
  margin-top: 14rem !important;
}

.mb-14 {
  margin-bottom: 14rem !important;
}

.pt-14 {
  padding-top: 14rem !important;
}

.pb-14 {
  padding-bottom: 14rem !important;
}

.mt-15 {
  margin-top: 15rem !important;
}

.mb-15 {
  margin-bottom: 15rem !important;
}

.pt-15 {
  padding-top: 15rem !important;
}

.pb-15 {
  padding-bottom: 15rem !important;
}

.section.content {
  padding: 60px 0;
  min-height: 600px;
}

.homeTimeline h1 {
  text-align: center;
}
.homeTimeline .time {
  width: 100%;
}
.homeTimeline .time:before {
  content: "";
  height: 1px;
  width: 100%;
  background-color: #d2d2d2;
  position: absolute;
  top: 50%;
  left: 0;
}
.homeTimeline .time .slick-prev {
  top: 43% !important;
  bottom: 0 !important;
  border: 1px solid #231f20;
  line-height: 56px;
  left: -80px;
}
.homeTimeline .time .slick-prev:hover {
  background-color: #231f20;
}
.homeTimeline .time .slick-next {
  top: 43% !important;
  bottom: 0px !important;
  border: 1px solid #231f20;
  line-height: 56px;
  left: 102%;
}
.homeTimeline .timeSection {
  display: inline-block;
  list-style: none;
  position: relative;
  top: -7px;
  width: 160px;
}
.homeTimeline .timeSection .head {
  color: #231f20;
  font-size: 30px;
  font-weight: 700;
  text-align: left;
  font-family: "garamond", sans-serif;
  display: block;
  margin-top: 5px;
}
.homeTimeline .timeSection .txt {
  color: #4d4d4d;
  font-size: 14px;
  margin-bottom: 10px;
  text-align: left;
  height: 42px;
  overflow: hidden;
}
.homeTimeline .timeSection .line {
  width: 1px;
  height: 70px;
  background-color: #d2d2d2;
  margin-left: 10px;
}
.homeTimeline .timeSection .ping {
  width: 7px;
  height: 7px;
  background-color: #ffe9bd;
  border: 7px solid #3c3537;
  border-radius: 50%;
  box-sizing: initial;
  display: inline-block;
  float: left;
}
.homeTimeline .t190 {
  top: 167px !important;
}

.homeProduct {
  position: relative;
}
.homeProduct h1 {
  margin-bottom: 15px;
  text-align: center;
}
.homeProduct .txt {
  line-height: 30px;
  margin-bottom: 15px;
  text-align: center;
}
.homeProduct .plist {
  display: block;
  width: 100%;
  padding: 0 15px;
  overflow: hidden;
  margin-top: 15px;
}
.homeProduct .plist .list_wrap {
  width: 33%;
  height: 520px;
  display: inline-block;
  background-color: #231f20;
  position: relative;
  overflow: hidden;
  margin-right: -2px;
  margin-left: -2px;
}
.homeProduct .plist .list_wrap:last-child {
  margin-right: 0;
  margin-left: -2px;
}
.homeProduct .plist .list_wrap .img {
  width: 280px;
  height: 520px;
  position: absolute;
  z-index: 0;
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
  max-width: none;
  width: calc(100% + 50px);
  opacity: 0.2;
  transition: opacity 0.35s, transform 0.35s;
  transform: translate3d(-40px, 0, 0);
}
.homeProduct .plist .list_wrap .phead {
  position: relative;
  z-index: 1;
  color: #FFF;
  font-size: 25px;
  font-family: "garamond", sans-serif;
  bottom: -160px;
  left: -180px;
  height: 100px;
  width: 520px;
  -webkit-font-smoothing: antialiased;
  transform: rotate(-90deg);
}
.homeProduct .plist .list_wrap .more {
  position: absolute;
  bottom: 30px;
  left: 35px;
  color: #FFF;
  font-size: 14px;
  opacity: 0;
}
.homeProduct .plist .list_wrap .more i {
  margin-left: 15px;
}
.homeProduct .plist .list_wrap:hover .img {
  transform: translate3d(0, 0, 0);
  opacity: 0.8;
}
.homeProduct .plist .list_wrap:hover .phead {
  bottom: -120px;
}
.homeProduct .plist .list_wrap:hover .more {
  opacity: 1;
}

a.play-btn {
  border: 2px solid #aaa;
  border-radius: 50%;
  color: #aaa;
  display: inline-block;
  height: 70px;
  line-height: 70px;
  position: relative;
  width: 70px;
  margin-top: 150px;
}

@media screen and (min-width: 768px) {
  h1 {
    font-size: 5rem;
  }
  .h2 {
    font-size: 4rem;
  }
  h3 {
    font-size: 3rem;
  }
  .h4 {
    font-size: 2.4rem;
  }
  #scroll-top {
    display: flex;
  }
  .title {
    font-size: 2.4rem;
  }
  .title-lg {
    font-size: 3rem;
  }
  .icon-box {
    margin-bottom: 4rem;
  }
  .icon-box-side {
    margin-bottom: 0;
  }
  .just-action-icons-md .btn-product span {
    display: none;
  }
  .just-action-icons-md .btn-product:before {
    margin-right: 0;
  }
  .banner-content {
    left: 3rem;
  }
  .banner-big .banner-content {
    left: 6rem;
  }
  .banner-big .banner-title {
    font-size: 4rem;
  }
  .banner-display .banner-title {
    font-size: 2.4rem;
  }
  .video-banner-bg {
    padding-top: 14.5rem;
    padding-bottom: 13.5rem;
  }
  .video-banner-title > span {
    font-size: 2.4rem;
  }
  .video-box-content .video-banner-title span {
    font-size: 2rem;
  }
  .btn-video {
    width: 7rem;
    height: 7rem;
    font-size: 1.8rem;
  }
  .intro-content {
    left: 70px;
  }
  .intro-content .btn {
    font-size: 1.3rem;
    min-width: 130px;
    padding: 0.95rem 1.5rem;
  }
  .intro-subtitle {
    font-size: 1.8rem;
  }
  .intro-title {
    font-size: 4.2rem;
    margin-bottom: 1.7rem;
  }
  .toolbox {
    flex-direction: row;
  }
  .toolbox-center {
    margin-top: 0;
  }
  .toolbox-right {
    margin-top: 0;
    justify-content: flex-end;
  }
  .product-details-separator [class*=col-] {
    padding-top: 1rem;
  }
  .product-details-separator [class*=col-] + [class*=col-]:before {
    content: "";
    width: 0.1rem;
    display: block;
    height: 100%;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    background-color: #ebebeb;
  }
  .entry-title {
    font-size: 2.4rem;
  }
  .entry-list {
    margin-bottom: 5rem;
  }
  .entry-list .col-md-5 {
    flex: 0 0 45%;
    max-width: 45%;
  }
  .entry-list .entry-media {
    max-width: 376px;
    margin-bottom: 0;
  }
  .entry-list .col-md-7 {
    flex: 0 0 55%;
    max-width: 55%;
  }
  .single-entry .entry-title-big {
    font-size: 3.4rem;
  }
  .editor-content::after {
    display: block;
    clear: both;
    content: "";
  }
  .editor-content .float-left {
    margin-right: 4.8rem;
    margin-bottom: 2rem;
  }
  .editor-content .float-right {
    margin-left: 4.8rem;
    margin-bottom: 2rem;
  }
  .editor-content h3 {
    font-size: 2.4rem;
  }
  .editor-content blockquote {
    padding: 4rem 6rem 4rem 12rem;
  }
  .editor-content blockquote:before {
    left: 4rem;
    top: 50%;
  }
  .error-content {
    padding-top: 12.5rem;
    min-height: 540px;
  }
  .soon-content {
    padding-right: 3rem;
  }
  .soon-bg {
    display: block;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 1rem !important;
  }
  .mt-md-2 {
    margin-top: 2rem !important;
  }
  .mt-md-3 {
    margin-top: 3rem !important;
  }
  .mt-md-4 {
    margin-top: 4rem !important;
  }
  .mt-md-5 {
    margin-top: 5rem !important;
  }
  .mt-md-6 {
    margin-top: 6rem !important;
  }
  .mt-md-7 {
    margin-top: 7rem !important;
  }
  .mt-md-8 {
    margin-top: 8rem !important;
  }
  .mt-md-9 {
    margin-top: 9rem !important;
  }
  .mt-md-10 {
    margin-top: 10rem !important;
  }
  .mt-md-11 {
    margin-top: 11rem !important;
  }
  .mt-md-12 {
    margin-top: 12rem !important;
  }
  .mt-md-13 {
    margin-top: 13rem !important;
  }
  .mt-md-14 {
    margin-top: 14rem !important;
  }
  .mt-md-15 {
    margin-top: 15rem !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 1rem !important;
  }
  .mb-md-2 {
    margin-bottom: 2rem !important;
  }
  .mb-md-3 {
    margin-bottom: 3rem !important;
  }
  .mb-md-4 {
    margin-bottom: 4rem !important;
  }
  .mb-md-5 {
    margin-bottom: 5rem !important;
  }
  .mb-md-6 {
    margin-bottom: 6rem !important;
  }
  .mb-md-7 {
    margin-bottom: 7rem !important;
  }
  .mb-md-8 {
    margin-bottom: 8rem !important;
  }
  .mb-md-9 {
    margin-bottom: 9rem !important;
  }
  .mb-md-10 {
    margin-bottom: 10rem !important;
  }
  .mb-md-11 {
    margin-bottom: 11rem !important;
  }
  .mb-md-12 {
    margin-bottom: 12rem !important;
  }
  .mb-md-13 {
    margin-bottom: 13rem !important;
  }
  .mb-md-14 {
    margin-bottom: 14rem !important;
  }
  .mb-md-15 {
    margin-bottom: 15rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 1rem !important;
  }
  .pt-md-2 {
    padding-top: 2rem !important;
  }
  .pt-md-3 {
    padding-top: 3rem !important;
  }
  .pt-md-4 {
    padding-top: 4rem !important;
  }
  .pt-md-5 {
    padding-top: 5rem !important;
  }
  .pt-md-6 {
    padding-top: 6rem !important;
  }
  .pt-md-7 {
    padding-top: 7rem !important;
  }
  .pt-md-8 {
    padding-top: 8rem !important;
  }
  .pt-md-9 {
    padding-top: 9rem !important;
  }
  .pt-md-10 {
    padding-top: 10rem !important;
  }
  .pt-md-11 {
    padding-top: 11rem !important;
  }
  .pt-md-12 {
    padding-top: 12rem !important;
  }
  .pt-md-13 {
    padding-top: 13rem !important;
  }
  .pt-md-14 {
    padding-top: 14rem !important;
  }
  .pt-md-15 {
    padding-top: 15rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 1rem !important;
  }
  .pb-md-2 {
    padding-bottom: 2rem !important;
  }
  .pb-md-3 {
    padding-bottom: 3rem !important;
  }
  .pb-md-4 {
    padding-bottom: 4rem !important;
  }
  .pb-md-5 {
    padding-bottom: 5rem !important;
  }
  .pb-md-6 {
    padding-bottom: 6rem !important;
  }
  .pb-md-7 {
    padding-bottom: 7rem !important;
  }
  .pb-md-8 {
    padding-bottom: 8rem !important;
  }
  .pb-md-9 {
    padding-bottom: 9rem !important;
  }
  .pb-md-10 {
    padding-bottom: 10rem !important;
  }
  .pb-md-11 {
    padding-bottom: 11rem !important;
  }
  .pb-md-12 {
    padding-bottom: 12rem !important;
  }
  .pb-md-13 {
    padding-bottom: 13rem !important;
  }
  .pb-md-14 {
    padding-bottom: 14rem !important;
  }
  .pb-md-15 {
    padding-bottom: 15rem !important;
  }
}
@media all and (-ms-high-contrast: none) {
  .page-wrapper {
    display: block;
  }
}
@media (-ms-high-contrast: active) {
  .page-wrapper {
    display: block;
  }
}
@media screen and (min-width: 992px) {
  .col-5col {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .heading.heading-flex {
    align-items: center;
    flex-direction: row;
    text-align: left;
  }
  .heading-right {
    margin-top: 0;
    margin-left: auto;
  }
  .card-title {
    font-size: 2rem;
  }
  .just-action-icons-lg .btn-product span {
    display: none;
  }
  .just-action-icons-lg .btn-product:before {
    margin-right: 0;
  }
  .banner-content {
    left: 4rem;
  }
  .banner-big .banner-content {
    left: 10rem;
  }
  .banner-big .banner-title {
    font-size: 5rem;
  }
  .video-banner-bg {
    padding-top: 6rem;
    padding-bottom: 11rem;
  }
  .video-box-content {
    padding-left: 5rem;
  }
  .video-fullheight {
    height: 100vh;
    max-height: 900px;
  }
  .cta-border {
    text-align: left;
    padding-left: 0;
    padding-right: 0;
  }
  .cta-border .cta-content {
    flex-direction: row;
    padding-left: 1.5rem;
  }
  .cta-border .cta-heading {
    flex: 0 0 24.7%;
    max-width: 24.7%;
    padding-right: 3rem;
  }
  .cta-border .cta-title {
    margin-bottom: 0;
  }
  .cta-border .cta-text {
    margin-right: 2rem;
    padding-left: 3rem;
    margin-bottom: 0;
  }
  .cta-border .cta-text::before {
    display: block;
  }
  .cta-display .cta-title {
    font-size: 3rem;
  }
  .banners-sm .banner-link-anim {
    padding-left: 0;
    padding-right: 0;
  }
  .banners-sm .row {
    margin-left: 0;
    margin-right: 0;
  }
  .container-fluid .product-details-top .col-lg-7 {
    flex: 0 0 56.5%;
    max-width: 56.5%;
  }
  .container-fluid .product-details-top .col-lg-5 {
    flex: 0 0 43.5%;
    max-width: 43.5%;
  }
  .product-gallery-vertical .row {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
    flex-direction: row-reverse;
  }
  .product-gallery-vertical .btn-product-gallery {
    right: 2.5rem;
  }
  .product-gallery-vertical .product-image-gallery {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    width: auto;
    flex: 0 0 20%;
    max-width: 20%;
    margin-left: 0;
    margin-right: 0;
  }
  .product-gallery-vertical .product-main-image {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    flex: 0 0 80%;
    max-width: 80%;
  }
  .product-gallery-vertical .product-gallery-item {
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 1rem;
  }
  .product-gallery-vertical .product-gallery-item:before {
    left: 0;
    right: 0;
  }
  .product-gallery-vertical .product-gallery-item:after {
    left: 0;
    right: 0;
  }
  .sticky-bar {
    display: block;
    width: 100%;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 500;
    padding-top: 1rem;
    padding-bottom: 1rem;
    background-color: #fff;
    box-shadow: -15px 0 43px rgba(51, 51, 51, 0.15);
  }
  .sticky-bar .row {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .sticky-bar .col-6 {
    display: flex;
    align-items: center;
  }
  .sticky-bar .product-media {
    max-width: 60px;
    margin-right: 2rem;
    margin-bottom: 0;
    flex-shrink: 0;
  }
  .sticky-bar .product-title {
    margin-bottom: 0;
    letter-spacing: -0.025em;
  }
  .sticky-bar .product-price {
    margin-bottom: 0;
    margin-right: 2rem;
  }
  .sticky-bar .product-details-quantity {
    margin-right: 2rem;
    max-width: 100px;
  }
  .sticky-bar .product-details-action {
    margin-bottom: 0;
  }
  .sticky-bar .btn-cart {
    min-width: 200px;
    margin-right: 2rem;
  }
  .sticky-bar .btn-product + .btn-product {
    border: none;
  }
  .sticky-bar .btn-wishlist {
    font-size: 1.8rem;
    margin-right: 2rem;
  }
  .sticky-bar .btn-wishlist span {
    display: none;
  }
  .sticky-bar .btn-wishlist:before {
    margin-right: 0;
  }
  .sidebar {
    margin-top: -0.3rem;
  }
  .entry {
    margin-bottom: 6rem;
  }
  .entry-grid {
    margin-bottom: 4rem;
  }
  .max-col-2 .entry-grid .entry-media {
    margin-bottom: 2.4rem;
  }
  .max-col-2 .entry-grid .entry-title {
    font-size: 2.4rem;
  }
  .max-col-2 .entry-grid .entry-cats {
    margin-bottom: 1.5rem;
  }
  .max-col-4 .entry-grid .entry-video a:after {
    width: 5rem;
    height: 5rem;
    margin-left: -2.5rem;
    margin-top: -2.5rem;
  }
  .max-col-4 .entry-grid .entry-video a:hover:after {
    box-shadow: 0 0 0 1rem rgba(255, 255, 255, 0.2);
  }
  .max-col-4 .entry-grid .entry-video a:focus:after {
    box-shadow: 0 0 0 1rem rgba(255, 255, 255, 0.2);
  }
  .entry-list {
    margin-bottom: 7rem;
  }
  .entry-list:last-of-type {
    margin-bottom: 6rem;
  }
  .entry-list .entry-body {
    padding-left: 1.5rem;
  }
  .entry-mask .entry-title {
    font-size: 2.4rem;
  }
  .single-entry .entry-title-big {
    font-size: 4rem;
  }
  .social-icons.social-icons-vertical {
    flex-direction: column;
    text-align: center;
    padding-bottom: 4rem;
  }
  .social-icons.social-icons-vertical .social-label {
    margin-right: 0;
    margin-bottom: 1.1rem;
  }
  .social-icons.social-icons-vertical .social-icon {
    margin-bottom: 1rem;
    margin-right: 0;
  }
  .social-icons.social-icons-vertical .social-icon + .social-icon {
    margin-left: 0;
  }
  .entry-fullwidth .social-icons.social-icons-vertical {
    margin-bottom: 0;
  }
  .mt-lg-0 {
    margin-top: 0rem !important;
  }
  .mt-lg-1 {
    margin-top: 1rem !important;
  }
  .mt-lg-2 {
    margin-top: 2rem !important;
  }
  .mt-lg-3 {
    margin-top: 3rem !important;
  }
  .mt-lg-4 {
    margin-top: 4rem !important;
  }
  .mt-lg-5 {
    margin-top: 5rem !important;
  }
  .mt-lg-6 {
    margin-top: 6rem !important;
  }
  .mt-lg-7 {
    margin-top: 7rem !important;
  }
  .mt-lg-8 {
    margin-top: 8rem !important;
  }
  .mt-lg-9 {
    margin-top: 9rem !important;
  }
  .mt-lg-10 {
    margin-top: 10rem !important;
  }
  .mt-lg-11 {
    margin-top: 11rem !important;
  }
  .mt-lg-12 {
    margin-top: 12rem !important;
  }
  .mt-lg-13 {
    margin-top: 13rem !important;
  }
  .mt-lg-14 {
    margin-top: 14rem !important;
  }
  .mt-lg-15 {
    margin-top: 15rem !important;
  }
  .mb-lg-0 {
    margin-bottom: 0rem !important;
  }
  .mb-lg-1 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 2rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 4rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 5rem !important;
  }
  .mb-lg-6 {
    margin-bottom: 6rem !important;
  }
  .mb-lg-7 {
    margin-bottom: 7rem !important;
  }
  .mb-lg-8 {
    margin-bottom: 8rem !important;
  }
  .mb-lg-9 {
    margin-bottom: 9rem !important;
  }
  .mb-lg-10 {
    margin-bottom: 10rem !important;
  }
  .mb-lg-11 {
    margin-bottom: 11rem !important;
  }
  .mb-lg-12 {
    margin-bottom: 12rem !important;
  }
  .mb-lg-13 {
    margin-bottom: 13rem !important;
  }
  .mb-lg-14 {
    margin-bottom: 14rem !important;
  }
  .mb-lg-15 {
    margin-bottom: 15rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 1rem !important;
  }
  .pt-lg-2 {
    padding-top: 2rem !important;
  }
  .pt-lg-3 {
    padding-top: 3rem !important;
  }
  .pt-lg-4 {
    padding-top: 4rem !important;
  }
  .pt-lg-5 {
    padding-top: 5rem !important;
  }
  .pt-lg-6 {
    padding-top: 6rem !important;
  }
  .pt-lg-7 {
    padding-top: 7rem !important;
  }
  .pt-lg-8 {
    padding-top: 8rem !important;
  }
  .pt-lg-9 {
    padding-top: 9rem !important;
  }
  .pt-lg-10 {
    padding-top: 10rem !important;
  }
  .pt-lg-11 {
    padding-top: 11rem !important;
  }
  .pt-lg-12 {
    padding-top: 12rem !important;
  }
  .pt-lg-13 {
    padding-top: 13rem !important;
  }
  .pt-lg-14 {
    padding-top: 14rem !important;
  }
  .pt-lg-15 {
    padding-top: 15rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 2rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 3rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 4rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 5rem !important;
  }
  .pb-lg-6 {
    padding-bottom: 6rem !important;
  }
  .pb-lg-7 {
    padding-bottom: 7rem !important;
  }
  .pb-lg-8 {
    padding-bottom: 8rem !important;
  }
  .pb-lg-9 {
    padding-bottom: 9rem !important;
  }
  .pb-lg-10 {
    padding-bottom: 10rem !important;
  }
  .pb-lg-11 {
    padding-bottom: 11rem !important;
  }
  .pb-lg-12 {
    padding-bottom: 12rem !important;
  }
  .pb-lg-13 {
    padding-bottom: 13rem !important;
  }
  .pb-lg-14 {
    padding-bottom: 14rem !important;
  }
  .pb-lg-15 {
    padding-bottom: 15rem !important;
  }
}
@media screen and (min-width: 1200px) {
  .container-fluid {
    padding-left: 30px;
    padding-right: 30px;
  }
  .col-xl-5col {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .col-xl-4-5col {
    flex: 0 0 80%;
    max-width: 80%;
  }
  .col-xl-2-5col {
    flex: 0 0 40%;
    max-width: 40%;
  }
  .col-xl-3-5col {
    flex: 0 0 60%;
    max-width: 60%;
  }
  .no-gutters .col-xl-2-5col {
    flex: 0 0 calc(40% + 8px);
    max-width: calc(40% + 8px);
  }
  .no-gutters .col-xl-3-5col {
    flex: 0 0 calc(60% - 8px);
    max-width: calc(60% - 8px);
  }
  .just-action-icons-xl .btn-product span {
    display: none;
  }
  .just-action-icons-xl .btn-product:before {
    margin-right: 0;
  }
  .quickView-content > .row .product-fullscreen {
    flex: 0 0 calc(50% + 2rem);
    max-width: calc(50% + 2rem);
    padding-left: 0;
    padding-right: 0;
  }
  .intro-content {
    left: 100px;
  }
  .intro-content .btn {
    font-size: 1.4rem;
    min-width: 150px;
    padding-top: 1.15rem;
    padding-bottom: 1.15rem;
  }
  .intro-subtitle {
    font-size: 2rem;
  }
  .intro-title {
    font-size: 5.2rem;
  }
  .entry-fullwidth .entry-body {
    padding-left: 2rem;
  }
  .soon-content {
    padding-right: 0;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 1rem !important;
  }
  .mt-xl-2 {
    margin-top: 2rem !important;
  }
  .mt-xl-3 {
    margin-top: 3rem !important;
  }
  .mt-xl-4 {
    margin-top: 4rem !important;
  }
  .mt-xl-5 {
    margin-top: 5rem !important;
  }
  .mt-xl-6 {
    margin-top: 6rem !important;
  }
  .mt-xl-7 {
    margin-top: 7rem !important;
  }
  .mt-xl-8 {
    margin-top: 8rem !important;
  }
  .mt-xl-9 {
    margin-top: 9rem !important;
  }
  .mt-xl-10 {
    margin-top: 10rem !important;
  }
  .mt-xl-11 {
    margin-top: 11rem !important;
  }
  .mt-xl-12 {
    margin-top: 12rem !important;
  }
  .mt-xl-13 {
    margin-top: 13rem !important;
  }
  .mt-xl-14 {
    margin-top: 14rem !important;
  }
  .mt-xl-15 {
    margin-top: 15rem !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 2rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 4rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 5rem !important;
  }
  .mb-xl-6 {
    margin-bottom: 6rem !important;
  }
  .mb-xl-7 {
    margin-bottom: 7rem !important;
  }
  .mb-xl-8 {
    margin-bottom: 8rem !important;
  }
  .mb-xl-9 {
    margin-bottom: 9rem !important;
  }
  .mb-xl-10 {
    margin-bottom: 10rem !important;
  }
  .mb-xl-11 {
    margin-bottom: 11rem !important;
  }
  .mb-xl-12 {
    margin-bottom: 12rem !important;
  }
  .mb-xl-13 {
    margin-bottom: 13rem !important;
  }
  .mb-xl-14 {
    margin-bottom: 14rem !important;
  }
  .mb-xl-15 {
    margin-bottom: 15rem !important;
  }
}
@media screen and (min-width: 1280px) {
  .container {
    max-width: 1200px;
  }
}
@media screen and (min-width: 1600px) {
  .container-fluid {
    padding-left: 70px;
    padding-right: 70px;
  }
  .col-xxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xxl-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xxl-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .offset-xxl-2 {
    margin-left: 16.666667%;
  }
  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xxl-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xxl-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xxl-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .col-xxl-5col {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .col-xxl-4-5col {
    flex: 0 0 80%;
    max-width: 80%;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .mt-xxl-0 {
    margin-top: 0rem !important;
  }
  .mt-xxl-1 {
    margin-top: 1rem !important;
  }
  .mt-xxl-2 {
    margin-top: 2rem !important;
  }
  .mt-xxl-3 {
    margin-top: 3rem !important;
  }
  .mt-xxl-4 {
    margin-top: 4rem !important;
  }
  .mt-xxl-5 {
    margin-top: 5rem !important;
  }
  .mt-xxl-6 {
    margin-top: 6rem !important;
  }
  .mt-xxl-7 {
    margin-top: 7rem !important;
  }
  .mt-xxl-8 {
    margin-top: 8rem !important;
  }
  .mt-xxl-9 {
    margin-top: 9rem !important;
  }
  .mt-xxl-10 {
    margin-top: 10rem !important;
  }
  .mt-xxl-11 {
    margin-top: 11rem !important;
  }
  .mt-xxl-12 {
    margin-top: 12rem !important;
  }
  .mt-xxl-13 {
    margin-top: 13rem !important;
  }
  .mt-xxl-14 {
    margin-top: 14rem !important;
  }
  .mt-xxl-15 {
    margin-top: 15rem !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 2rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 4rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 5rem !important;
  }
  .mb-xxl-6 {
    margin-bottom: 6rem !important;
  }
  .mb-xxl-7 {
    margin-bottom: 7rem !important;
  }
  .mb-xxl-8 {
    margin-bottom: 8rem !important;
  }
  .mb-xxl-9 {
    margin-bottom: 9rem !important;
  }
  .mb-xxl-10 {
    margin-bottom: 10rem !important;
  }
  .mb-xxl-11 {
    margin-bottom: 11rem !important;
  }
  .mb-xxl-12 {
    margin-bottom: 12rem !important;
  }
  .mb-xxl-13 {
    margin-bottom: 13rem !important;
  }
  .mb-xxl-14 {
    margin-bottom: 14rem !important;
  }
  .mb-xxl-15 {
    margin-bottom: 15rem !important;
  }
}
@media screen and (min-width: 1920px) {
  .container-fluid {
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (max-width: 1199px) {
  .icon-box-sm br {
    display: none;
  }
  .cta-horizontal-box {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}
@media screen and (max-width: 991px) {
  .cta-horizontal .cta-desc {
    margin-bottom: 1.5rem;
  }
  .cta-separator [class*=col-] + [class*=col-]:before {
    height: 0.1rem;
    width: 80%;
    left: 10%;
    top: 0;
    bottom: auto;
  }
  .cta-border .cta-title {
    text-align: center !important;
  }
  .cta-border .cta-title.text-right {
    text-align: center !important;
  }
  .newsletter-img {
    display: none;
  }
  .newsletter-popup-container .banner-content {
    padding-bottom: 2rem;
  }
  .sticky-content.is_stuck {
    position: static !important;
    top: auto !important;
    width: auto !important;
    bottom: auto !important;
  }
  .product-col .product {
    padding-right: 0;
  }
}
@media screen and (max-width: 767px) {
  .cta-horizontal .form-control {
    margin-right: 1rem;
  }
  .cta .btn:not(.btn-block) {
    min-width: 0;
  }
  .cta-wrapper {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .product-desc-row {
    min-height: 380px;
  }
  .product-desc-content h2 {
    font-size: 2.2rem;
  }
  .product-details .product-title {
    font-size: 2.2rem;
  }
  .product-gallery-separated {
    margin-bottom: 3rem;
  }
  .table-mobile .product-col .product-media {
    margin-right: 1.5rem;
  }
}
@media screen and (max-width: 575px) {
  .cta .input-group {
    flex-direction: column;
  }
  .cta .input-group .form-control {
    width: 100%;
    margin-right: 0;
  }
  .cta .input-group .btn {
    margin-left: 0;
    margin-top: 1rem;
  }
  .cta .input-group .input-group-append {
    justify-content: center;
  }
  .cta .input-group .input-group-prepend {
    justify-content: center;
  }
  .cta .input-group-round .form-control {
    border-radius: 30px;
  }
  .cta .input-group-round .btn {
    border-radius: 30px;
  }
  .cta .input-group-round .input-group-append {
    justify-content: center;
  }
  .cta-display .cta-desc {
    margin-bottom: 2rem;
  }
  .cta-display .btn {
    padding-top: 0.85rem;
    padding-bottom: 0.85rem;
  }
  .product-pager {
    margin-right: auto;
    margin-top: 1rem;
  }
  .product-details-tab .nav.nav-pills .nav-item + .nav-item {
    margin-left: 0;
  }
  .nav-filter {
    flex-wrap: wrap;
    justify-content: center;
  }
  .cart-bottom {
    flex-direction: column;
    margin-bottom: 4rem;
  }
  .cart-bottom .cart-discount .btn {
    margin-top: 0;
  }
  .cart-bottom .btn {
    margin-left: 0 !important;
    margin-top: 1rem;
  }
}
@media screen and (max-width: 479px) {
  .slider-container-1:before {
    padding-top: 83.3333333333%;
  }
  .intro-slider-1 .slide-image:before {
    padding-top: 83.3333333333%;
  }
}
@media screen and (min-width: 480px) {
  .just-action-icons-sm .btn-product span {
    display: none;
  }
  .just-action-icons-sm .btn-product:before {
    margin-right: 0;
  }
}
@media screen and (min-width: 576px) {
  .intro-content {
    left: 50px;
  }
  .intro-content .btn {
    font-size: 1.2rem;
    min-width: 110px;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .intro-subtitle {
    font-size: 1.6rem;
  }
  .intro-title {
    font-size: 3.6rem;
    margin-bottom: 1.3rem;
  }
  .toolbox-right {
    flex-direction: row;
  }
  .toolbox-layout {
    margin-top: 0;
    margin-right: -0.5rem;
    margin-left: 1rem;
  }
  .pager-nav {
    flex-direction: row;
    padding-bottom: 3.5rem;
    border-bottom: 0.1rem solid #ebebeb;
  }
  .pager-link {
    flex: 0 0 50%;
    max-width: 50%;
    padding-top: 0.55rem;
    padding-bottom: 0.55rem;
  }
  .pager-link + .pager-link {
    border-top: none;
    border-left: 0.1rem solid #ebebeb;
  }
  .form-choice .col-sm-6:not(:last-child) {
    margin-bottom: 0;
  }
  .form-tab .form-footer .btn {
    width: auto;
    margin-top: 0;
  }
  .soon-content {
    font-size: 1.6rem;
  }
  .soon-content .input-group {
    flex-direction: row;
  }
  .soon-content .btn {
    margin-left: 1rem;
    margin-top: 0;
  }
  .soon-content .form-control {
    width: auto;
  }
  .coming-countdown .countdown-section {
    font-size: 1.6rem;
  }
  .coming-countdown .countdown-amount {
    font-size: 3rem;
  }
}
@media screen and (max-width: 900px) {
  .fullscreen-container {
    margin-left: -0.6rem;
    margin-right: -0.6rem;
  }
}
@media screen and (max-width: 591px) {
  .newsletter-popup-container .banner-content {
    flex: 0 0 90%;
    max-width: 90%;
  }
}
@media screen and (max-width: 439px) {
  .quickView-content .details-action-wrapper {
    flex-direction: column;
  }
  .quickView-content .details-action-wrapper .btn-product + .btn-product {
    border: none;
    margin-top: 1rem;
  }
}
@media screen and (min-width: 1700px) {
  .container-fluid .product-details-action .btn-cart {
    min-width: 240px;
  }
  .container-fluid .details-filter-row .form-control {
    min-width: 171px;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .details-action-wrapper {
    margin-left: 1rem;
  }
  .product-details-sidebar .product-details-quantity {
    max-width: 91px;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-action {
    flex-direction: column;
    align-items: flex-start;
  }
  .product-details-action .btn-cart {
    min-width: 198px;
  }
  .details-action-wrapper {
    margin-left: 0;
    margin-top: 1.5rem;
  }
  .product-details-tab .nav.nav-pills .nav-item + .nav-item {
    margin-left: 1rem;
  }
  .product-details-tab .nav.nav-pills .nav-item .nav-link {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
@media screen and (max-width: 580px) {
  .product-details-action {
    flex-direction: column;
    align-items: flex-start;
  }
  .product-details-action .btn-cart {
    min-width: 198px;
  }
  .details-action-wrapper {
    margin-left: 0;
    margin-top: 1.5rem;
  }
  .product-details-footer {
    flex-direction: column;
    align-items: flex-start;
  }
  .product-details-footer .social-icons {
    margin-left: 0;
    margin-top: 1rem;
  }
  .review .row {
    flex-direction: column;
  }
  .details-filter-row .select-custom {
    margin-right: 1.5rem;
  }
  .product-details-centered .product-details-action .btn-cart {
    min-width: 198px;
  }
  .product-details-sidebar .details-action-col .btn-product {
    min-width: 168px;
  }
}
@media screen and (max-width: 380px) {
  .sidebar-filter {
    left: -300px;
    max-width: 300px;
  }
  .sidebar-filter-active .sidebar-filter {
    transform: translateX(300px);
  }
  .sidebar-filter-wrapper {
    padding-left: 1.7rem;
    padding-right: 1.7rem;
  }
}
@media (max-width: 1366px) {
  .homeContact .txt {
    line-height: 22px;
    margin: 15px 0;
  }
  .all_btn {
    bottom: 30px;
  }
  .homeTimeline .time .slick-prev {
    display: none !important;
  }
  .homeTimeline .time .slick-next {
    display: none !important;
  }
}
@media (max-width: 1200px) {
  .homeabout {
    padding: 30px 0;
  }
  .homeTimeline {
    padding: 30px 0;
  }
  .homeProduct {
    padding: 30px 0;
  }
  .homeWorld {
    padding: 30px 0;
  }
  .homeNews {
    padding: 30px 0;
  }
  .homeContact {
    padding: 30px 0;
  }
}
@media (max-width: 992px) {
  .homeabout {
    padding: 30px 0;
  }
  .homeTimeline {
    padding: 30px 0;
  }
  .homeTimeline .timeSection {
    top: 7px;
  }
  .homeTimeline .timeSection .head {
    font-size: 20px;
  }
  .homeTimeline .timeSection .txt {
    font-size: 13px;
  }
  .homeProduct {
    padding: 30px 0;
  }
  .homeProduct .plist {
    margin-top: 60px;
  }
  .homeProduct .plist .list_wrap {
    width: 50%;
  }
  .homeWorld {
    padding: 30px 0;
  }
  .homeNews {
    padding: 30px 0;
  }
  .homeContact {
    padding: 30px 0;
  }
}
@media (max-width: 767px) {
  .homeabout {
    padding: 15px 0 !important;
    box-sizing: border-box;
  }
  .homeTimeline {
    padding: 15px 0 !important;
    box-sizing: border-box;
  }
  .homeTimeline .timeSection {
    top: -8px;
  }
  .homeTimeline .timeSection .txt {
    height: 72px;
    overflow: hidden;
  }
  .homeTimeline .t190 {
    top: 180px !important;
  }
  .homeTimeline .time:before {
    top: 54%;
  }
  .homeTimeline h1 {
    margin-bottom: 30px;
  }
  .homeProduct {
    padding: 15px 0 !important;
    box-sizing: border-box;
  }
  .homeProduct .txt {
    height: 77px;
    overflow: hidden;
    margin-bottom: 15px;
    line-height: 24px;
  }
  .homeProduct h1 {
    margin-top: 0 !important;
  }
  .homeWorld {
    padding: 15px 0 !important;
    box-sizing: border-box;
  }
  .homeNews {
    padding: 15px 0 !important;
    box-sizing: border-box;
  }
  .homeContact {
    padding: 15px 0 !important;
    box-sizing: border-box;
  }
}
@media (max-height: 768px) {
  .homeTimeline .pd130 {
    padding-top: 0;
    margin-top: 30px;
  }
  .homeTimeline .all_btn {
    bottom: 20px;
  }
  .homeTimeline h1 {
    margin-bottom: 30px;
  }
  .homeProduct .pd70 {
    padding-top: 0;
    margin-top: 90px;
  }
  .homeProduct h1 {
    margin-top: 90px;
  }
  .homeProduct .plist .list_wrap {
    height: 420px;
  }
  .homeProduct .plist .list_wrap .phead {
    bottom: -80px;
  }
  .homeProduct .plist .list_wrap:hover .phead {
    bottom: -50px;
  }
  .homeabout .img_wrap {
    height: 450px;
  }
}
.input-group-round .form-control {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}
.input-group-round > .input-group-append > .btn {
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}

.modal-dialog {
  max-width: 800px;
  margin: 30px auto;
}

.modal-body {
  position: relative;
}
.modal-body .close {
  position: absolute;
  right: -30px;
  top: 0;
  z-index: 999;
  font-size: 2rem;
  font-weight: normal;
  color: #fff;
  opacity: 1;
}

.modal .modal-popup {
  padding: 0 !important;
}

#gallery {
  padding-top: 20px;
}
@media screen and (min-width: 991px) {
  #gallery {
    padding: 10px 30px 0 30px;
  }
}

.img-wrapper {
  position: relative;
  margin-top: 15px;
}
.img-wrapper img {
  width: 100%;
}

.img-overlay {
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
}
.img-overlay i {
  color: #fff;
  font-size: 3em;
}

#overlay {
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
#overlay img {
  margin: 0;
  width: 80%;
  height: auto;
  -o-object-fit: contain;
     object-fit: contain;
  padding: 5%;
}
@media screen and (min-width: 768px) {
  #overlay img {
    width: 60%;
  }
}
@media screen and (min-width: 1200px) {
  #overlay img {
    width: 50%;
  }
}

#nextButton {
  color: #fff;
  font-size: 2em;
  transition: opacity 0.8s;
}
#nextButton:hover {
  opacity: 0.7;
}
@media screen and (min-width: 768px) {
  #nextButton {
    font-size: 3em;
  }
}

#prevButton {
  color: #fff;
  font-size: 2em;
  transition: opacity 0.8s;
}
#prevButton:hover {
  opacity: 0.7;
}
@media screen and (min-width: 768px) {
  #prevButton {
    font-size: 3em;
  }
}

#exitButton {
  color: #fff;
  font-size: 2em;
  transition: opacity 0.8s;
  position: absolute;
  top: 15px;
  right: 15px;
}
#exitButton:hover {
  opacity: 0.7;
}
@media screen and (min-width: 768px) {
  #exitButton {
    font-size: 3em;
  }
}

.carousel-inner {
  background-color: #000 !important;
}

.carousel-item {
  opacity: 0.9 !important;
}

.carousel-caption {
  bottom: 30vh;
}

.carousel-caption h4 {
  color: white;
  font-size: 2.4rem;
  font-weight: 600;
}

.carousel-caption p {
  color: white;
  font-size: 1rem;
}

.slider-button {
  border: 1px solid #fff;
  color: #fff;
  padding: 8px 50px;
  text-decoration: unset !important;
  margin-top: 15px;
  display: inline-block;
  background-color: transparent;
  transition: all 0.5s ease;
}

.timeline {
  box-sizing: border-box;
  position: relative;
}
.timeline *,
.timeline *::before,
.timeline *::after {
  box-sizing: inherit;
}
.timeline:not(.timeline--horizontal)::before {
  background-color: #313B44;
  bottom: 0;
  content: "";
  left: 50%;
  margin-left: -2px;
  position: absolute;
  top: 0;
  width: 4px;
  z-index: 1;
}

.timeline__wrap {
  overflow: hidden;
  position: relative;
  z-index: 2;
}

.timeline__item {
  font-size: 1rem;
  padding: 10px 40px 10px 0;
  position: relative;
  width: 50%;
  z-index: 2;
}
.timeline__item::after {
  background-color: #313B44;
  border: 4px solid #313B44;
  border-radius: 50%;
  content: "";
  height: 20px;
  position: absolute;
  right: -10px;
  transform: translateY(-50%);
  top: 50%;
  width: 20px;
  z-index: 1;
}
.timeline__item.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
  opacity: 0;
}
.timeline__item.fadeIn {
  animation-name: fadeIn;
}

.timeline__item--left {
  left: 0;
}

.timeline__item--right {
  left: 50%;
  padding: 10px 0 10px 40px;
}
.timeline__item--right::after {
  left: -10px;
}
.timeline__item--right .timeline__content::before {
  border-bottom: 10px solid transparent;
  border-right: 12px solid #313B44;
  border-left: none;
  border-top: 10px solid transparent;
  left: -12px;
}
.timeline__item--right .timeline__content::after {
  border-bottom: 9px solid transparent;
  border-right: 11px solid #FFF;
  border-left: none;
  border-top: 9px solid transparent;
  left: -10px;
}

.timeline__content {
  background-color: #FFF;
  border: 1px solid #313B44;
  border-radius: 10px;
  color: #333;
  display: block;
  padding: 20px;
  position: relative;
}
.timeline__content::before, .timeline__content::after {
  content: "";
  height: 0;
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  width: 0;
}
.timeline__content::before {
  border-bottom: 10px solid transparent;
  border-left: 12px solid #313B44;
  border-top: 10px solid transparent;
  right: -12px;
  z-index: 1;
}
.timeline__content::after {
  border-bottom: 9px solid transparent;
  border-left: 11px solid #FFF;
  border-top: 9px solid transparent;
  right: -10px;
  z-index: 2;
}
.timeline__content h2 {
  font-size: 20px;
  font-weight: 700;
  margin: 0 0 10px;
  font-style: italic;
}
.timeline__content p {
  font-size: 15px;
  line-height: 1.5;
  margin-bottom: 10px;
  font-style: italic;
}

.timeline--horizontal {
  font-size: 0;
  padding: 0 50px;
  overflow: hidden;
  white-space: nowrap;
}
.timeline--horizontal .timeline-divider {
  background-color: #313B44;
  display: block;
  height: 4px;
  left: 40px;
  position: absolute;
  transform: translateY(-50%);
  right: 40px;
  z-index: 1;
}
.timeline--horizontal .timeline__items {
  transition: all 0.8s;
  will-change: transform;
}
.timeline--horizontal .timeline__item {
  display: inline-block;
  left: 0;
  padding: 0 0 40px;
  position: relative;
  transition: none;
  vertical-align: top;
  white-space: normal;
}
.timeline--horizontal .timeline__item::after {
  left: 50%;
  right: auto;
  transform: translate(-50%, -50%);
  top: 100%;
}
.timeline--horizontal .timeline__item .timeline__item__inner {
  display: table;
  height: 100%;
  width: 100%;
}
.timeline--horizontal .timeline__item .timeline__content__wrap {
  display: table-cell;
  margin: 0;
  padding: 0;
  vertical-align: bottom;
}
.timeline--horizontal .timeline__item .timeline__content::before {
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-top: 12px solid #313B44;
  left: 50%;
  right: auto;
  transform: translateX(-50%);
  top: 100%;
}
.timeline--horizontal .timeline__item .timeline__content::after {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #FFF;
  left: 50%;
  right: auto;
  transform: translateX(-50%);
  top: 100%;
}
.timeline--horizontal .timeline__item--bottom {
  padding: 40px 0 0;
}
.timeline--horizontal .timeline__item--bottom::after {
  top: 0;
}
.timeline--horizontal .timeline__item--bottom .timeline__content__wrap {
  vertical-align: top;
}
.timeline--horizontal .timeline__item--bottom .timeline__content::before {
  border-bottom: 12px solid #313B44;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-top: none;
  bottom: 100%;
  top: auto;
}
.timeline--horizontal .timeline__item--bottom .timeline__content::after {
  border-bottom: 10px solid #FFF;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: none;
  bottom: 100%;
  top: auto;
}

.timeline-nav-button {
  background-color: #313B44;
  border: 2px solid #313B44;
  border-radius: 50px;
  box-sizing: border-box;
  box-shadow: none;
  cursor: pointer;
  display: block;
  height: 40px;
  outline: none;
  position: absolute;
  text-indent: -9999px;
  transform: translateY(-50%);
  top: 50%;
  width: 40px;
  z-index: 10;
}
.timeline-nav-button:disabled {
  opacity: 0.5;
  pointer-events: none;
}
.timeline-nav-button::before {
  background-position: center center;
  background-repeat: no-repeat;
  content: "";
  display: block;
  height: 14px;
  left: 50%;
  position: absolute;
  transform: translateX(-50%) translateY(-50%);
  top: 50%;
  width: 8px;
}
.timeline-nav-button--prev {
  left: 0;
}
.timeline-nav-button--prev::before {
  background-image: url("/assets/tuncay_template/images/arrow-left.svg");
}
.timeline-nav-button--next {
  right: 0;
}
.timeline-nav-button--next::before {
  background-image: url("/assets/tuncay_template/images/arrow-right.svg");
}

.timeline--mobile {
  padding: 0;
}
.timeline--mobile::before {
  left: 10px !important;
  margin: 0 !important;
}
.timeline--mobile .timeline__item {
  left: 0;
  padding-left: 40px;
  padding-right: 0;
  width: 100%;
}
.timeline--mobile .timeline__item::after {
  left: 2px;
  margin: 0;
}
.timeline--mobile .timeline__item .timeline__content::before {
  left: -12px;
  border-bottom: 12px solid transparent;
  border-right: 12px solid #313B44;
  border-left: none;
  border-top: 12px solid transparent;
}
.timeline--mobile .timeline__item .timeline__content::after {
  left: -10px;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #FFF;
  border-left: none;
  border-top: 10px solid transparent;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    top: 70px;
  }
  100% {
    opacity: 1;
    top: 0px;
  }
}
@keyframes liftUp {
  0% {
    top: 0px;
  }
  100% {
    top: -15px;
  }
}
a {
  color: #6c757d;
}

a:hover {
  color: #e8e8e8;
  text-decoration: none;
}

::-moz-selection {
  background: #e8e8e8;
  text-shadow: none;
}

::selection {
  background: #e8e8e8;
  text-shadow: none;
}

footer {
  background-color: black;
  font-family: "Lato", sans-serif;
  padding: 85px 0 85px 0;
}

.footer-column:not(:first-child) {
  padding-top: 2rem;
}
@media (min-width: 768px) {
  .footer-column:not(:first-child) {
    padding-top: 0;
  }
}

.footer-column {
  text-align: center;
}
.footer-column .nav-item .nav-link {
  padding: 0.7rem 0.7rem 0;
}
.footer-column .nav-item span.nav-link {
  color: #6c757d;
}
.footer-column .nav-item span.footer-title {
  font-size: 14px;
  margin-bottom: 20px;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
}
.footer-column .nav-item .fas {
  color: white;
  margin-right: 1rem;
}
.footer-column ul {
  display: inline-block;
}
@media (min-width: 768px) {
  .footer-column ul {
    text-align: left;
  }
}

ul.social-buttons {
  margin-bottom: 0;
}

ul.social-buttons li a:active,
ul.social-buttons li a:focus,
ul.social-buttons li a:hover {
  background-color: #6c757d;
}

ul.social-buttons li a {
  font-size: 20px;
  line-height: 40px;
  display: block;
  width: 40px;
  height: 40px;
  transition: all 0.3s;
  color: #fff;
  border-radius: 100%;
  outline: 0;
  background-color: #1a1d20;
}

.fa-ellipsis-h {
  color: white;
  padding: 2rem 0;
}

.footer-logo {
  min-width: 5rem;
  height: 4.5rem;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: center;
     object-position: center;
}

footer h3 {
  font-weight: 500;
  text-transform: uppercase;
  font-size: 15px;
  color: white;
  margin: 0;
  padding-bottom: 9px;
  letter-spacing: 0.03em;
}

footer .quick-links {
  color: #e8e8e8;
  font-size: 90%;
  line-height: 40px;
  margin-bottom: 0;
  text-transform: none;
}

footer .powered_by {
  color: white;
  font-size: 90%;
  line-height: 40px;
  margin-bottom: 0;
  text-align: right;
}
@media (min-width: 768px) {
  footer .powered_by {
    text-align: center;
  }
}

footer .powered_by a {
  color: white;
}

footer .copyright {
  color: white;
  text-align: left;
}
@media (min-width: 768px) {
  footer .copyright {
    text-align: center;
  }
}

footer ul {
  line-height: 1.8;
  list-style-type: none;
  padding: 0;
}

footer li {
  font-weight: 400;
  font-size: 13px;
  color: #e8e8e8;
  letter-spacing: 0.03em;
  padding-top: 0.5rem;
}

/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8;
}

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden;
}

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box;
}

.mfp-container:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.mfp-align-top .mfp-container:before {
  display: none;
}

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045;
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto;
}

.mfp-ajax-cur {
  cursor: progress;
}

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: zoom-out;
}

.mfp-zoom {
  cursor: pointer;
  cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
  cursor: auto;
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.mfp-loading.mfp-figure {
  display: none;
}

.mfp-hide {
  display: none !important;
}

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044;
}
.mfp-preloader a {
  color: #CCC;
}
.mfp-preloader a:hover {
  color: #FFF;
}

.mfp-s-ready .mfp-preloader {
  display: none;
}

.mfp-s-error .mfp-content {
  display: none;
}

button.mfp-close, button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation;
}
button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace;
}
.mfp-close:hover, .mfp-close:focus {
  opacity: 1;
}
.mfp-close:active {
  top: 1px;
}

.mfp-close-btn-in .mfp-close {
  color: #333;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%;
}

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
}

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.mfp-arrow:active {
  margin-top: -54px;
}
.mfp-arrow:hover, .mfp-arrow:focus {
  opacity: 1;
}
.mfp-arrow:before, .mfp-arrow:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent;
}
.mfp-arrow:after {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px;
}
.mfp-arrow:before {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 0.7;
}

.mfp-arrow-left {
  left: 0;
}
.mfp-arrow-left:after {
  border-right: 17px solid #FFF;
  margin-left: 31px;
}
.mfp-arrow-left:before {
  margin-left: 25px;
  border-right: 27px solid #3F3F3F;
}

.mfp-arrow-right {
  right: 0;
}
.mfp-arrow-right:after {
  border-left: 17px solid #FFF;
  margin-left: 39px;
}
.mfp-arrow-right:before {
  border-left: 27px solid #3F3F3F;
}

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px;
}
.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px;
}
.mfp-iframe-holder .mfp-close {
  top: -40px;
}

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
}
.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #000;
}

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto;
}

/* The shadow behind the image */
.mfp-figure {
  line-height: 0;
}
.mfp-figure:after {
  content: "";
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #444;
}
.mfp-figure small {
  color: #BDBDBD;
  display: block;
  font-size: 12px;
  line-height: 14px;
}
.mfp-figure figure {
  margin: 0;
}

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto;
}

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px;
}

.mfp-image-holder .mfp-content {
  max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer;
}

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
   * Remove all paddings around the image on small screen
   */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0;
  }
  .mfp-img-mobile img.mfp-img {
    padding: 0;
  }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0;
  }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px;
  }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box;
  }
  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0;
  }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px;
  }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0;
  }
}
@media all and (max-width: 900px) {
  .mfp-arrow {
    transform: scale(0.75);
  }
  .mfp-arrow-left {
    transform-origin: 0;
  }
  .mfp-arrow-right {
    transform-origin: 100%;
  }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px;
  }
}